import { Router } from '@angular/router';
import { AuthenticationService } from '../_services';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../_services/authentication.service";
var AuthGuard = /** @class */ (function () {
    function AuthGuard(router, auth) {
        this.router = router;
        this.auth = auth;
    }
    AuthGuard.prototype.canActivate = function (next, state) {
        var currentUser = this.auth.currentUserValue;
        if (currentUser) {
            // authorised so return true
            // console.log('Could authenticate', { queryParams: { 'redirectURL': state.url } });
            return true;
        }
        else {
            // not logged in so redirect to login page with the return url
            // this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            // console.log({ queryParams: { returnUrl: state.url } })
            // return false;
            // console.log('Could not authenticate');
            // console.log('Could not authenticate', { queryParams: { 'redirectURL': state.url } });
            // localStorage.setItem('EditcurrentUser', JSON.stringify(state.url));
            this.router.navigate(['login'], { queryParams: { 'redirectURL': state.url } });
            return false;
        }
    };
    AuthGuard.ngInjectableDef = i0.defineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.inject(i1.Router), i0.inject(i2.AuthenticationService)); }, token: AuthGuard, providedIn: "root" });
    return AuthGuard;
}());
export { AuthGuard };
