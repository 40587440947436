import { ToasterService } from 'angular2-toaster/angular2-toaster';
import { Router, NavigationStart } from '@angular/router';
import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "angular2-toaster/src/toaster.service";
// import { ToasterService} from 'angular2-toaster'
var AlertService = /** @class */ (function () {
    function AlertService(router, toasterService) {
        var _this = this;
        this.router = router;
        this.sidebarVisibilityChange = new Subject();
        this.subject = new Subject();
        this.keepAfterNavigationChange = false;
        this.toasterService = toasterService;
        // clear alert message on route change
        router.events.subscribe(function (event) {
            if (event instanceof NavigationStart) {
                if (_this.keepAfterNavigationChange) {
                    // only keep for a single location change
                    _this.keepAfterNavigationChange = false;
                }
                else {
                    // clear alert
                    _this.subject.next();
                }
            }
        });
        this.sidebarVisibilityChange.subscribe(function (value) {
            _this.isSidebarVisible = value;
        });
    }
    AlertService.prototype.toggleSidebarVisibility = function () {
        this.sidebarVisibilityChange.next(!this.isSidebarVisible);
    };
    AlertService.prototype.errorAlert = function (title, msg) {
        this.toasterService.pop('error', title, msg);
    };
    AlertService.prototype.warningAlert = function (title, msg) {
        this.toasterService.pop('warning', title, msg);
    };
    AlertService.prototype.successAlert = function (title, msg) {
        this.toasterService.pop('success', title, msg);
    };
    AlertService.prototype.success = function (message, keepAfterNavigationChange) {
        if (keepAfterNavigationChange === void 0) { keepAfterNavigationChange = false; }
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: 'success', text: message });
        this.successAlert('success', message);
    };
    AlertService.prototype.error = function (message, keepAfterNavigationChange) {
        if (keepAfterNavigationChange === void 0) { keepAfterNavigationChange = false; }
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: 'error', text: message });
        this.errorAlert('error', message);
    };
    AlertService.prototype.p2Alert = function (title, msg) {
        this.toasterService.pop('error', title, msg);
    };
    AlertService.prototype.getMessage = function () {
        return this.subject.asObservable();
    };
    AlertService.ngInjectableDef = i0.defineInjectable({ factory: function AlertService_Factory() { return new AlertService(i0.inject(i1.Router), i0.inject(i2.ToasterService)); }, token: AlertService, providedIn: "root" });
    return AlertService;
}());
export { AlertService };
