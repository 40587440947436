/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./register.component";
var styles_RegisterComponent = [];
var RenderType_RegisterComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RegisterComponent, data: {} });
export { RenderType_RegisterComponent as RenderType_RegisterComponent };
export function View_RegisterComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_RegisterComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-dashboard", [], null, null, null, View_RegisterComponent_0, RenderType_RegisterComponent)), i0.ɵdid(1, 49152, null, 0, i1.RegisterComponent, [], null, null)], null, null); }
var RegisterComponentNgFactory = i0.ɵccf("app-dashboard", i1.RegisterComponent, View_RegisterComponent_Host_0, {}, {}, []);
export { RegisterComponentNgFactory as RegisterComponentNgFactory };
