export class Report {
  dateStart?: Date;
  dateEnd?: Date;
  shop_id?: string;
  supplier_id?: string;
  shop_name?: string;
  skip?: number;
  limit?: number;
  dateTo?: string;
  dateFrom?: string;
  name?: string;
  month?: number;
  shopId?: string;
  shopNo?: number;
  vendorDoesOwnShipping?: boolean;
  itemDetailTypeNo?: number;
  idList?: [];
  status?: Boolean;
  vendorId?: string;
  cartStatusNo: any;
}

export class Reports {
  Report: Reports[];
}
