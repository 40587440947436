import * as tslib_1 from "tslib";
import * as fromActions from './items.action';
var ItemsState = /** @class */ (function () {
    function ItemsState() {
    }
    return ItemsState;
}());
export { ItemsState };
var initialState = {
    loaded: false,
    loading: false,
    items: [],
    searchedItems: [],
    currentMixedCaseItems: [],
    itemsEntities: {
        all: [],
        is_new: [],
        archived: [],
        in_review: [],
        incomplete: [],
        published: [],
        scheduled: []
    },
    filter: null,
    currentItem: null,
    currentMixedCase: null,
    currentMixedItem: null,
    itemBrand: null,
    brands: [],
    labels: [],
    itemImages: [],
    countries: [],
    itemDetailTypes: [],
    excelTemplateUrl: null,
    suppliers: [],
    brandItem: null,
    supplierItem: null,
    highLevelTypes: [],
    supplierItemConditionSuggest: [],
    error: null,
    showDeleteError: 'false',
    showDeleteConfirmation: false,
    createMixedCase: false
};
export function itemsReducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case fromActions.LOAD_ITEMS:
        case fromActions.SEARCH_ITEMS:
        case fromActions.LOAD_ITEM:
        case fromActions.DUPLICATE_ITEM:
        case fromActions.DELETE_SUPPLIER:
        case fromActions.LOAD_SUGGESTED_IMAGES:
        case fromActions.CREATE_ITEM:
        case fromActions.UPDATE_ITEM:
        case fromActions.DELETE_ITEM:
        case fromActions.CREATE_ITEM_DETAIL:
        case fromActions.UPDATE_ITEM_DETAIL:
        case fromActions.DELETE_ITEM_DETAIL:
        case fromActions.UPLOAD_ITEM_IMAGE:
        case fromActions.DELETE_ITEM_IMAGE:
        case fromActions.LOAD_BRANDS:
        case fromActions.LOAD_COUNTRIES:
        case fromActions.LOAD_ITEM_DETAIL_TYPES:
        case fromActions.LOAD_SUPPLIERS:
        case fromActions.LOAD_SUPPLIER_ITEM:
        case fromActions.CREATE_SUPPLIER_ITEM:
        case fromActions.LOAD_SUPPLIER_ITEM_CONDITION_SUGGEST:
        case fromActions.CREATE_BRAND:
        case fromActions.UPDATE_BRAND:
        case fromActions.GET_EXCEL_TEMPLATE:
        case fromActions.IMPORT_ITEMS:
        case fromActions.EXPORT_ITEMS:
        case fromActions.CREATE_ITEM_REVIEW:
        case fromActions.UPLOAD_DB_ITEM_IMAGE:
            {
                // console.log('DELETE_ITEM', action['payload']);
                return tslib_1.__assign({}, state, { loading: true, loaded: false, showDeleteConfirmation: false });
            }
        case fromActions.LOAD_ITEMS_SUCCESS:
        case fromActions.SEARCH_ITEMS_SUCCESS: {
            // console.log('LOAD_ITEMS_SUCCESS', action['payload'].items);
            var date_1 = new Date();
            var newItemsEntities_1 = {
                all: [],
                is_new: [],
                archived: [],
                in_review: [],
                incomplete: [],
                published: [],
                scheduled: []
            };
            action['payload'].items.forEach(function (item) {
                for (var keyName in ItemEntitiesValues) {
                    var key = ItemEntitiesValues[keyName];
                    if (item[key] == true && key != 'published' && key != 'scheduled') {
                        newItemsEntities_1[key].push(item);
                        return;
                    }
                    var schedule_date = new Date(item.schedule_date);
                    if (item[key] == true && key == 'published' && !item.schedule_date) {
                        newItemsEntities_1[key].push(item);
                        return;
                    }
                    if (item[key] == true && key == 'published' && item.schedule_date && schedule_date < date_1) {
                        newItemsEntities_1[key].push(item);
                        return;
                    }
                    if (item.published == true && key == 'scheduled' && item.schedule_date && schedule_date > date_1) {
                        newItemsEntities_1[key].push(item);
                        return;
                    }
                }
            });
            var resultItemsEntities = {
                all: [],
                is_new: [],
                archived: [],
                in_review: [],
                incomplete: [],
                published: [],
                scheduled: []
            };
            // if (!action['payload'].filter) {
            //   resultItemsEntities = newItemsEntities;
            // } else {
            resultItemsEntities = {
                all: newItemsEntities_1.is_new.length ? newItemsEntities_1.all : state.itemsEntities.all,
                is_new: newItemsEntities_1.is_new.length ? newItemsEntities_1.is_new : state.itemsEntities.is_new,
                in_review: newItemsEntities_1.in_review.length ? newItemsEntities_1.in_review : state.itemsEntities.in_review,
                incomplete: newItemsEntities_1.incomplete.length ? newItemsEntities_1.incomplete : state.itemsEntities.incomplete,
                archived: newItemsEntities_1.archived.length ? newItemsEntities_1.archived : state.itemsEntities.archived,
                published: newItemsEntities_1.published.length ? newItemsEntities_1.published : state.itemsEntities.published,
                scheduled: newItemsEntities_1.scheduled.length ? newItemsEntities_1.scheduled : state.itemsEntities.scheduled,
            };
            // }
            return tslib_1.__assign({}, state, { items: action['payload'].items, itemsEntities: resultItemsEntities, error: null, loading: false, loaded: true });
        }
        case fromActions.LOAD_SEARCHED_ITEMS_SUCCESS:
            {
                return tslib_1.__assign({}, state, { searchedItems: action['payload'].searchedItems, error: null, loading: false, loaded: true });
            }
        case fromActions.LOAD_SUGGESTED_IMAGES_SUCCESS: {
            return tslib_1.__assign({}, state, { itemImages: action['payload'].itemImages, error: null, loading: false, loaded: true });
        }
        case fromActions.CLEAR_SHOW_DELETE_ERROR: {
            return tslib_1.__assign({}, state, { showDeleteError: 'false', showDeleteConfirmation: false });
        }
        case fromActions.LOAD_HIGH_LEVEL_TYPES_SUCCESS: {
            return tslib_1.__assign({}, state, { highLevelTypes: action['payload'].highLevelTypes });
        }
        case fromActions.LOAD_HIGH_LEVEL_TYPES_FAIL: {
            return tslib_1.__assign({}, state, { highLevelTypes: [] });
        }
        case fromActions.LOAD_ITEMS_FAIL:
        case fromActions.SEARCH_ITEMS_FAIL:
        case fromActions.LOAD_ITEM_FAIL:
        case fromActions.DUPLICATE_ITEM_FAIL:
        case fromActions.DELETE_SUPPLIER_FAIL:
        case fromActions.LOAD_SUGGESTED_IMAGES_FAIL:
        case fromActions.CREATE_ITEM_FAIL:
        case fromActions.UPDATE_ITEM_FAIL:
        case fromActions.CREATE_ITEM_DETAIL_FAIL:
        case fromActions.DELETE_ITEM_IMAGE_FAIL:
        case fromActions.UPLOAD_ITEM_IMAGE_FAIL:
        case fromActions.UPDATE_ITEM_DETAIL_FAIL:
        case fromActions.DELETE_ITEM_DETAIL_FAIL:
        case fromActions.LOAD_BRANDS_FAIL:
        case fromActions.LOAD_COUNTRIES_FAIL:
        case fromActions.LOAD_ITEM_DETAIL_TYPES_FAIL:
        case fromActions.LOAD_SUPPLIERS_FAIL:
        case fromActions.LOAD_SUPPLIER_ITEM_FAIL:
        case fromActions.CREATE_SUPPLIER_ITEM_FAIL:
        case fromActions.LOAD_SUPPLIER_ITEM_CONDITION_SUGGEST_FAIL:
        case fromActions.CREATE_BRAND_FAIL:
        case fromActions.UPDATE_BRAND_FAIL:
        case fromActions.GET_EXCEL_TEMPLATE_FAIL:
        case fromActions.IMPORT_ITEMS_FAIL:
        case fromActions.EXPORT_ITEMS_FAIL:
        case fromActions.CREATE_ITEM_REVIEW_FAIL:
        case fromActions.UPLOAD_DB_ITEM_IMAGE_FAIL:
            {
                // console.log('FAIL', action['payload'].error);
                return tslib_1.__assign({}, state, { items: [], error: action['payload'].error, loading: false, loaded: false });
            }
        case fromActions.DELETE_ITEM_FAIL:
            {
                // console.log('FAIL', action['payload'].error.graphQLErrors[0].error.message);
                return tslib_1.__assign({}, state, { showDeleteError: action['payload'].error.graphQLErrors[0].error.message, error: action['payload'].error.graphQLErrors[0].error.message, loading: false, loaded: false });
            }
        case fromActions.CLEAR_ITEM:
            // console.log('CLEAR_ITEM');
            {
                return tslib_1.__assign({}, state, { currentMixedCase: null, currentMixedItem: null, currentItem: null, error: null, loading: false, loaded: false });
            }
        case fromActions.CLEAR_MIXED_ITEM:
            // console.log('CLEAR_ITEM');
            {
                return tslib_1.__assign({}, state, { currentMixedItem: null, error: null, loading: false, loaded: false });
            }
        case fromActions.DELETE_ITEM_MIXED_IMAGE_SUCCESS:
            {
                var itemImages = state.currentMixedItem.itemMixedCaseImage.filter(function (it) { return it._id !== action['payload'].itemMixedCaseItem_id; });
                return tslib_1.__assign({}, state, { currentMixedItem: tslib_1.__assign({}, state.currentMixedItem, { itemImages: itemImages }), error: null, loading: false, loaded: true });
            }
        case fromActions.DELETE_ITEM_IMAGE_SUCCESS:
            {
                var itemImages = state.currentItem.itemImages.filter(function (it) { return it._id !== action['payload']._id; });
                return tslib_1.__assign({}, state, { currentItem: tslib_1.__assign({}, state.currentItem, { itemImages: itemImages }), error: null, loading: false, loaded: true });
            }
        case fromActions.UPLOAD_ITEM_MIXED_IMAGE_SUCCESS:
            console.log('UPLOAD_ITEM_MIXED_IMAGE_SUCCESS');
            {
                var itemImages = state.currentMixedCase.itemImages.concat([action['payload']]);
                return tslib_1.__assign({}, state, { currentMixedCase: tslib_1.__assign({}, state.currentMixedCase, { itemImages: itemImages }), error: null, loading: false, loaded: true });
            }
        case fromActions.UPLOAD_ITEM_IMAGE_SUCCESS:
            {
                var itemImages = state.currentItem.itemImages.concat([action['payload']]);
                return tslib_1.__assign({}, state, { currentItem: tslib_1.__assign({}, state.currentItem, { itemImages: itemImages }), error: null, loading: false, loaded: true });
            }
        case fromActions.UPDATE_ITEM_INLINE_SUCCESS:
            {
                var updItem_1 = action['payload'].item;
                // console.log('updatedItem', updItem);
                var updatedSupplierItem_1 = action['payload'].supplierItemInput;
                // console.log('updatedSupplierItem', updatedSupplierItem);
                var updatedCurrentItem = null;
                var updatedItems_1 = state.items.slice();
                updatedItems_1.forEach(function (item, itemIndex) {
                    if (!item.suppliers.length) {
                        return;
                    }
                    var supplierIndex = item.suppliers.findIndex(function (i) { return i._id == updatedSupplierItem_1._id; });
                    if (supplierIndex >= 0) {
                        // console.log('supplierIndex', supplierIndex);
                        var updatedSuppliers = item.suppliers.slice();
                        // console.log('updatedSuppliers[supplierIndex]', updatedSuppliers[supplierIndex]);
                        var updSupplier = {
                            _id: updatedSuppliers[supplierIndex]._id,
                            sku: updatedSuppliers[supplierIndex].sku,
                            // currency: updatedSuppliers[supplierIndex].currency,
                            item_id: updatedSuppliers[supplierIndex].item_id,
                            supplier_id: updatedSuppliers[supplierIndex].supplier_id,
                            supplier: updatedSuppliers[supplierIndex].supplier,
                            price: updatedSupplierItem_1.price,
                            stockQty: updatedSupplierItem_1.stockQty,
                            currency: updatedSupplierItem_1.currency,
                            isDiscounted: updatedSupplierItem_1.isDiscounted
                        };
                        updatedSuppliers[supplierIndex] = updSupplier;
                        // updSupplier[supplierIndex].stockQty = updatedSupplierItem.stockQty;
                        // updSupplier[supplierIndex].price = updatedSupplierItem.price;
                        var updatedItem = tslib_1.__assign({}, updItem_1, { suppliers: updatedSuppliers });
                        updatedItems_1[itemIndex] = updatedItem;
                        // console.log('updatedItems', updatedItems);
                        // console.log('updSupplier', updSupplier);
                    }
                });
                // console.log('updatedSupplierItem', updatedSupplierItem);
                return tslib_1.__assign({}, state, { supplierItem: updatedSupplierItem_1, currentItem: updItem_1, items: updatedItems_1, error: null, loading: false, loaded: true });
            }
        case fromActions.CREATE_ITEM_SUCCESS:
            // console.log('xxx cccCREATE_ITEM_SUCCESS', action['payload'].item);
            {
                return tslib_1.__assign({}, state, { currentItem: action['payload'].item, error: null, loading: false, loaded: true, createMixedCase: action['payload'].item.mixed_case });
            }
        case fromActions.CREATE_MIXED_ITEM_SUCCESS:
            // console.log('xxx CREATE_MIXED_ITEM_SUCCESS', action['payload'].item);
            {
                return tslib_1.__assign({}, state, { currentMixedItem: action['payload'].item, error: null, loading: false, loaded: true });
            }
        case fromActions.UPDATE_ITEM_SUCCESS:
        // case fromActions.UPDATE_ITEM_INLINE_SUCCESS:
        case fromActions.LOAD_ITEM_SUCCESS:
        case fromActions.DUPLICATE_ITEM_SUCCESS:
        case fromActions.IMPORT_ITEMS_SUCCESS:
            // console.log('LOAD_ITEM_SUCCESS');
            {
                return tslib_1.__assign({}, state, { currentItem: action['payload'].item, error: null, loading: false, loaded: true, createMixedCase: false });
            }
        case fromActions.LOAD_MIXED_CASE_SUCCESS:
            // console.log('LOAD_MIXED_CASE_SUCCESS', action['payload']);
            {
                return tslib_1.__assign({}, state, { currentMixedCase: action['payload'].item, currentMixedCaseItems: action['payload'].item.itemMixedCaseItems, error: null, loading: false, loaded: true, createMixedCase: false });
            }
        case fromActions.UPDATE_MIXED_ITEM_SUCCESS:
            var updatedMixedCaseItems = state.currentMixedCaseItems.slice();
            // console.log('UPDATE_MIXED_ITEM_SUCCESS', updatedMixedCaseItems);
            updatedMixedCaseItems.forEach(function (item, itemIndex) {
                // console.log('UPDATE_MIXED_ITEM_SUCCESS', item);
                // console.log('UPDATE_MIXED_ITEM_SUCCESS', itemIndex);
                // if (!item.suppliers.length) {
                //   return;
                // }
                // const supplierIndex: number = item.suppliers.findIndex(i => i._id == updatedSupplierItem._id);
                // if (supplierIndex >= 0) {
                //   const updatedSuppliers: SupplierItem[] = item.suppliers.slice();
                //   const updSupplier: SupplierItem = {
                //     _id: updatedSuppliers[supplierIndex]._id,
                //     sku: updatedSuppliers[supplierIndex].sku,
                //     item_id: updatedSuppliers[supplierIndex].item_id,
                //     supplier_id: updatedSuppliers[supplierIndex].supplier_id,
                //     supplier: updatedSuppliers[supplierIndex].supplier,
                //     price: updatedSupplierItem.price,
                //     stockQty: updatedSupplierItem.stockQty,
                //     currency: updatedSupplierItem.currency,
                //     isDiscounted: updatedSupplierItem.isDiscounted
                //   }
                //   updatedSuppliers[supplierIndex] = updSupplier;
                //   const updatedItem = { ...updItem, suppliers: updatedSuppliers };
                //   updatedItems[itemIndex] = updatedItem;
                // }
            });
            {
                return tslib_1.__assign({}, state, { currentMixedItem: action['payload'].item, error: null, loading: false, loaded: true, createMixedCase: false });
            }
        case fromActions.LOAD_MIXED_ITEM_SUCCESS:
            // console.log('LOAD_MIXED_ITEM_SUCCESS');
            {
                return tslib_1.__assign({}, state, { currentMixedItem: action['payload'].item, error: null, loading: false, loaded: true, createMixedCase: false });
            }
        case fromActions.CREATE_ITEM_DETAIL_SUCCESS:
            // let itemdetail: any = state && state.currentItem && state.currentItem.itemDetails ? state.currentItem.itemDetails : '';
            // console.log(state)
            {
                return tslib_1.__assign({}, state, { currentItem: tslib_1.__assign({}, state.currentItem, { itemDetails: state.currentItem.itemDetails.concat([action['payload'].itemDetail]) }), error: null, loading: false, loaded: true, createMixedCase: false });
            }
        case fromActions.UPDATE_ITEM_DETAIL_SUCCESS:
            // console.log(state)
            {
                var updatedItemDetails = state.currentItem.itemDetails.slice();
                var foundIndex = updatedItemDetails.findIndex(function (i) { return i._id == action['payload'].itemDetail._id; });
                updatedItemDetails[foundIndex] = action['payload'].itemDetail;
                return tslib_1.__assign({}, state, { currentItem: tslib_1.__assign({}, state.currentItem, { itemDetails: updatedItemDetails }), error: null, loading: false, loaded: true });
            }
        case fromActions.UPDATE_MIXED_ITEM_DETAIL_SUCCESS:
            // console.log(state)
            {
                var updatedItemDetails = state.currentMixedItem.itemMixedCaseDetail.slice();
                var foundIndex = updatedItemDetails.findIndex(function (i) { return i._id == action['payload'].itemDetail._id; });
                updatedItemDetails[foundIndex] = action['payload'].itemDetail;
                return tslib_1.__assign({}, state, { currentMixedItem: tslib_1.__assign({}, state.currentMixedItem, { itemMixedCaseDetail: updatedItemDetails }), error: null, loading: false, loaded: true });
            }
        case fromActions.DELETE_ITEM_DETAIL_SUCCESS:
            {
                var updatedItemDetails = state.currentItem.itemDetails.slice().filter(function (item) { return item._id != action['payload'].itemDetail._id; });
                return tslib_1.__assign({}, state, { currentItem: tslib_1.__assign({}, state.currentItem, { itemDetails: updatedItemDetails }), error: null, loading: false, loaded: true });
            }
        case fromActions.LOAD_BRANDS_SUCCESS:
            var brands = action['payload'].brands;
            if (brands && brands[0] && brands[0].name !== "Various") {
                var obj = null;
                for (var i = 0; i < brands.length; i++) {
                    if (brands[i].name == "Various") {
                        obj = brands[i];
                        break;
                    }
                }
                brands.unshift(obj);
            }
            {
                return tslib_1.__assign({}, state, { brands: brands, error: null, loading: false, loaded: true });
            }
        case fromActions.LOAD_COUNTRIES_SUCCESS: {
            return tslib_1.__assign({}, state, { countries: action['payload'].countries, error: null, loading: false, loaded: true });
        }
        case fromActions.LOAD_ITEM_DETAIL_TYPES_SUCCESS: {
            return tslib_1.__assign({}, state, { itemDetailTypes: action['payload'].itemDetailTypes, error: null, loading: false, loaded: true });
        }
        case fromActions.DELETE_ITEM_SUCCESS: {
            var updatedItems = state.items.slice().filter(function (item) { return item._id != action['payload'].item._id; });
            // console.log('DELETE_ITEM_SUCCESS', action['payload'])
            return tslib_1.__assign({}, state, { showDeleteConfirmation: true, items: updatedItems, error: null, loading: false, loaded: true });
        }
        case fromActions.DELETE_MIXED_ITEM_SUCCESS: {
            var updatedItems = state.currentMixedCaseItems.slice().filter(function (item) { return item._id != action['payload'].item._id; });
            return tslib_1.__assign({}, state, { showDeleteConfirmation: true, currentMixedCaseItems: updatedItems, error: null, loading: false, loaded: true });
        }
        case fromActions.LOAD_SUPPLIERS_SUCCESS:
            {
                return tslib_1.__assign({}, state, { suppliers: action['payload'].suppliers, error: null, loading: false, loaded: true });
            }
        case fromActions.CREATE_SUPPLIER_ITEM_SUCCESS:
            console.log(state.currentMixedCase);
            if (state.currentMixedCase) {
                {
                    return tslib_1.__assign({}, state, { supplierItem: action['payload'].supplierItem, currentMixedCase: tslib_1.__assign({}, state.currentMixedCase, { suppliers: state.currentMixedCase.suppliers.concat([action['payload'].supplierItem]) }), error: null, loading: false, loaded: true });
                }
            }
            else {
                {
                    return tslib_1.__assign({}, state, { supplierItem: action['payload'].supplierItem, currentItem: tslib_1.__assign({}, state.currentItem, { suppliers: state.currentItem.suppliers.concat([action['payload'].supplierItem]) }), error: null, loading: false, loaded: true });
                }
            }
        case fromActions.DELETE_SUPPLIER_SUCCESS:
            if (state.currentMixedCase) {
                {
                    var suppliers = state.currentMixedCase.suppliers.slice();
                    var deleteSup_1 = action['payload'].supplierItem.data.deleteSupplierItem;
                    // const foundIndex = suppliers.findIndex(i => i._id == deleteSup);
                    // suppliers[foundIndex] = action['payload'].supplierItem;
                    var index = suppliers.findIndex(function (x) { return x._id === deleteSup_1._id; });
                    suppliers.splice(index, 1);
                    // console.log('suppliers', suppliers);
                    if (!suppliers.length) {
                        suppliers = [];
                    }
                    return tslib_1.__assign({}, state, { supplierItem: action['payload'].supplierItem, currentMixedCase: tslib_1.__assign({}, state.currentMixedCase, { suppliers: suppliers }), error: null, loading: false, loaded: true });
                }
            }
            else {
                {
                    var suppliers = state.currentItem.suppliers.slice();
                    var deleteSup_2 = action['payload'].supplierItem.data.deleteSupplierItem;
                    // const foundIndex = suppliers.findIndex(i => i._id == deleteSup);
                    // suppliers[foundIndex] = action['payload'].supplierItem;
                    var index = suppliers.findIndex(function (x) { return x._id === deleteSup_2._id; });
                    suppliers.splice(index, 1);
                    // console.log('suppliers', suppliers);
                    if (!suppliers.length) {
                        suppliers = [];
                    }
                    return tslib_1.__assign({}, state, { supplierItem: action['payload'].supplierItem, currentItem: tslib_1.__assign({}, state.currentItem, { suppliers: suppliers }), error: null, loading: false, loaded: true });
                }
            }
        case fromActions.LOAD_MIXED_CASE_SUPPLIER_ITEM_SUCCESS:
            {
                var suppliers = state.currentMixedCase.suppliers.slice();
                var foundIndex = suppliers.findIndex(function (i) { return i._id == action['payload'].supplierItem._id; });
                suppliers[foundIndex] = action['payload'].supplierItem;
                return tslib_1.__assign({}, state, { supplierItem: action['payload'].supplierItem, currentMixedCase: tslib_1.__assign({}, state.currentMixedCase, { suppliers: suppliers }), error: null, loading: false, loaded: true });
            }
        case fromActions.LOAD_SUPPLIER_ITEM_SUCCESS:
            {
                var suppliers = state.currentItem.suppliers.slice();
                var foundIndex = suppliers.findIndex(function (i) { return i._id == action['payload'].supplierItem._id; });
                suppliers[foundIndex] = action['payload'].supplierItem;
                return tslib_1.__assign({}, state, { supplierItem: action['payload'].supplierItem, currentItem: tslib_1.__assign({}, state.currentItem, { suppliers: suppliers }), error: null, loading: false, loaded: true });
            }
        case fromActions.UPDATE_SUPPLIER_ITEM_SUCCESS:
            {
                var updatedSupplierItem_2 = action['payload'].supplierItem;
                // console.log('updatedSupplierItem', updatedSupplierItem);
                var updatedCurrentItem = null;
                if (state.currentItem && state.currentItem.suppliers) {
                    // console.log('state.currentItem');
                    var suppliers = state.currentItem.suppliers.slice();
                    var foundIndex = suppliers.findIndex(function (i) { return i._id == updatedSupplierItem_2._id; });
                    var updSupplier = {
                        _id: updatedSupplierItem_2._id,
                        item_id: suppliers[foundIndex].item_id,
                        supplier_id: suppliers[foundIndex].supplier_id,
                        supplier: suppliers[foundIndex].supplier,
                        price: updatedSupplierItem_2.price,
                        stockQty: updatedSupplierItem_2.stockQty,
                        currency: updatedSupplierItem_2.currency,
                        soldInMultiplesOf: updatedSupplierItem_2.soldInMultiplesOf,
                        sku: updatedSupplierItem_2.sku,
                        discountedPrice: updatedSupplierItem_2.discountedPrice,
                        discountedPriceValidFrom: updatedSupplierItem_2.discountedPriceValidFrom,
                        discountedPriceValidTo: updatedSupplierItem_2.discountedPriceValidTo,
                        limitQty: updatedSupplierItem_2.limitQty,
                        limitStock: updatedSupplierItem_2.limitStock
                    };
                    suppliers[foundIndex] = updSupplier;
                    updatedCurrentItem = tslib_1.__assign({}, state.currentItem, { suppliers: suppliers });
                }
                var updatedItems_2 = state.items.slice();
                updatedItems_2.forEach(function (item, itemIndex) {
                    if (!item.suppliers.length) {
                        return;
                    }
                    var supplierIndex = item.suppliers.findIndex(function (i) { return i._id == updatedSupplierItem_2._id; });
                    if (supplierIndex >= 0) {
                        // console.log('supplierIndex');
                        var updatedSuppliers = item.suppliers.slice();
                        // console.log('updatedSuppliers[supplierIndex]', updatedSuppliers[supplierIndex]);
                        var updSupplier = {
                            _id: updatedSuppliers[supplierIndex]._id,
                            sku: updatedSuppliers[supplierIndex].sku,
                            // currency: updatedSuppliers[supplierIndex].currency,
                            item_id: updatedSuppliers[supplierIndex].item_id,
                            supplier_id: updatedSuppliers[supplierIndex].supplier_id,
                            supplier: updatedSuppliers[supplierIndex].supplier,
                            price: updatedSupplierItem_2.price,
                            stockQty: updatedSupplierItem_2.stockQty,
                            currency: updatedSupplierItem_2.currency,
                            limitQty: updatedSupplierItem_2.limitQty,
                            limitStock: updatedSupplierItem_2.limitStock
                        };
                        updatedSuppliers[supplierIndex] = updSupplier;
                        // updSupplier[supplierIndex].stockQty = updatedSupplierItem.stockQty;
                        // updSupplier[supplierIndex].price = updatedSupplierItem.price;
                        var updatedItem = tslib_1.__assign({}, item, { suppliers: updatedSuppliers });
                        updatedItems_2[itemIndex] = updatedItem;
                        // console.log('updatedItems', updatedItems);
                    }
                });
                return tslib_1.__assign({}, state, { supplierItem: updatedSupplierItem_2, currentItem: updatedCurrentItem, items: updatedItems_2, error: null, loading: false, loaded: true });
            }
        case fromActions.LOAD_SUPPLIER_ITEM_CONDITION_SUGGEST_SUCCESS:
            {
                return tslib_1.__assign({}, state, { supplierItemConditionSuggest: action['payload'].conditions.slice(), error: null, loading: false, loaded: true });
            }
        case fromActions.CREATE_BRAND_SUCCESS:
        case fromActions.UPDATE_BRAND_SUCCESS:
            {
                return tslib_1.__assign({}, state, { itemBrand: action['payload'].brand, currentItem: tslib_1.__assign({}, state.currentItem, { brand_id: action['payload'].brand._id, brandDescription: action['payload'].brand.name }), error: null, loading: false, loaded: true });
            }
        case fromActions.GET_EXCEL_TEMPLATE_SUCCESS:
            {
                return tslib_1.__assign({}, state, { excelTemplateUrl: action['payload'].excelTemplateUrl, error: null, loading: false, loaded: true });
            }
        case fromActions.EXPORT_ITEMS_SUCCESS:
        case fromActions.CREATE_ITEM_REVIEW_SUCCESS:
        case fromActions.UPLOAD_DB_ITEM_IMAGE_SUCCESS:
            {
                return tslib_1.__assign({}, state, { error: null, loading: false, loaded: true });
            }
        case fromActions.LOAD_LABELS_SUCCESS:
            {
                return tslib_1.__assign({}, state, { labels: action['payload'].labels, error: null, loading: false, loaded: true });
            }
        default: {
            return state;
        }
    }
}
export var getLoading = function (state) { return state.loading; };
export var getLoaded = function (state) { return state.loaded; };
export var getItems = function (state) { return state.items; };
export var getSearchedItems = function (state) { return state.searchedItems; };
export var getItemImages = function (state) { return state.itemImages; };
export var getItemsEntities = function (state) { return state.itemsEntities; };
export var getCurrentItem = function (state) { return state.currentItem; };
export var getCurrentMixedCase = function (state) { return state.currentMixedCase; };
export var getMixedCaseItems = function (state) { return state.currentMixedCaseItems; };
export var getCurrentMixedItem = function (state) { return state.currentMixedItem; };
export var getBrands = function (state) { return state.brands; };
export var getLabels = function (state) { return state.labels; };
export var getBrandItem = function (state) { return state.brandItem; };
export var getCountries = function (state) { return state.countries; };
export var getItemDetails = function (state) { return state.itemDetailTypes; };
export var getSuppliers = function (state) { return state.suppliers; };
export var getSupplierItem = function (state) { return state.supplierItem; };
export var getHighLevelTypes = function (state) { return state.highLevelTypes; };
export var getSupplierItemConditionSuggest = function (state) { return state.supplierItemConditionSuggest; };
export var getItemDetailTypes = function (state) { return state.itemDetailTypes; };
export var getExcelTemplateUrl = function (state) { return state.excelTemplateUrl; };
export var getError = function (state) { return state.error; };
export var getShowDeleteError = function (state) { return state.showDeleteError; };
export var getShowDeleteConfirmation = function (state) { return state.showDeleteConfirmation; };
export var getMixedCase = function (state) { return state.createMixedCase; };
export var ItemEntitiesValues;
(function (ItemEntitiesValues) {
    ItemEntitiesValues["ALL"] = "all";
    ItemEntitiesValues["NEW"] = "is_new";
    ItemEntitiesValues["IN_REVIW"] = "in_review";
    ItemEntitiesValues["INCOMPLETE"] = "incomplete";
    ItemEntitiesValues["ARCHIVED"] = "archived";
    ItemEntitiesValues["PUBLISHED"] = "published";
    ItemEntitiesValues["SCHEDULED"] = "scheduled";
})(ItemEntitiesValues || (ItemEntitiesValues = {}));
