import { Injectable, OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpEvent, HttpHandler, HttpRequest, HttpClient, HttpHeaders } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { interval } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import * as jwt_decode from 'jwt-decode';
import { formatDate } from '@angular/common';
import { NotificationsService } from './notifications.service';

@Injectable({
  providedIn: 'root'
})
export class IdleEventManager {
  myDate: number;
  token;
  refreshTime;
  noticeList: any;
  showNotice: boolean;
  userNoticeAmount: number;

  idle = false;
  callbacks = [];
  timeout = null;
  limit = 6;

  constructor(private auth: AuthenticationService, private apollo: Apollo, private router: Router, private myNotice: NotificationsService) {
    // if (this.auth.isLogin) {
    //   console.log("this in const", this.auth.isLogin);
    //  this.start();
    //  }
    if (this.router.url.indexOf('login') > -1) {

      // console.log('=================================login page');
    } else {
      // this.myStart();
      // console.log('=================================fake start');
    }
  }


  registerCallbacks() { }

  myStart() {
    // console.log('========================start is start!');
    const token = this.auth.token;
    if (token && !(this.router.url.indexOf('login') > -1)) {
      // console.log('start is start token!');
      this.startTimer();
      const decoded = jwt_decode(this.auth.token);
      this.refreshTime = ((decoded.exp - decoded.iat) * 1000) - 300000;
      // console.log('decoded', decoded);
      // this.refreshTime = ((decoded.exp - decoded.iat) * 1000) - 60000;
      // this.refreshTime = 2500;
      // console.log('ref', this.refreshTime);
      // if (this.auth.isLogin === true) {
      // console.log('this.auth.isLogin in click', this.auth.isLogin);
      document.body.addEventListener('click', () => {
        // if ((this.router.url.indexOf('login') > -1) || !this.auth.isLogin) {
        // console.log('doos');
        //   localStorage.clear();
        // } else {
        //   this.auth.refreshToken()
        // console.log('clicked');
        // }
        // if (this.timeout) {
        //   this.limit = 6;
        //   clearTimeout(this.timeout);
        // document.removeEventListener('click', this.yess);
        // }
        // this.startTimer();
      });
    }
  }


  startTimer() {
    if ((this.router.url.indexOf('login') > -1) || !this.auth.isLogin) {
      // console.log('poes');
      localStorage.clear();
      document.removeEventListener('click', this.yess);
    } else {

      // console.log('gggg');
      // this.myNotice.getNotice(this.auth.token).subscribe(res => {
      //  this.noticeList = res.data;
      //   this.noticeList = res.data;
      //   const NAMES = [];
      //   const COUNT = [];
      // tslint:disable-next-line: forin
      //   for (const d in this.noticeList.CmsNotifications) {
      //     const item = this.noticeList.CmsNotifications[d];
      //     if (item.isArchived === false) {
      //       const newName = this.noticeList.CmsNotifications[d];
      //       NAMES.push(newName);
      //     }
      //     if (item.isArchived === false && item.date_read === null) {
      //       const newCount = this.noticeList.CmsNotifications[d];
      //       COUNT.push(newCount);
      //     }
      //   }
      // console.log(NAMES);
      // console.log(COUNT);
      //   NAMES.length > 0 ? this.showNotice = false : this.showNotice = true;
      //   this.noticeList = NAMES;
      //   this.userNoticeAmount = COUNT.length;
      // });


      // console.log('this.refreshTime', this.refreshTime);
      // if (this.auth.isLogin) {
      this.timeout = setTimeout(() => {
        // call some side effect like refresh code here
        // this.auth.refreshToken();
        this.limit = this.limit - 1;
        // console.log('minus one', this.limit);
        // call some side effect like refresh code here

        if (this.limit <= 0) {
          // call logout code here
          // console.log('logging out here');
          this.auth.timeOut();
        } else {
          this.startTimer();
        }
      }, this.refreshTime);
    }
  }


  yess() {
    console.log('remove');
  }

}
