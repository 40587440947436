﻿import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../_services';
import { consoleSandbox } from '@sentry/utils';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private auth: AuthenticationService
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.auth.currentUserValue;
    if (currentUser) {
      // authorised so return true
      // console.log('Could authenticate', { queryParams: { 'redirectURL': state.url } });
      return true;
    } else {

      // not logged in so redirect to login page with the return url
      // this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
      // console.log({ queryParams: { returnUrl: state.url } })
      // return false;

      // console.log('Could not authenticate');
      // console.log('Could not authenticate', { queryParams: { 'redirectURL': state.url } });
      // localStorage.setItem('EditcurrentUser', JSON.stringify(state.url));
      this.router.navigate(['login'], { queryParams: { 'redirectURL': state.url } });

      return false;

    }
  }
}
