﻿export class Item {
    id: number;
    description: string;
}
export class Items {
    Item: Item[];
}

export class City {
  id: number;
  name: string;
  country_id: string;
  state_id: string;
}


export class ItemFilter1 {
  limit?: number;
  skip?: number;
  name?: string;
  routeKeySearch?: string;
  brand_id?: string;
  itemtype_id?: string;
  highLevelType_key?: string;
  is_new?: boolean;
  in_review?: boolean;
  incomplete?: boolean;
  archived?: boolean;
  published?: boolean;
  shopNo?: number;
  inStock?: boolean;
  query?: string;
}
