export class supplierInvoiceMonth {
  invoiceType?: string;
  month?: number;
  year?: number;
  supplier_id?: string;
  file?: File;
  pdfCommissionUrl?: string;
  pdfShippingChargeUrl?: string;

}

export class InvoicePdf {
  supplier_id?: string;
  supplierInvoiceMonth?: string;
  _id?: string;
  file?: File;
}
