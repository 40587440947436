import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { HttpClient, HttpHeaders, HttpClientModule, HttpBackend, HttpParams } from '@angular/common/http';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { AlertService, AuthenticationService } from '../../_services';
import * as jwt_decode from 'jwt-decode';
import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'my-auth-token'
  })
};

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})

@Injectable()
export class PasswordResetComponent implements OnInit {
  constructor(
    private apollo: Apollo,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private http: HttpClient,
    private httpClient: HttpClient,
    handler: HttpBackend
  ) { }

  form: FormGroup;
  tokenForm: FormGroup;
  loading = false;
  submitted = false;
  error: string;
  temp: any;
  public fragmentBoolean;
  public user_id;

  get f() { return this.form.controls; }
  get ff() { return this.tokenForm.controls; }

  get password() { return this.tokenForm.get('password'); }
  get confirmPassword() { return this.tokenForm.get('confirmPassword'); }

  static passwordsShouldMatch(control: AbstractControl) {
    let password = control.get('password');
    let confirmPassword = control.get('confirmPassword');

    if (password.value !== confirmPassword.value)
      return { passwordsShouldMatch: true };

    return null;
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
     email: ['', [Validators.required, Validators.email]]
    });

    this.tokenForm = this.formBuilder.group({
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    }, {
        validator: PasswordResetComponent.passwordsShouldMatch
      });

    //   if (this.loginForm.invalid) {
    //     return;
    // }

    this.route.fragment.subscribe((fragment: string) => {
      // console.log('My hash fragment is here => ', fragment);

      if (fragment) {
        const decoded = jwt_decode(fragment);
        // console.log('USER ID', decoded.user_id);
        this.user_id = decoded.user_id;
        // console.log('My hash fragment is here => ', fragment);
        // console.log('USER EMAIL', decoded.email);
        this.fragmentBoolean = fragment;
        // this.resetPassword(this.user_id , '123');
      }
    });

  }

  onSubmit = function (user) {
    this.submitted = true;

    // console.log(this.fragmentBoolean);
    if (this.fragmentBoolean) {
      if (this.tokenForm.invalid) {
        return;
      }
     //  console.log('sending passwords');
      this.resetPassword(this.user_id, this.ff.password.value);
    } else {
      if (this.form.invalid) {
        return;
      }
      // console.log('email bish', this.f.email.value);
      this.requestPassword(this.f.email.value);
    }
  };

  // => Requesting new password
  requestPassword(email: string) {
    // console.log('requesting password');
    const reqestGQL = gql`
      mutation createUserPasswordResetToken($email: String!) {
        createUserPasswordResetToken(email: $email) {
      email
          }
      }`;
    this.apollo.mutate({
      mutation: reqestGQL,
      variables: {
        email: email
      },
      context: {
        // example of setting the headers with context per operation
        // headers: new HttpHeaders().set('x-reset-token', this.fragmentBoolean)
      }
    })
      .pipe(first())
      .subscribe(newdata => {
        this.alertService.success('Password reset link sent');
        // setTimeout(() => {
        // console.log(newdata);
        // this.router.navigate([this.returnUrl]);
        // }, 600);
      }, error => {
        if (error.graphQLErrors.length > 0 && error.graphQLErrors[0].statusCode === 409) {
          this.alertService.error('Password reset link already sent, please try again in 30 mins');
          // this.temp = error.graphQLErrors[0];
        } else {
          this.temp.error = error;
        }
        // this.alertService.error(this.temp.error.message);
        // this.alertService.error(error);
        this.loading = false;
      });
  }

  // => Setting new password
  resetPassword(user_id: string, password: string) {
    const resetGQL = gql`
      mutation useUserPasswordResetToken($user_id: ID!, $password: String!) {
        useUserPasswordResetToken(user_id: $user_id, password: $password) {
          _id
          email
          shop_id
          createdAt
          expireAt
          token
          }
      }`;
    this.apollo.mutate({
      mutation: resetGQL,
      variables: {
        user_id: user_id,
        password: password
      },
      context: {
        // example of setting the headers with context per operation
        headers: new HttpHeaders().set('x-reset-token', this.fragmentBoolean)
      }
    }).subscribe(newdata => {
      this.alertService.success('Password reset successfully');
      setTimeout(() => {
        // console.log(newdata);
        this.router.navigate(['/login']);
      }, 600);
    }, error => {
      if (error.graphQLErrors.length > 0 && error.graphQLErrors[0].statusCode === 500) {
         this.alertService.error('Password reset token expired, please request a new one');
       // this.temp = error.graphQLErrors[0];
      } else {
        this.temp.error = error;
      }
      // this.alertService.error(this.temp.error.message);
      // this.alertService.error(error);
      this.loading = false;
    });
  }

}
