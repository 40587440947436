﻿import { UserType } from './../_models/user';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { User, UserToken } from '../_models';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { NGFNavItems, navItems } from '../_nav';
import { Router } from '@angular/router';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  myDate: any;
  private currentUserSubject: BehaviorSubject<UserToken>;
  userDataNew = new User();
  userData = new User();
  navItemList = navItems;
  navItemListTemp = navItems;
  ngfNavItems = NGFNavItems;
  public currentUser: Observable<UserToken>;
  token;
  newToken;
  isLogin: boolean;
  // onlyUser1: boolean;
  // onlyUser2: boolean;
  // onlyUser3: boolean;
  // onlyUser23: boolean;
  ///
  myUserType: string;
  myUserList: number[];
  onlyUser1: number[] = [1];
  onlyUser2: number[] = [2];
  onlyUser3: number[] = [3];
  onlyUser23: number[] = [2, 3];
  onlyUser32: number[] = [3, 2];
  user3Special: string = 'noShow';
  idToggle = false;
  showMsg: boolean;
  showImportModal: boolean = false;
  showDeleteModal: boolean = false;
  myTimer: any;
  constructor(
    private http: HttpClient,
    private apollo: Apollo,
    private router: Router) {
    this.currentUserSubject = new BehaviorSubject<UserToken>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
    this.token = localStorage.getItem('token');
  }

  public get currentUserValue(): UserToken {
    // if (this.currentUserSubject.value != null) {
    // console.log('current subject',this.currentUserSubject.value.token) }
    return this.currentUserSubject.value;

  }

  setModal() {
    // console.log(this.showImportModal)
    this.showImportModal = true;
    // console.log(this.showImportModal)
  }

  setDeleteModal() {
    // console.log(this.showImportModal)
    this.showDeleteModal = true;
    // console.log(this.showImportModal)
  }

  checkNGFUser() {
    const userSuppier_id = this.getCurrentUserType().supplier_id;

    if (userSuppier_id === '5e63897aaa3e878408806a67' || userSuppier_id === '65effaa25618076e2c5f49b0' || userSuppier_id === '65effab85618076e2c5f49b1') {
      return true;
    } else {
      return false;
    }

  }
  getUserNo() {
    const usertypeno = this.getCurrentUserType().userTypeNo;
    if (JSON.stringify(this.onlyUser1) === JSON.stringify(usertypeno)) {
      this.myUserType = 'user1';
    } else if (JSON.stringify(this.onlyUser2) === JSON.stringify(usertypeno)) {
      this.myUserType = 'user2';
    } else if (JSON.stringify(this.onlyUser3) === JSON.stringify(usertypeno)) {
      this.myUserType = 'user3';
    } else if (JSON.stringify(this.onlyUser23) === JSON.stringify(usertypeno) || JSON.stringify(this.onlyUser32) === JSON.stringify(usertypeno)) {
      this.myUserType = 'user23';
    }
    if (this.myUserType === 'user3') {
      this.checkSupplierShipments();
    }
    return this.myUserType;
  }

  getNavItem() {
    if (this.checkNGFUser()) {
      return this.ngfNavItems;
    } else {
      this.getUserNo();
      this.navItemList = this.navItemListTemp.filter(
        nav => (nav.myUserType === this.myUserType) || (nav.myUserType === '') || (nav.user3Special === this.user3Special)
      );
      return this.navItemList;
    }
  }

  getCurrentUserType() {
    const data = JSON.parse(localStorage.getItem('currentUserDetail'));
    if (data) {
      return data.User;
    } else {
      //  console.log('tokeninfo:', this.userDataNew);
      return this.userDataNew;
    }

  }

  checkSupplierShipments() {
    const data = JSON.parse(localStorage.getItem('currentUserDetail'));
    if (data && data.User.supplier_id && (data.User.supplier_id === "5f35095bb2e1a80026428add") || (data.User.supplier_id === "5fbb9150bd4409001f89da78") || (data.User.supplier_id === "5f8d9aa167ba00002579b419")) {
      this.user3Special = 'user3Special'
    }

  }

  // clean data before submit to gql
  clean(obj) {
    for (const propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
        delete obj[propName];
      }
    }
  }

  // show and hide id collumn on listings
  toggleId() {
    // console.log(this.idToggle);
    if (this.idToggle === true) {
      this.idToggle = false;
      return;
    } else if (this.idToggle === false) {
      this.idToggle = true;
    }
    // this.idToggle === true ? this.idToggle === false : this.idToggle === true;
    // console.log(this.idToggle);
    return this.idToggle;
  }

  login(email: string, password: string) {
    const loginGQL = gql`
            mutation Login($email: String!, $password: String!) {
                Login(email: $email, password: $password) {
                    token {
                        user_id
                        token
                    }
                }
            }`;
    return this.apollo.mutate({
      mutation: loginGQL,
      variables: {
        email: email,
        password: password
      }
    }).pipe(map(data => {
      const tokenInfo = data.data;
      // console.log("tokeninfo:",tokenInfo);
      if (tokenInfo && tokenInfo.Login) {
        this.isLogin = true;
        this.token = tokenInfo.Login.token.token;
        localStorage.setItem('currentUser', JSON.stringify(tokenInfo.Login.token));
        localStorage.setItem('token', this.token);
        // console.log("tokeninfo:", this.token);
        // setTimeout( () => {
        //    this.refreshToken();

        // } , 5000);

        this.currentUserSubject.next(tokenInfo.Login.token);
        // console.log('post login',tokenInfo.Login.token.token)
      }
      return tokenInfo;
    }));
  }

  refreshToken() {
    // console.log('renewing now');
    const renewGQL = gql`
                       mutation { Renew {
                           _id
                           user_id
                           token
                           createdAt
                           expireAt
                       }
                   }`;

    this.apollo.mutate({
      mutation: renewGQL,
      context: {
        // example of setting the headers with context per operation
        headers: new HttpHeaders().set('x-access-token', this.token)
      }
    }).pipe(map(newdata => {
      if (this.router.url.indexOf('login') > -1) {
        // console.log('goodbye', d);
      } else {
        // localStorage.removeItem('currentUser');
        // localStorage.removeItem('token');
        localStorage.setItem('currentUser', JSON.stringify(newdata.data.Renew))
        localStorage.setItem('token', (newdata.data.Renew.token))
        this.token = newdata.data.Renew.token;
        this.currentUserSubject.value.token = newdata.data.Renew.token;
        // location.reload();
        // console.log('post', d.data.Renew.token);
        console.log('post renew', this.token);
        // console.log('post renew subjhect', this.currentUserSubject.value.token);
      }
      // let decoded = jwt_decode(this.token.token);
      // console.log("EXP AFTER", decoded.exp);
    }));
    // console.log('end of if token', this.token);
  }

  getUserDetail(userid: string, token: string) {
    const d = `query { User (_id: "${userid}") {
      _id
      shop_id
      supplier { country { shortName } name }
      supplier_id
      firstName
      lastName
      name
      email
      password
      status
      userTypeNo } }`
    const userDetailGQL = gql(d);
    // console.log('this.token user', this.token);
    return this.apollo.query({
      query: userDetailGQL,
      fetchPolicy: 'network-only',
      variables: {
        _id: userid
      },
      context: {
        // example of setting the headers with context per operation
        headers: new HttpHeaders().set('x-access-token', token)
      }
    }).pipe(map(newdata => {
      const userInfo = newdata;
      // console.log(userInfo);
      localStorage.setItem('currentUserDetail', JSON.stringify(userInfo.data));
      return userInfo;
    }));

  }

  getAllUserData(token: string, UserData: User, IsAdvanceSearch: boolean, IsAllUsers: boolean) {
    let allUserGQL = gql;
    if (!IsAdvanceSearch) {
      allUserGQL = gql`query {
        Users(limit: 0) {
          _id
          shop_id
          supplier_id
          name
          email
          password
          status
          userTypeNo
        }
       }`;
    } else {
      let q = '';
      if (IsAllUsers)
        q = 'query {  Users(limit:0, name:  "' + UserData.name + '",email: "' + UserData.email + '"){  _id        shop_id        supplier_id        name        email        password        status        userTypeNo   }       }'
      else
        q = 'query {  Users(limit:0, name:  "' + UserData.name + '",email: "' + UserData.email + '",status: ' + UserData.status + '){ _id        shop_id        supplier_id        name        email        password        status        userTypeNo   }       }'
      allUserGQL = gql(q);
    }

    return this.apollo.query({
      query: allUserGQL,
      fetchPolicy: 'network-only',
      context: {
        // example of setting the headers with context per operation
        headers: new HttpHeaders().set('x-access-token', token)
      }
    }).pipe(map(newdata => {
      const userInfo = newdata;
      // console.log(userInfo);
      localStorage.setItem('totaluser', JSON.stringify(userInfo.data));
      return userInfo.data;
    }));
  }


  getUserWiseDetail(userid: string, token: string) {
    // console.log('sadfasdfasdfr');
    const d = 'query {User(_id: "' + userid + '") {        _id        shop_id        supplier_id    firstName   lastName       name        email        password        status        userTypeNo          }       }'
    const userDetailGQL = gql(d);
    return this.apollo.query({
      query: userDetailGQL,
      fetchPolicy: 'network-only',
      variables: {
        _id: userid
      },
      context: {
        // example of setting the headers with context per operation
        headers: new HttpHeaders().set('x-access-token', token)
      }
    }).pipe(map(newdata => {
      const userInfo = newdata;
      localStorage.setItem('getUserWiseDetail', JSON.stringify(userInfo.data));
      return userInfo;
    }));
  }

  editUserDetail(isEdit: boolean, data: any, token: string) {
    let editUserGQL = gql;
    if (!isEdit) {
      editUserGQL = gql`
        mutation editUser($id:  ID!,$firstName:String!,$lastName:String!,$email: String!, $status: Boolean!) {
            editUser(_id: $id, user: {firstName:$firstName,lastName:$lastName,email:$email,status: $status}) {
                _id
                firstName
                lastName
                name
                email
                status
            }
        }`;
    } else {
      editUserGQL = gql`
      mutation createUser($firstName:String!,$lastName:String!,$email: String!, $password: String!,$userTypeNo:[Int!], $shop_id: String, $supplier_id: String) {
        createUser(
          user:{
          firstName:$firstName,
          lastName:$lastName,
          email: $email,
          password: $password,
          userTypeNo: $userTypeNo,
          shop_id: $shop_id,
          supplier_id: $supplier_id}) {
          _id
          shop_id
          supplier_id
          name
          email
          password
          status
          userTypeNo
          }
        }`;
    }


    this.userData = data;
    // this.userData.status= data.status=="true"?Boolean(true): Boolean(false);
    /// COME BACK AND FIX
    // this.userData.userTypeNo = Number(this.userData.userTypeNo);
    return this.apollo.mutate({
      mutation: editUserGQL,
      variables: {
        id: data._id,
        firstName: this.userData.firstName,
        lastName: this.userData.lastName,
        email: this.userData.email,
        status: this.userData.status,
        password: this.userData.password,
        userTypeNo: this.userData.userTypeNo,
        shop_id: this.userData.shop_id,
        supplier_id: this.userData.supplier_id
      },
      context: {
        // example of setting the headers with context per operation
        headers: new HttpHeaders().set('x-access-token', token)
      }
    }).pipe(map(data => {
      const editInfo = data.data;
      // console.log(editInfo);
      if (editInfo && editInfo) {
        localStorage.setItem('EditcurrentUser', JSON.stringify(editInfo));
      }

      return editInfo;
    }));
  }

  editCustomerDetail(isEdit: boolean, name: string, email: string, password: string, userTypeNo: [number], shop_id: string, token: string) {
    let editUserGQL = gql;
    if (!isEdit) {
      editUserGQL = gql`
        mutation editUser($id:  ID!,$name:String!,$email: String!, $status: Boolean!) {
            editUser(_id: $id, user: {name:$name,email:$email,status: $status}) {
                _id
                name
                email
                status
            }
        }`;
    } else {
      editUserGQL = gql`
      mutation createUser($name:String!,$email: String!, $password: String!,$userTypeNo:[Int!], $shop_id: String, $supplier_id: String) {
        createUser(
          user:{
          name: $name,
          email: $email,
          password: $password,
          userTypeNo: $userTypeNo,
          shop_id: $shop_id,
          supplier_id: $supplier_id}) {
          _id
          shop_id
          supplier_id
          name
          email
          password
          status
          userTypeNo
          }
        }`;
    }
    // this.userData = data;
    // this.userData.status= data.status=="true"?Boolean(true): Boolean(false);
    /// COME BACK AND FIX
    // this.userData.userTypeNo = Number(this.userData.userTypeNo);
    return this.apollo.mutate({
      mutation: editUserGQL,
      variables: {
        //  id: data._id,
        name: this.userData.name,
        email: this.userData.email,
        status: this.userData.status,
        password: this.userData.password,
        userTypeNo: this.userData.userTypeNo
      },
      context: {
        // example of setting the headers with context per operation
        headers: new HttpHeaders().set('x-access-token', token)
      }
    }).pipe(map(data => {
      const editInfo = data.data;
      // console.log(editInfo);
      if (editInfo && editInfo) {
        localStorage.setItem('EditcurrentUser', JSON.stringify(editInfo));
      }
      return editInfo;
    }));
  }


  logout() {
    localStorage.clear();
    localStorage.removeItem('currentUser');
    localStorage.removeItem('token');
    localStorage.removeItem('currentUserDetail');
    this.currentUserSubject.next(null);
    this.isLogin = false;
    // this.router.navigate(['/login']);
    location.reload();

  }

  timeOut() {

    localStorage.clear();
    this.currentUserSubject.next(null);
    this.showMsg = true;
    this.isLogin = false;
    this.router.navigate(['/login']);
  }


}
