import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DashService } from 'app/views/dashboard/dashboard.service';
import { Report } from 'app/_models';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-header-stats',
  templateUrl: './header-stats.component.html',
  providers: [DashService]
})
export class HeaderStatsComponent implements OnInit {
  @Input() salesAdvanceSearch;
  temp: any;
  userStat: any;
  userTokenStats: any;
  totalUserCount: any;

  updateData(): void {
    this.loadOrderBasedOnItemDetailReportZa()
  }

  constructor(private dashServ: DashService) { }


  ngOnInit() {
    this.loadOrderBasedOnItemDetailReportZa()
  }

  loadOrderBasedOnItemDetailReportZa() {
    this.dashServ.UserLoggedIn()
      .pipe(take(1))
      .subscribe(
        () => {
          const data = JSON.parse(localStorage.getItem('UserTokenStats'));
          // data.OrderBasedOnItemDetailReport.sort((a, b) => parseFloat(b.quantity) - parseFloat(a.quantity));
          this.userTokenStats = data.UserTokenStats;
          this.totalUserCount = this.userTokenStats.customerUsersLoggedIn +
            this.userTokenStats.shopUsersLoggedIn +
            this.userTokenStats.supplierUsersLoggedIn +
            this.userTokenStats.adminUsersLoggedIn
          localStorage.removeItem('UserTokenStats');
        }, // success path
        error => {
          if (error.graphQLErrors.length > 0) {
            this.temp = error.graphQLErrors[0];
          } else {
            this.temp.error = error;
          }
          // this.alertService.error(this.temp.error.message);
        });
  }


}
