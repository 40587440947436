import { Report } from './../../_models/carts-orders';
import { HttpErrorResponse } from '@angular/common/http';

import { throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Router } from '@angular/router';

@Injectable()
export class DashService {
  token: string;
  userInfo: any;

  constructor(
    private http: HttpClient,
    private apollo: Apollo,
    private router: Router) {
    this.token = localStorage.getItem('token');
  }
  reportDataNew = new Report();
  reportData = new Report();

  /// GET ALL REPORTS
  getAllReportsDataZa(reportData: Report) {
    this.token = localStorage.getItem('token');
    let allReportGQL = gql;
    let q = '';
    q = `query { OrderReportsByDay (
                shopNo: ${reportData.shopNo},
                limit: ${reportData.limit},
                skip: ${reportData.skip},
                sort: {dateCreated: 1},
                dateFrom: "${reportData.dateFrom}",
                dateTo: "${reportData.dateTo}" )
                {
                  totals {
                    orderCount
                    orderTotal
                    orderAverage
                  }
                  ordersByDate {
                    vendorDoesOwnShipping
                    dateCreated
                    value
                    shippingAmount
                    discount
                  }
                }
              }`;
    allReportGQL = gql(q);
    return this.apollo.query({
      query: allReportGQL,
      variables: {
        dateFrom: reportData.dateFrom,
        limit: reportData.limit,
        skip: reportData.skip,
        dateTo: reportData.dateTo,
        shopNo: reportData.shopNo
      },
      fetchPolicy: 'network-only',
      context: {
        headers: new HttpHeaders().set('x-access-token', this.token)
      }
    }).pipe(map(newdata => {
      const userInfo = newdata;
      // console.log(userInfo);
      localStorage.setItem('OrderReports', JSON.stringify(userInfo.data));
      return userInfo.data;
    }));
  }


  getAllReportsDataUk(reportData: Report) {
    this.token = localStorage.getItem('token');
    let allReportGQL = gql;
    let q = '';
    q = `query { OrderReportsByDay (
                  shopNo: ${reportData.shopNo},
                  limit: ${reportData.limit},
                  skip: ${reportData.skip},
                  sort: {dateCreated: 1},
                  dateFrom: "${reportData.dateFrom}",
                  dateTo: "${reportData.dateTo}")
                  {
                    totals {
                      orderCount
                      orderTotal
                      orderAverage
                    }
                    ordersByDate {
                      vendorDoesOwnShipping
                      dateCreated
                      value
                      shippingAmount
                      discount
                    }
                  }
                }`;
    allReportGQL = gql(q);
    return this.apollo.query({
      query: allReportGQL,
      variables: {
        dateFrom: reportData.dateFrom,
        limit: reportData.limit,
        skip: reportData.skip,
        dateTo: reportData.dateTo,
        shopNo: reportData.shopNo
      },
      fetchPolicy: 'network-only',
      context: {
        headers: new HttpHeaders().set('x-access-token', this.token)
      }
    }).pipe(map(newdata => {
      const userInfo = newdata;
      // console.log(userInfo);
      localStorage.setItem('OrderReportsUk', JSON.stringify(userInfo.data));
      return userInfo.data;
    }));
  }

  ///
  getOrderShipmentStats(reportData: Report) {
    this.token = localStorage.getItem('token');
    let allReportGQL = gql;
    let q = '';
    q = `query { OrderShipmentStats (
                  shopNo: ${reportData.shopNo},
                  dateFrom: "${reportData.dateFrom}",
                  dateTo: "${reportData.dateTo}")
                  {
                    pendingStats
                    deliveredStats
                    canceledStats
                    processing
                    outForDelivery
                    newCustomers
                    returningCustomers
                    allItemslisted
                    topRatedItemsListed
                    newItemsListed
                    totalShipmentsCount
                    totalPayedForShipmentsCount
                    totalDelayedShipmentsCount
                    totalOrdersCount
                    totalPayedForOrders
                    totalDelayedOrders
                    itemStockCount
                    suppliersActiveCount
                  }
                }`;
    allReportGQL = gql(q);
    return this.apollo.query({
      query: allReportGQL,
      variables: {
        dateFrom: reportData.dateFrom,
        limit: reportData.limit,
        skip: reportData.skip,
        dateTo: reportData.dateTo,
        shopNo: reportData.shopNo
      },
      fetchPolicy: 'network-only',
      context: {
        headers: new HttpHeaders().set('x-access-token', this.token)
      }
    }).pipe(map(newdata => {
      const userInfo = newdata;
      localStorage.setItem('OrderShipmentStats', JSON.stringify(userInfo.data));
      return userInfo.data;
    }));
  }
  ////
  getDashStats(reportData: Report) {
    // console.log(reportData);
    const OrderShipmentStatsGQL = gql`
      query OrderShipmentStats ($shopNo: Int, $dateFrom:String, $dateTo:String){
        OrderShipmentStats(shopNo: $shopNo, dateFrom:$dateFrom, dateTo:$dateTo){
          pendingStats
                    deliveredStats
                    canceledStats
                    processing
                    outForDelivery
                    newCustomers
                    returningCustomers
                    allItemslisted
                    topRatedItemsListed
                    newItemsListed
                    totalShipmentsCount
                    totalPayedForShipmentsCount
                    totalDelayedShipmentsCount
                    totalOrdersCount
                    totalPayedForOrders
                    totalDelayedOrders
                    itemStockCount
  	                suppliersActiveCount
        }
        ItemUploadStats(shopNo: $shopNo, dateFrom:$dateFrom, dateTo:$dateTo){
          count
          message
        }
      }
    `;
    return this.apollo.query({
      context: {
        headers: new HttpHeaders().set('x-access-token', this.token)
      },
      query: OrderShipmentStatsGQL,
      variables: {
        dateFrom: reportData.dateFrom,
        dateTo: reportData.dateTo,
        shopNo: reportData.shopNo
      }
    })
      .pipe(map(newdata => {
        const userInfo = newdata;
        localStorage.setItem('OrderShipmentStats', JSON.stringify(userInfo.data));
        return userInfo.data;
      }));
  }
  ////
  OrderBasedOnItemDetailReport(reportData: Report) {
    this.token = localStorage.getItem('token');
    let allReportGQL = gql;
    let q = '';
    q = `query { OrderBasedOnItemDetailReport (
                  shopNo: ${reportData.shopNo},
                  itemDetailTypeNo: ${reportData.itemDetailTypeNo}
                  dateFrom: "${reportData.dateFrom}",
                  dateTo: "${reportData.dateTo}",sort: "quantity", sortOrder: "desc")
                  {
                    quantity
                    description
                  }
                }`;
    allReportGQL = gql(q);
    return this.apollo.query({
      query: allReportGQL,
      variables: {
        dateFrom: reportData.dateFrom,
        dateTo: reportData.dateTo,
        shopNo: reportData.shopNo
      },
      fetchPolicy: 'network-only',
      context: {
        headers: new HttpHeaders().set('x-access-token', this.token)
      }
    }).pipe(map(newdata => {
      const userInfo = newdata;
      localStorage.setItem('OrderBasedOnItemDetailReport' + reportData.shopNo, JSON.stringify(userInfo.data));
      return userInfo.data;
    }));
  }
  ///
  UserStat(reportData: Report) {
    this.token = localStorage.getItem('token');
    let allReportGQL = gql;
    let q = '';
    q = `query { UserStat (
                  dateFrom: "${reportData.dateFrom}",
                  dateTo: "${reportData.dateTo}")
                  {
                    newCustomersCountZA
                    newCustomersCountUK
                  }
                }`;
    allReportGQL = gql(q);
    return this.apollo.query({
      query: allReportGQL,
      variables: {
        dateFrom: reportData.dateFrom,
        dateTo: reportData.dateTo
      },
      fetchPolicy: 'network-only',
      context: {
        headers: new HttpHeaders().set('x-access-token', this.token)
      }
    }).pipe(map(newdata => {
      const userInfo = newdata;
      // console.log(newdata);
      localStorage.setItem('UserStat', JSON.stringify(userInfo.data));
      return userInfo.data;
    }));
  }
  ///

  UserLoggedIn() {
    this.token = localStorage.getItem('token');
    let allReportGQL = gql;
    let q = '';
    q = `query { UserTokenStats
                  {
                    customerUsersLoggedIn
                    shopUsersLoggedIn
                    supplierUsersLoggedIn
                    adminUsersLoggedIn
                  }
                }`;
    allReportGQL = gql(q);
    return this.apollo.query({
      query: allReportGQL,
      fetchPolicy: 'network-only',
      context: {
        headers: new HttpHeaders().set('x-access-token', this.token)
      }
    }).pipe(map(newdata => {
      const userInfo = newdata;
      // console.log(newdata);
      localStorage.setItem('UserTokenStats', JSON.stringify(userInfo.data));
      return userInfo.data;
    }));
  }
  ///

}
