import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './_guards';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { PasswordResetComponent } from './views/password-reset/password-reset.component';
import { RegisterComponent } from './views/register/register.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'orders',
    canActivate: [AuthGuard],
    pathMatch: 'full',
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'password-reset',
    component: PasswordResetComponent,
    data: {
      title: 'Password Reset Page'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Home',
      breadcrumb: 'Home'
    },
    children: [
      {
        path: 'accounts/upload',
        loadChildren: './views/accounts/upload/upload.module#UploadModule',
        data: {
          title: 'Content',
          breadcrumb: [
            {
              label: 'Home',
              url: '/'
            },
            {
              label: 'Accounts',
              url: ''
            }
          ]
        }
      },
      {
        path: 'accounts',
        loadChildren: './views/accounts/accounts.module#AccountsModule',
        data: {
          title: 'Content',
          breadcrumb: [
            {
              label: 'Home',
              url: '/'
            },
            {
              label: 'Accounts',
              url: ''
            }
          ]
        }
      },
      {
        path: 'accounts/:id',
        loadChildren: './views/accounts/accounts.module#AccountsModule',
        data: {
          title: 'Content',
          breadcrumb: [
            {
              label: 'Home',
              url: '/'
            },
            {
              label: 'Accounts',
              url: ''
            }
          ]
        }
      },
      {
        path: 'articles',
        loadChildren: './views/articles/articles.module#ArticlesModule'
      },
      {
        path: 'base',
        loadChildren: './views/base/base.module#BaseModule'
      },
      {
        path: 'buttons',
        loadChildren: './views/buttons/buttons.module#ButtonsModule'
      },
      {
        path: 'brands',
        loadChildren: './views/brands/brands.module#BrandsModule'
      },
      {
        path: 'site-content/:id',
        loadChildren: './views/site-content/content.module#ContentModule',
        data: {
          title: 'Content',
          breadcrumb: [
            {
              label: 'Home',
              url: '/'
            },
            {
              label: 'Shops',
              url: '/shops'
            },
            {
              label: 'Site Content',
              url: ''
            }
          ]
        }
      },
      {
        path: 'site-content/:id/:id1',
        loadChildren: './views/site-content/content.module#ContentModule',
        data: {
          title: 'Content',
          breadcrumb: [
            {
              label: 'Home',
              url: '/'
            },
            {
              label: 'Shops',
              url: '/shops'
            },
            {
              label: 'Site Content',
              url: '/site-content/:id/'
            },
            {
              label: 'Site Content Categories',
              url: ''
            }
          ]
        }
      },
      {
        path: 'site-content/:id/:id1/:id2',
        loadChildren: './views/site-content/content.module#ContentModule',
        data: {
          title: 'Content',
          breadcrumb: [
            {
              label: 'Home',
              url: '/'
            },
            {
              label: 'Shops',
              url: '/shops'
            },
            {
              label: 'Site Content',
              url: '/site-content/:id/'
            },
            {
              label: 'Site Content Categories',
              url: ''
            }
          ]
        }
      },
      {
        path: 'site-content/detail/:id/:id1/:id2/:id3/:id4/:id5',
        loadChildren: './views/site-content/detail/detail.module#DetailModule',
        data: {
          title: 'Content',
          breadcrumb: [
            {
              label: 'Home',
              url: '/'
            },
            {
              label: 'Shops',
              url: '/shops'
            },
            {
              label: 'Site Content',
              url: '/site-content/:id/'
            },
            // {
            //   label: 'Site Content Categories',
            //   url: '/site-content/:id/:id1'
            // },
            {
              label: 'Site Content Blocks',
              url: ''
            }
          ]
        }
      },
      {
        path: 'brands/add',
        loadChildren: './views/brands/detail/detail.module#DetailModule',
        data: {
          title: 'brands Add',
          breadcrumb: [
            {
              label: 'Home',
              url: '/'
            },
            {
              label: 'Brands',
              url: '/brands'
            },
            {
              label: 'Brand Add',
              url: ''
            }
          ]
        }
      },
      {
        path: 'brands/edit/:id',
        loadChildren: './views/brands/detail/detail.module#DetailModule',
        data: {
          title: 'Brand Edit',
          breadcrumb: [
            {
              label: 'Home',
              url: '/'
            },
            {
              label: 'Brands',
              url: '/brands'
            },
            {
              label: 'Brand Edit',
              url: ''
            }
          ]
        }
      },
      {
        path: 'carts/active',
        loadChildren: './views/carts/carts.module#CartsModule',
        data: {
          title: 'Customers',
          breadcrumb: [
            {
              label: 'Carts',
              url: ''
            }
          ]
        }
      },
      {
        path: 'carts/abandoned',
        loadChildren: './views/carts/carts.module#CartsModule',
        data: {
          title: 'Carts',
          breadcrumb: [
            {
              label: 'Carts',
              url: ''
            }
          ]
        }
      },
      {
        path: 'charts',
        loadChildren: './views/chartjs/chartjs.module#ChartJSModule'
      },
      //
      {
        path: 'customers',
        loadChildren: './views/customers/customers.module#CustomersModule',
        data: {
          title: 'Customers',
          breadcrumb: [
            {
              label: 'Home',
              url: '/'
            },
            {
              label: 'Customers',
              url: ''
            }
          ]
        }
      },
      {
        path: 'customers/add',
        loadChildren: './views/customers/detail/detail.module#DetailModule',
        data: {
          title: 'customers Add',
          breadcrumb: [
            {
              label: 'Home',
              url: '/'
            },
            {
              label: 'customers',
              url: '/customers'
            },
            {
              label: 'customers Add',
              url: ''
            }
          ]
        }
      },
      {
        path: 'customers/edit/:id',
        loadChildren: './views/customers/detail/detail.module#DetailModule',
        data: {
          title: 'customers Edit',
          breadcrumb: [
            {
              label: 'Home',
              url: '/'
            },
            {
              label: 'customers',
              url: '/customers'
            },
            {
              label: 'customers Edit',
              url: ''
            }
          ]
        }
      },
      {
        path: 'customers/shop/:id',
        loadChildren: './views/customers/customers.module#CustomersModule',
        data: {
          title: 'Shop Customers',
          breadcrumb: [
            {
              label: 'Home',
              url: '/'
            },
            {
              label: 'Shops',
              url: '/shops'
            },
            {
              label: 'Customers',
              url: ''
            }
          ]
        }
      },
      //
      // {
      //   path: 'customers',
      //   loadChildren: './views/customers/customers.module#CustomersModule'
      // },
      // {
      //   path: 'dashboard',
      //   redirectTo: '',
      //   loadChildren: './views/dashboard/dashboard.module#DashboardModule',
      //   data: {
      //     title: 'Dashboard',
      //     breadcrumb: [
      //       {
      //         label: 'Dashboard',
      //         url: ''
      //       }
      //     ]
      //   }
      // },
      {
        path: 'dashboard',
        loadChildren: './views/dashboard/dashboard.module#DashboardModule',
        data: {
          title: 'Dashboard',
          breadcrumb: [
            {
              label: 'Dashboard',
              url: 'Dashboard'
            }
          ]
        }
      },
      {
        path: 'editors',
        loadChildren: './views/editors/editors.module#EditorsModule'
      },
      {
        path: 'forms',
        loadChildren: './views/forms/forms.module#FormsModule'
      },
      {
        path: 'user-guides',
        loadChildren: './views/user-guides/user-guides.module#UserGuidesModule',
        data: {
          title: 'Dashboard',
          breadcrumb: [
            {
              label: 'Home / User Guides',
              url: ''
            }
          ]
        }
      },
      {
        path: 'google-maps',
        loadChildren: './views/google-maps/google-maps.module#GoogleMapsModule'
      },
      {
        path: 'icons',
        loadChildren: './views/icons/icons.module#IconsModule'
      },
      {
        path: 'catalogue',
        loadChildren: './views/items/items.module#ItemsModule'
      },
      {
        path: 'notifications',
        loadChildren: './views/notifications/notifications.module#NotificationsModule'
      },
      {
        path: 'orders',
        loadChildren: './views/orders/orders.module#OrdersModule'
      },
      {
        path: 'orders/detail/:id',
        loadChildren: './views/orders/detail/detail.module#DetailModule',
        data: {
          title: 'Order Detail',
          breadcrumb: [
            {
              label: 'Orders',
              url: '/orders'
            },
            {
              label: 'Order Detail',
              url: ''
            }
          ]
        }
      },
      {
        path: 'orders/:id/:id1',
        loadChildren: './views/orders/orders.module#OrdersModule'
      },
      {
        path: 'orders/:id/:id1/:id2/:id3',
        loadChildren: './views/orders/orders.module#OrdersModule'
      },
      {
        path: 'plugins',
        loadChildren: './views/plugins/plugins.module#PluginsModule'
      },
      {
        path: 'reports',
        loadChildren: './views/reports/reports.module#ReportsModule',
      },
      {
        path: 'reports/sales-product/:id/:id1',
        loadChildren: './views/reports/product/sales-product.module#SalesModule',
        data: {
          title: 'Sales',
          breadcrumb: [
            {
              label: 'Home',
              url: '/'
            },
            {
              label: 'Reports',
              url: '/reports'
            },
            {
              label: 'Products',
              url: ''
            }
          ]
        },
      },
      {
        path: 'reports/sales-orders/:id/:id1',
        loadChildren: './views/reports/orders/sales-orders.module#SalesModule',
        data: {
          title: 'Sales',
          breadcrumb: [
            {
              label: 'Home',
              url: '/'
            },
            {
              label: 'Reports',
              url: '/reports'
            },
            {
              label: 'Orders',
              url: ''
            }
          ]
        },
      },
      {
        path: 'reports/sales-product',
        loadChildren: './views/reports/product/sales-product.module#SalesModule',
        data: {
          title: 'Sales',
          breadcrumb: [
            {
              label: 'Home',
              url: '/'
            },
            {
              label: 'Reports',
              url: '/reports'
            },
            {
              label: 'Products',
              url: ''
            }
          ]
        },
      },
      {
        path: 'reports/sales-orders',
        loadChildren: './views/reports/orders/sales-orders.module#SalesModule',
        data: {
          title: 'Sales',
          breadcrumb: [
            {
              label: 'Home',
              url: '/'
            },
            {
              label: 'Reports',
              url: '/reports'
            },
            {
              label: 'Orders',
              url: ''
            }
          ]
        },
      },
      {
        path: 'reports/sales-picklist',
        loadChildren: './views/reports/picklist/sales-picklist.module#SalesModule',
        data: {
          title: 'Sales',
          breadcrumb: [
            {
              label: 'Home',
              url: '/'
            },
            {
              label: 'Reports',
              url: '/reports'
            },
            {
              label: 'Picklists',
              url: ''
            }
          ]
        },
      },
      {
        path: 'reports/items-vendor',
        loadChildren: './views/reports/items-vendor/items-vendor.module#SalesModule',
        data: {
          title: 'Sales',
          breadcrumb: [
            {
              label: 'Home',
              url: '/'
            },
            {
              label: 'Reports',
              url: '/reports'
            },
            {
              label: 'Vendor Items Sold',
              url: ''
            }
          ]
        },
      },
      {
        path: 'reports/sales-picklist/:id/:id1',
        loadChildren: './views/reports/picklist/sales-picklist.module#SalesModule',
        data: {
          title: 'Sales',
          breadcrumb: [
            {
              label: 'Home',
              url: '/'
            },
            {
              label: 'Reports',
              url: '/reports'
            },
            {
              label: 'Picklists',
              url: ''
            }
          ]
        },
      },
      {
        path: 'reports/sales-vendor',
        loadChildren: './views/reports/vendor/sales-vendor.module#SalesModule',
        data: {
          title: 'Sales',
          breadcrumb: [
            {
              label: 'Home',
              url: '/'
            },
            {
              label: 'Reports',
              url: '/reports'
            },
            {
              label: 'Vendors',
              url: ''
            }
          ]
        },
      },
      {
        path: 'reports/sales-vendor/:id/:id1',
        loadChildren: './views/reports/vendor/sales-vendor.module#SalesModule',
        data: {
          title: 'Sales',
          breadcrumb: [
            {
              label: 'Home',
              url: '/'
            },
            {
              label: 'Reports',
              url: '/reports'
            },
            {
              label: 'Vendors',
              url: ''
            }
          ]
        },
      },
      {
        path: 'reports/carts-orders',
        loadChildren: './views/reports/carts-orders/carts-orders.module#CartsOrdersModule',
        data: {
          title: 'Carts Orders',
          breadcrumb: [
            {
              label: 'Home',
              url: '/'
            },
            {
              label: 'Reports',
              url: '/reports'
            },
            {
              label: 'Sales Vendors',
              url: ''
            }
          ]
        },
      },
      {
        path: 'settings',
        loadChildren: './views/settings/settings.module#SettingsModule'
      },
      {
        path: 'shipments',
        loadChildren: './views/shipments/shipments.module#ShipmentsModule'
      },
      {
        path: 'shipments/:id/:id1',
        loadChildren: './views/shipments/shipments.module#ShipmentsModule'
      },
      {
        path: 'shipments/:id/:id1/:id2/:id3',
        loadChildren: './views/shipments/shipments.module#ShipmentsModule'
      },
      {
        path: 'shipments/:id/:id1/:id2/:id3/:id4',
        loadChildren: './views/shipments/shipments.module#ShipmentsModule'
      },
      {
        path: 'shipments/:id/:id1/:id2/:id3/:id4/:id5',
        loadChildren: './views/shipments/shipments.module#ShipmentsModule'
      },
      {
        path: 'shipments/:id/:id1/:id2/:id3/:id4/:id5/:id6',
        loadChildren: './views/shipments/shipments.module#ShipmentsModule'
      },
      {
        path: 'shops',
        loadChildren: './views/shops/shops.module#ShopsModule'
      },
      {
        path: 'shop/edit',
        loadChildren: './views/shops/detail/detail.module#DetailModule',
        data: {
          title: 'Shop Edit',
          breadcrumb: [
            {
              label: 'Home',
              url: '/'
            },
            {
              label: 'Shop Edit',
              url: ''
            }
          ]
        }
      },
      {
        path: 'shops/add',
        loadChildren: './views/shops/detail/detail.module#DetailModule',
        data: {
          title: 'Shops Add',
          breadcrumb: [
            {
              label: 'Home',
              url: '/'
            },
            {
              label: 'Shops',
              url: '/shops'
            },
            {
              label: 'Shop Add',
              url: ''
            }
          ]
        }
      },
      {
        path: 'shops/edit/:id',
        loadChildren: './views/shops/detail/detail.module#DetailModule',
        data: {
          title: 'Shops Edit',
          breadcrumb: [
            {
              label: 'Home',
              url: '/'
            },
            {
              label: 'Shops',
              url: '/shops'
            },
            {
              label: 'Shop Edit',
              url: ''
            }
          ]
        }
      },
      {
        path: 'vendors',
        loadChildren: './views/vendors/vendors.module#VendorsModule'
      },
      {
        path: 'shop/edit',
        loadChildren: './views/shops/detail/detail.module#DetailModule',
        data: {
          title: 'Shop Edit',
          breadcrumb: [
            {
              label: 'Home',
              url: '/'
            },
            {
              label: 'Shop Edit',
              url: ''
            }
          ]
        }
      },
      {
        path: 'vendors/add-vendor',
        loadChildren: './views/vendors/detail/detail.module#DetailModule',
        data: {
          title: 'Vendors Add',
          breadcrumb: [
            {
              label: 'Home',
              url: '/'
            },
            {
              label: 'Vendors',
              url: '/vendors'
            },
            {
              label: 'Vendor Add',
              url: ''
            }
          ]
        }
      },
      {
        path: 'vendors/edit/:id',
        loadChildren: './views/vendors/detail/detail.module#DetailModule',
        data: {
          title: 'Vendors Edit',
          breadcrumb: [
            {
              label: 'Home',
              url: '/'
            },
            {
              label: 'Vendors',
              url: '/vendors'
            },
            {
              label: 'Vendor Edit',
              url: ''
            }
          ]
        }
      },
      {
        path: 'vendor/edit',
        loadChildren: './views/vendors/detail/detail.module#DetailModule',
        data: {
          title: 'Vendor Edit',
          breadcrumb: [
            {
              label: 'Home',
              url: '/'
            },
            {
              label: 'Vendors',
              url: '/vendors'
            },
            {
              label: 'Vendor Edit',
              url: ''
            }
          ]
        }
      },
      {
        path: 'vouchers',
        loadChildren: './views/vouchers/vouchers.module#VouchersModule'
      },
      {
        path: 'vouchers/add',
        loadChildren: './views/vouchers/detail/detail.module#DetailModule',
        data: {
          title: 'Vouchers Add',
          breadcrumb: [
            {
              label: 'Home',
              url: '/'
            },
            {
              label: 'Vouchers',
              url: '/vouchers'
            },
            {
              label: 'Voucher Add',
              url: ''
            }
          ]
        }
      },
      {
        path: 'vouchers/edit/:id',
        loadChildren: './views/vouchers/detail/detail.module#DetailModule',
        data: {
          title: 'Vouchers Edit',
          breadcrumb: [
            {
              label: 'Home',
              url: '/'
            },
            {
              label: 'Vouchers',
              url: '/vouchers'
            },
            {
              label: 'Voucher Edit',
              url: ''
            }
          ]
        }
      },
      {
        path: 'tables',
        loadChildren: './views/tables/tables.module#TablesModule'
      },
      {
        path: 'theme',
        loadChildren: './views/theme/theme.module#ThemeModule'
      },
      {
        path: 'users/shop/:id',
        loadChildren: './views/users/users.module#UsersModule',
        data: {
          title: 'Shop Users',
          breadcrumb: [
            {
              label: 'Home',
              url: '/'
            },
            {
              label: 'Shops',
              url: '/shops'
            },
            {
              label: 'Users',
              url: ''
            }
          ]
        }
      },
      {
        path: 'users/vendors/:id',
        loadChildren: './views/users/users.module#UsersModule',
        data: {
          title: 'Vendor Users',
          breadcrumb: [
            {
              label: 'Home',
              url: '/'
            },
            {
              label: 'Vendors',
              url: '/vendors'
            },
            {
              label: 'Users',
              url: ''
            }
          ]
        }
      },
      {
        path: 'users',
        loadChildren: './views/users/users.module#UsersModule',
        data: {
          title: 'Users',
          breadcrumb: [
            {
              label: 'Home',
              url: '/'
            },
            {
              label: 'Users',
              url: ''
            }
          ]
        }
      },
      {
        path: 'users/add',
        loadChildren: './views/users/detail/detail.module#DetailModule',
        data: {
          title: 'Users Add',
          breadcrumb: [
            {
              label: 'Home',
              url: '/'
            },
            {
              label: 'Users',
              url: '/users'
            },
            {
              label: 'User Add',
              url: ''
            }
          ]
        }
      },
      {
        path: 'users/sub/:id',
        loadChildren: './views/users/detail/detail.module#DetailModule',
        data: {
          title: 'Users Add',
          breadcrumb: [
            {
              label: 'Home',
              url: '/'
            },
            {
              label: 'Users',
              url: '/users'
            },
            {
              label: 'User Add',
              url: ''
            }
          ]
        }
      },
      {
        path: 'users/:id/shop',
        loadChildren: './views/users/detail/detail.module#DetailModule',
        data: {
          title: 'Users Add',
          breadcrumb: [
            {
              label: 'Home',
              url: '/'
            },
            {
              label: 'Users',
              url: '/users'
            },
            {
              label: 'User Add',
              url: ''
            }
          ]
        }
      },
      {
        path: 'users/:id/supplier',
        loadChildren: './views/users/detail/detail.module#DetailModule',
        data: {
          title: 'Users Add',
          breadcrumb: [
            {
              label: 'Home',
              url: '/'
            },
            {
              label: 'Users',
              url: '/users'
            },
            {
              label: 'User Add',
              url: ''
            }
          ]
        }
      },
      {
        path: 'users/edit/:id',
        loadChildren: './views/users/detail/detail.module#DetailModule',
        data: {
          title: 'Users Edit',
          breadcrumb: [
            {
              label: 'Home',
              url: '/'
            },
            {
              label: 'Users',
              url: '/users'
            },
            {
              label: 'User Edit',
              url: ''
            }
          ]
        }
      },
      {
        path: 'users/shop/:id',
        loadChildren: './views/users/users.module#UsersModule',
        data: {
          title: 'Shop Users',
          breadcrumb: [
            {
              label: 'Home',
              url: '/'
            },
            {
              label: 'users',
              url: '/users'
            },
            {
              label: 'User Edit',
              url: ''
            }
          ]
        }
      },
      ///
      {
        path: 'apps',
        loadChildren: './views/apps/apps.module#AppsModule'
      },
      {
        path: 'widgets',
        loadChildren: './views/widgets/widgets.module#WidgetsModule'
      }
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
