import { ItemsService } from './../../items.service';
import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Store, select, Action } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { of, from, Observable } from 'rxjs';
import { catchError, map, exhaustMap, debounceTime, tap, switchMap, withLatestFrom, flatMap, mergeMap } from 'rxjs/operators';

import { Item, ItemBrand, ItemDetailInput, ItemDetail, ItemDetailType, ItemImage, Supplier, SupplierItem, SupplierItemInput, ItemInline, valueLabel } from './../../models';

import * as fromRoot from 'app/@store';
import * as fromActions from './items.action';
import * as fromSelectors from './items.selector';
import * as fromReducer from './items.reducer';
import { Country } from 'app/_models';
import { HighLevelTypes } from 'app/_models/high-level-type';
import { ItemReview } from '../../models/item-review';

@Injectable()
export class ItemsEffects {

  constructor(
    private actions$: Actions,
    private store: Store<fromRoot.State>,
    private itemsService: ItemsService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
  }

  @Effect()
  loadItems$: Observable<fromActions.LoadItemsSuccess | fromActions.LoadItemsFail> = this.actions$.pipe(
    ofType<fromActions.LoadItems>(fromActions.LOAD_ITEMS),
    withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))),
    flatMap(([action, state]: [fromActions.LoadItems, fromReducer.ItemsState]) => {
      // console.log('effect');
      return this.itemsService.loadItems(action['payload'].filter).pipe(
        map((items: Item[]) => {
          // console.log('items from effect', items)
          return new fromActions.LoadItemsSuccess({ items, filter: action['payload'].filter })
        }),
        catchError((error: any) => {
          // console.log('error', error);
          return of(new fromActions.LoadItemsFail({ error }))
        }
        )
      );
    })
  );

  @Effect()
  loadSearchedItems$: Observable<fromActions.LoadSearchedItemsSuccess | fromActions.LoadItemsFail> = this.actions$.pipe(
    ofType<fromActions.LoadItems>(fromActions.LOAD_SEARCHED_ITEMS),
    withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))),
    flatMap(([action, state]: [fromActions.LoadSearchedItems, fromReducer.ItemsState]) => {
      // console.log('effect');
      return this.itemsService.loadItems(action['payload'].filter).pipe(
        map((searchedItems: Item[]) => {
          // console.log('items from effect', searchedItems)
          return new fromActions.LoadSearchedItemsSuccess({ searchedItems, filter: action['payload'].filter })
        }),
        catchError((error: any) => {
          // console.log('error', error);
          return of(new fromActions.LoadItemsFail({ error }))
        }
        )
      );
    })
  );

  @Effect()
  loadSuggestedImages$: Observable<fromActions.LoadSuggestedImagesSuccess | fromActions.LoadSuggestedImagesFail> = this.actions$.pipe(
    ofType<fromActions.LoadSuggestedImages>(fromActions.LOAD_SUGGESTED_IMAGES),
    withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))),
    flatMap(([action, state]: [fromActions.LoadSuggestedImages, fromReducer.ItemsState]) => {

      return this.itemsService.loadItems({ brand_id: action['payload'].brandId }).pipe(
        map((items: Item[]) => {
          const itemImages: ItemImage[] = [];
          items.forEach(item => {
            itemImages.push(...item.itemImages);
          });
          return new fromActions.LoadSuggestedImagesSuccess({ itemImages });
        }),
        catchError((error: any) => of(new fromActions.LoadSuggestedImagesFail({ error })))
      );
    })
  );

  @Effect()
  loadHighLevelTypes$: Observable<fromActions.LoadHighLevelTypesSuccess | fromActions.LoadHighLevelTypesFail> = this.actions$.pipe(
    ofType<fromActions.LoadHighLevelTypes>(fromActions.LOAD_HIGH_LEVEL_TYPES),
    withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))),
    flatMap(([action, state]: [fromActions.LoadHighLevelTypes, fromReducer.ItemsState]) => {

      return this.itemsService.loadHighLevelTypes().pipe(
        map((highLevelTypes: HighLevelTypes[]) => new fromActions.LoadHighLevelTypesSuccess({ highLevelTypes })),
        catchError((error: any) => of(new fromActions.LoadHighLevelTypesFail({ error })))
      );
    })
  );

  @Effect()
  loadItemDetailTypes$: Observable<fromActions.LoadItemDetailTypesSuccess | fromActions.LoadItemDetailTypesFail> = this.actions$.pipe(
    ofType<fromActions.LoadItemDetailTypes>(fromActions.LOAD_ITEM_DETAIL_TYPES),
    withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))),
    flatMap(([action, state]: [fromActions.LoadItemDetailTypes, fromReducer.ItemsState]) => {

      return this.itemsService.loadItemDetailTypes().pipe(
        map((itemDetailTypes: ItemDetailType[]) => new fromActions.LoadItemDetailTypesSuccess({ itemDetailTypes })),
        catchError((error: any) => of(new fromActions.LoadItemDetailTypesFail({ error })))
      );
    })
  );

  @Effect()
  loadItem$: Observable<fromActions.ItemsAction> = this.actions$.pipe(
    ofType<fromActions.LoadItem>(fromActions.LOAD_ITEM),
    withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))),
    flatMap(([action, state]: [fromActions.LoadItem, fromReducer.ItemsState]) => {

      return this.itemsService.loadItem(action['payload'].id).pipe(
        flatMap((item: Item) => {
          return [
            new fromActions.LoadItemSuccess({ item }),
            new fromActions.LoadBrands(),
            // new fromActions.LoadLabels(),
            new fromActions.LoadHighLevelTypes(),
            new fromActions.LoadCountries()
          ]
        })
        // catchError((error: any) => {of(new fromActions.LoadItemFail({ error })))}
        ,
        catchError((error: any) => {
          // console.log('error', error);
          return of(new fromActions.LoadItemFail({ error }))
        }
        )
      );
    })
  );

  @Effect()
  loadMixedCase$: Observable<fromActions.ItemsAction> = this.actions$.pipe(
    ofType<fromActions.LoadItem>(fromActions.LOAD_MIXED_CASE),
    withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))),
    flatMap(([action, state]: [fromActions.LoadMixedCase, fromReducer.ItemsState]) => {

      return this.itemsService.loadItem(action['payload'].id).pipe(
        flatMap((item: Item) => {
          return [
            new fromActions.LoadMixedCaseSuccess({ item }),
            new fromActions.LoadBrands(),
            // new fromActions.LoadLabels(),
            new fromActions.LoadHighLevelTypes(),
            new fromActions.LoadCountries()
          ]
        })
        // catchError((error: any) => {of(new fromActions.LoadItemFail({ error })))}
        ,
        catchError((error: any) => {
          // console.log('error', error);
          return of(new fromActions.LoadItemFail({ error }))
        }
        )
      );
    })
  );

  @Effect()
  loadItemMixed$: Observable<fromActions.ItemsAction> = this.actions$.pipe(
    ofType<fromActions.LoadItem>(fromActions.LOAD_MIXED_ITEM),
    withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))),
    flatMap(([action, state]: [fromActions.LoadMixedItem, fromReducer.ItemsState]) => {
      // console.log('item mixed')
      return this.itemsService.loadItemMixed(action['payload'].id, action['payload'].item_id).pipe(
        flatMap((item: Item) => {
          return [
            new fromActions.LoadMixedItemSuccess({ item }),
            new fromActions.LoadBrands(),
            // new fromActions.LoadLabels(),
            new fromActions.LoadHighLevelTypes(),
            new fromActions.LoadCountries()
          ]
        })
        // catchError((error: any) => {of(new fromActions.LoadItemFail({ error })))}
        ,
        catchError((error: any) => {
          // console.log('error', error);
          return of(new fromActions.LoadItemFail({ error }))
        }
        )
      );
    })
  );


  // duplicate item
  @Effect()
  duplicateItem$: Observable<fromActions.DuplicateItemSuccess | fromActions.DuplicateItemFail> = this.actions$.pipe(
    ofType<fromActions.DuplicateItem>(fromActions.DUPLICATE_ITEM),
    withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))),
    flatMap(([action, state]: [fromActions.DuplicateItem, fromReducer.ItemsState]) => {
      return this.itemsService.duplicateItem(action['payload']._id).pipe(
        flatMap((item: Item) => {
          if (item && item.mixed_case === true) {
            this.router.navigate([`catalogue/mixed-cases/edit/2/${item._id}`]);
          } else {
            this.router.navigate([`catalogue/items/edit/2/${item._id}`]);
          }
          return [
            new fromActions.DuplicateItemSuccess({ item })
          ];
        }),
        catchError((error: any) => of(new fromActions.DuplicateItemFail({ error })))
      );
    })
  );
  // duplicate item


  // duplicate item
  @Effect()
  duplicateMixedItem$: Observable<fromActions.DuplicateMixedItemSuccess | fromActions.DuplicateMixedItemFail | fromActions.LoadMixedCase> = this.actions$.pipe(
    ofType<fromActions.DuplicateMixedItem>(fromActions.DUPLICATE_MIXED_ITEM),
    withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))),
    flatMap(([action, state]: [fromActions.DuplicateMixedItem, fromReducer.ItemsState]) => {
      return this.itemsService.duplicateMixedItem(action['payload'].clone_item_id, action['payload'].item_id).pipe(
        flatMap((item: Item) => {
          let mixedCase: Item = state.currentMixedCase
          return [
            new fromActions.LoadMixedCase({ id: mixedCase._id })
          ];
        }),
        catchError((error: any) => of(new fromActions.DuplicateMixedItemFail({ error })))
      );
    })
  );
  // duplicate item

  // duplicate item
  @Effect()
  duplicateItemMixedItem$: Observable<fromActions.DuplicateMixedItemSuccess | fromActions.DuplicateMixedItemFail | fromActions.LoadMixedCase> = this.actions$.pipe(
    ofType<fromActions.DuplicateItemMixedItem>(fromActions.DUPLICATE_ITEM_MIXED_ITEM),
    withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))),
    flatMap(([action, state]: [fromActions.DuplicateItemMixedItem, fromReducer.ItemsState]) => {
      return this.itemsService.duplicateItemMixedItem(action['payload'].clone_item_id, action['payload'].item_id).pipe(
        flatMap((item: Item) => {
          let mixedCase: Item = state.currentMixedCase
          return [
            new fromActions.LoadMixedCase({ id: mixedCase._id })
          ];
        }),
        catchError((error: any) => of(new fromActions.DuplicateMixedItemFail({ error })))
      );
    })
  );
  // duplicate item

  @Effect()
  createItem$: Observable<fromActions.ItemsAction> = this.actions$.pipe(
    ofType<fromActions.CreateItem>(fromActions.CREATE_ITEM),
    withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))),
    flatMap(([action, state]: [fromActions.CreateItem, fromReducer.ItemsState]) => {

      return this.itemsService.createItem(action['payload'].item).pipe(
        flatMap((item: Item) => {
          if (item && item.mixed_case === true) {
            this.router.navigate([`catalogue/mixed-cases/edit/2/${item._id}`]);
          } else {
            this.router.navigate([`catalogue/items/edit/2/${item._id}`]);
          }
          return [
            new fromActions.CreateItemSuccess({ item })
          ];
        }),
        catchError((error: any) => of(new fromActions.CreateItemFail({ error })))
      );
    })
  );

  @Effect()
  createMixedItem$: Observable<fromActions.ItemsAction> = this.actions$.pipe(
    ofType<fromActions.CreateItem>(fromActions.CREATE_MIXED_ITEM),
    withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))),
    flatMap(([action, state]: [fromActions.CreateMixedItem, fromReducer.ItemsState]) => {

      return this.itemsService.createMixedItem(action['payload'].item).pipe(
        flatMap((item: Item) => {
          // console.log(item)
          let mixedCase: Item = state.currentMixedCase
          return [
            new fromActions.CreateMixedItemSuccess({ item }),
            new fromActions.LoadMixedCase({ id: mixedCase._id })
          ];
        }),
        catchError((error: any) => of(new fromActions.CreateItemFail({ error })))
      );
    })
  );

  // let mixedCase: Item = state.currentMixedCase
  // if (mixedCase) {

  //   return new fromActions.LoadMixedCase({ id: mixedCase._id })
  // }

  @Effect()
  createItemDetail$: Observable<fromActions.CreateItemDetailSuccess | fromActions.CreateItemDetailFail | fromActions.LoadMixedCase> = this.actions$.pipe(
    ofType<fromActions.CreateItemDetail>(fromActions.CREATE_ITEM_DETAIL),
    withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))),
    flatMap(([action, state]: [fromActions.CreateItemDetail, fromReducer.ItemsState]) => {
      // console.log('createItemDetail')
      return this.itemsService.createItemDetail(action['payload'].itemDetail).pipe(
        flatMap((itemDetail: ItemDetail) => {
          // console.log(itemDetail)

          let mixedCase: Item = state.currentMixedCase
          if (mixedCase) {
            return [
              new fromActions.LoadMixedCase({ id: mixedCase._id })
            ];
          } else {
            return [
              new fromActions.CreateItemDetailSuccess({ itemDetail }),
              // new fromActions.LoadMixedCase({ id: mixedCase._id })
            ];
          }
        }),
        catchError((error: any) => of(new fromActions.CreateItemDetailFail({ error })))
      );
    })
  );
  //       map((itemDetail: ItemDetail) => new fromActions.CreateItemDetailSuccess({ itemDetail })),
  //       catchError((error: any) => of(new fromActions.CreateItemDetailFail({ error })))
  //     );
  //   })
  // );

  @Effect()
  createMixedItemDetail$: Observable<fromActions.CreateMixedItemDetailSuccess | fromActions.CreateItemDetailFail | fromActions.LoadMixedCase> = this.actions$.pipe(
    ofType<fromActions.CreateItemDetail>(fromActions.CREATE_MIXED_ITEM_DETAIL),
    withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))),
    flatMap(([action, state]: [fromActions.CreateItemDetail, fromReducer.ItemsState]) => {
      // console.log('xxx createMixedItemDetail', state)
      let mixedCase: Item = state.currentMixedCase
      return this.itemsService.createMixedItemDetail(action['payload'].itemDetail).pipe(
        flatMap((itemDetail: ItemDetail) => {
          // console.log('createMixedItemDetail', action['payload'])
          // console.log('createMixedItemDetail state', state.currentMixedCase)
          // return new fromActions.UpdateMixedItemSuccess({ item });
          let mixedCase: Item = state.currentMixedCase
          let mixedItem: Item = state.currentMixedItem
          return [
            new fromActions.UpdateMixedItemDetailSuccess({ itemDetail }),
            new fromActions.LoadMixedCase({ id: mixedCase._id }),
            new fromActions.LoadMixedItem({ id: mixedItem._id, item_id: mixedCase._id })

          ];
        }),
        catchError((error: any) => of(new fromActions.CreateItemDetailFail({ error })))
        // map((itemDetail: ItemDetail) => new fromActions.LoadMixedCase({ id: mixedCase._id })),
        // catchError((error: any) => of(new fromActions.CreateItemDetailFail({ error })))
      );
    })
  );

  @Effect()
  updateItemDetail$: Observable<fromActions.UpdateItemDetailSuccess | fromActions.UpdateItemDetailFail | fromActions.LoadMixedCase> = this.actions$.pipe(
    ofType<fromActions.UpdateItemDetail>(fromActions.UPDATE_ITEM_DETAIL),
    withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))),
    flatMap(([action, state]: [fromActions.UpdateItemDetail, fromReducer.ItemsState]) => {
      return this.itemsService.updateItemDetail(action['payload'].itemDetail).pipe(
        flatMap((itemDetail: ItemDetail) => {
          // console.log(itemDetail)
          let mixedCase: Item = state.currentMixedCase
          if (mixedCase) {
            return [
              new fromActions.LoadMixedCase({ id: mixedCase._id })
            ];
          } else {
            return [
              new fromActions.UpdateItemDetailSuccess({ itemDetail }),
              // new fromActions.LoadMixedCase({ id: mixedCase._id })
            ];
          }
        }),
        catchError((error: any) => of(new fromActions.UpdateItemDetailFail({ error })))
      );
    })
  );

  //     return this.itemsService.updateItemDetail(action['payload'].itemDetail).pipe(
  //       map((itemDetail: ItemDetail) => new fromActions.UpdateItemDetailSuccess({ itemDetail })),
  //       catchError((error: any) => of(new fromActions.UpdateItemDetailFail({ error })))
  //     );
  //   })
  // );

  @Effect()
  updateMixedItemDetail$: Observable<fromActions.UpdateMixedItemDetailSuccess | fromActions.UpdateItemDetailFail | fromActions.LoadMixedCase> = this.actions$.pipe(
    ofType<fromActions.UpdateItemDetail>(fromActions.UPDATE_MIXED_ITEM_DETAIL),
    withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))),
    flatMap(([action, state]: [fromActions.UpdateMixedItemDetailSuccess, fromReducer.ItemsState]) => {
      // console.log(action['payload'])
      return this.itemsService.updateMixedItemDetail(action['payload'].itemDetail).pipe(
        flatMap((itemDetail: ItemDetail) => {
          // console.log('updateMixedItemDetail', action['payload'])
          // console.log('updateMixedItemDetail state', state.currentMixedCase)
          // return new fromActions.UpdateMixedItemSuccess({ item });
          let mixedCase: Item = state.currentMixedCase
          let mixedItem: Item = state.currentMixedItem
          return [
            new fromActions.UpdateMixedItemDetailSuccess({ itemDetail }),
            new fromActions.LoadMixedCase({ id: mixedCase._id }),
            new fromActions.LoadMixedItem({ id: mixedItem._id, item_id: mixedCase._id })

          ];
        }),
        catchError((error: any) => of(new fromActions.UpdateItemDetailFail({ error })))
      );
    })
  );

  @Effect()
  updateMixedItem$: Observable<fromActions.UpdateItemFail | fromActions.LoadMixedCase> = this.actions$.pipe(
    ofType<fromActions.UpdateItem>(fromActions.UPDATE_MIXED_ITEM),
    withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))),
    flatMap(([action, state]: [fromActions.UpdateMixedItem, fromReducer.ItemsState]) => {
      // console.log(action['payload'])
      return this.itemsService.updateMixedItem(action['payload'].item).pipe(
        flatMap((item: Item) => {
          // console.log('updateMixedItem', action['payload'])
          // console.log('updateMixedItem state', state.currentMixedCase)
          // return new fromActions.UpdateMixedItemSuccess({ item });
          let mixedCase: Item = state.currentMixedCase
          let mixedItem: Item = state.currentMixedItem
          return [
            new fromActions.LoadMixedItem({ id: mixedItem._id, item_id: mixedCase._id }),
            new fromActions.LoadMixedCase({ id: mixedCase._id })
          ];
        }),
        catchError((error: any) => of(new fromActions.UpdateItemFail({ error })))
      );
    })
  );

  @Effect()
  deleteItemDetail$: Observable<fromActions.DeleteItemDetailSuccess | fromActions.DeleteItemDetailFail> = this.actions$.pipe(
    ofType<fromActions.DeleteItemDetail>(fromActions.DELETE_ITEM_DETAIL),
    withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))),
    flatMap(([action, state]: [fromActions.DeleteItemDetail, fromReducer.ItemsState]) => {
      // console.log('deleteItemDetail state', state.currentMixedCase)
      return this.itemsService.deleteItemDetail(action['payload'].itemDetail).pipe(
        map((itemDetail: ItemDetail) => new fromActions.DeleteItemDetailSuccess({ itemDetail })),
        catchError((error: any) => of(new fromActions.DeleteItemDetailFail({ error })))
      );
    })

  );

  @Effect()
  deleteMixedItemDetail$: Observable<fromActions.UpdateMixedItemDetailSuccess | fromActions.DeleteItemDetailFail | fromActions.LoadMixedCase> = this.actions$.pipe(
    ofType<fromActions.DeleteMixedItemDetail>(fromActions.DELETE_MIXED_ITEM_DETAIL),
    withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))),
    flatMap(([action, state]: [fromActions.DeleteMixedItemDetail, fromReducer.ItemsState]) => {
      // console.log('deleteItemDetail state', state.currentMixedCase)
      return this.itemsService.deleteMixedItemDetail(action['payload'].itemDetail).pipe(
        flatMap((itemDetail: ItemDetail) => {
          // console.log('createMixedItemDetail', action['payload'])
          // console.log('createMixedItemDetail state', state.currentMixedCase)
          // return new fromActions.UpdateMixedItemSuccess({ item });
          let mixedCase: Item = state.currentMixedCase
          let mixedItem: Item = state.currentMixedItem
          return [
            new fromActions.UpdateMixedItemDetailSuccess({ itemDetail }),
            new fromActions.LoadMixedCase({ id: mixedCase._id }),
            new fromActions.LoadMixedItem({ id: mixedItem._id, item_id: mixedCase._id })

          ];
        }),
        catchError((error: any) => of(new fromActions.DeleteItemDetailFail({ error })))
        // map((itemDetail: ItemDetail) => new fromActions.LoadMixedCase({ id: mixedCase._id })),
        // catchError((error: any) => of(new fromActions.CreateItemDetailFail({ error })))
      );
    })
  );



  @Effect()
  updateItem$: Observable<fromActions.UpdateItemSuccess | fromActions.UpdateItemFail | fromActions.LoadMixedCase> = this.actions$.pipe(
    ofType<fromActions.UpdateItem>(fromActions.UPDATE_ITEM),
    withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))),
    flatMap(([action, state]: [fromActions.UpdateItem, fromReducer.ItemsState]) => {
      return this.itemsService.updateItem(action['payload'].item).pipe(
        map((item: Item) => {
          // console.log('xxx updateItem', item)
          let mixedCase: Item = state.currentMixedCase
          if (mixedCase) {

            return new fromActions.LoadMixedCase({ id: mixedCase._id })
          }

          return new fromActions.UpdateItemSuccess({ item });
        }),
        catchError((error: any) => of(new fromActions.UpdateItemFail({ error })))
      );
    })
  );

  @Effect()
  deleteItem$: Observable<fromActions.DeleteItemSuccess | fromActions.DeleteItemFail> = this.actions$.pipe(
    ofType<fromActions.DeleteItem>(fromActions.DELETE_ITEM),
    withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))),
    flatMap(([action, state]: [fromActions.DeleteItem, fromReducer.ItemsState]) => {

      return this.itemsService.deleteItem(action['payload']._id).pipe(
        map((item: Item) => new fromActions.DeleteItemSuccess({ item })),
        catchError((error: any) => of(new fromActions.DeleteItemFail({ error })))
      );
    })
  );

  @Effect()
  deleteMixedItem$: Observable<fromActions.DeleteItemSuccess | fromActions.DeleteItemFail | fromActions.LoadMixedCase> = this.actions$.pipe(
    ofType<fromActions.DeleteItem>(fromActions.DELETE_MIXED_ITEM),
    withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))),
    flatMap(([action, state]: [fromActions.DeleteMixedItem, fromReducer.ItemsState]) => {
      let mixedCase: Item = state.currentMixedCase
      return this.itemsService.deleteMixedItem(action['payload']._id, action['payload'].item_id).pipe(
        map((item: Item) => new fromActions.LoadMixedCase({ id: mixedCase._id })),
        catchError((error: any) => of(new fromActions.DeleteItemFail({ error })))
      );
    })
  );

  @Effect()
  deleteItemImage$: Observable<fromActions.DeleteItemImageSuccess | fromActions.DeleteItemImageFail> = this.actions$.pipe(
    ofType<fromActions.DeleteItemImage>(fromActions.DELETE_ITEM_IMAGE),
    withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))),
    flatMap(([action, state]: [fromActions.DeleteItemImage, fromReducer.ItemsState]) => {
      // console.log('delete')
      return this.itemsService.deleteItemImage(action['payload']).pipe(
        map((item: Item) => new fromActions.DeleteItemImageSuccess({ ...action['payload'] })),
        catchError((error: any) => of(new fromActions.DeleteItemImageFail({ error })))
      );
    })
  );

  @Effect()
  deleteItemMixedCaseImage$: Observable<fromActions.DeleteItemMixedCaseImageSuccess | fromActions.DeleteItemImageFail | fromActions.LoadMixedCase> = this.actions$.pipe(
    ofType<fromActions.DeleteItemMixedCaseImage>(fromActions.DELETE_ITEM_MIXED_CASE_IMAGE),
    withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))),
    flatMap(([action, state]: [fromActions.DeleteItemMixedCaseImage, fromReducer.ItemsState]) => {
      // console.log('delete')
      let mixedCase: Item = state.currentMixedCase
      return this.itemsService.deleteItemImage(action['payload']).pipe(
        map((item: Item) => new fromActions.LoadMixedCase({ id: mixedCase._id })),
        catchError((error: any) => of(new fromActions.DeleteItemImageFail({ error })))
      );
    })
  );

  @Effect()
  deleteItemMixedImage$: Observable<fromActions.DeleteItemMixedImageSuccess | fromActions.DeleteItemImageFail | fromActions.LoadMixedCase> = this.actions$.pipe(
    ofType<fromActions.DeleteItemMixedImage>(fromActions.DELETE_ITEM_MIXED_IMAGE),
    withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))),
    flatMap(([action, state]: [fromActions.DeleteItemMixedImage, fromReducer.ItemsState]) => {
      // console.log('delete')
      let mixedCase: Item = state.currentMixedCase
      let mixedCaseItem: Item = state.currentMixedItem
      return this.itemsService.deleteItemMixedImage(action['payload']).pipe(
        map((item: Item) => new fromActions.LoadMixedItem({ id: mixedCaseItem._id, item_id: mixedCase._id })),
        catchError((error: any) => of(new fromActions.DeleteItemImageFail({ error })))
      );
    })
  );

  @Effect()
  uploadItemImage$: Observable<fromActions.UploadItemImageSuccess | fromActions.UploadItemImageFail> = this.actions$.pipe(
    ofType<fromActions.UploadItemImage>(fromActions.UPLOAD_ITEM_IMAGE),
    withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))),
    flatMap(([action, state]: [fromActions.UploadItemImage, fromReducer.ItemsState]) => {

      return this.itemsService.uploadItemImage(action['payload']).pipe(
        map((itemImage: ItemImage) => new fromActions.UploadItemImageSuccess(itemImage)),
        catchError((error: any) => of(new fromActions.UploadItemImageFail({ error })))
      );
    })
  );

  @Effect()
  uploadItemMixedImage$: Observable<fromActions.UploadItemImageSuccess | fromActions.UploadItemImageFail | fromActions.LoadMixedCase> = this.actions$.pipe(
    ofType<fromActions.UploadItemImage>(fromActions.UPLOAD_ITEM_MIXED_IMAGE),
    withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))),
    flatMap(([action, state]: [fromActions.UploadItemImage, fromReducer.ItemsState]) => {
      // console.log('blegh', state)
      let mixedCase: Item = state.currentMixedCase
      let mixedCaseItem: Item = state.currentMixedItem
      return this.itemsService.uploadItemMixedImage(action['payload']).pipe(
        map((itemImage: ItemImage) => new fromActions.LoadMixedItem({ id: mixedCaseItem._id, item_id: mixedCase._id })),
        catchError((error: any) => of(new fromActions.UploadItemImageFail({ error })))
      );
    })
  );


  @Effect()
  uploadItemImageListing$: Observable<fromActions.UploadItemImageSuccess | fromActions.UploadItemImageFail> = this.actions$.pipe(
    ofType<fromActions.UploadItemImage>(fromActions.UPLOAD_ITEM_IMAGE_LISTING),
    withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))),
    flatMap(([action, state]: [fromActions.UploadItemImage, fromReducer.ItemsState]) => {
      // console.log('blegh')
      return this.itemsService.uploadItemImageListing(action['payload']).pipe(
        map((itemImage: ItemImage) => new fromActions.UploadItemMixedItemImageSuccess(itemImage)),
        catchError((error: any) => of(new fromActions.UploadItemImageFail({ error })))
      );
    })
  );

  @Effect()
  searchItems$: Observable<fromActions.SearchItemsSuccess | fromActions.SearchItemsFail> = this.actions$.pipe(
    ofType<fromActions.SearchItems>(fromActions.SEARCH_ITEMS),
    withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))),
    flatMap(([action, state]: [fromActions.SearchItems, fromReducer.ItemsState]) => {

      return this.itemsService.searchItems(action['payload'].query, action['payload'].limit, action['payload'].filters).pipe(
        map((items: Item[]) => new fromActions.SearchItemsSuccess({ items })),
        catchError((error: any) => of(new fromActions.SearchItemsFail({ error })))
      );
    })
  );

  ///
  ///
  @Effect()
  loadBrands$: Observable<fromActions.LoadBrandsSuccess | fromActions.LoadBrandsFail> = this.actions$.pipe(
    ofType<fromActions.LoadBrands>(fromActions.LOAD_BRANDS),
    withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))),
    flatMap(([action, state]: [fromActions.LoadBrands, fromReducer.ItemsState]) => {
      // console.log('xxx bRANDS')
      return this.itemsService.loadBrands().pipe(
        map((brands: ItemBrand[]) => new fromActions.LoadBrandsSuccess({ brands })),
        catchError((error: any) => of(new fromActions.LoadBrandsFail({ error })))
      );
    })
  );

  @Effect()
  loadCountries$: Observable<fromActions.LoadCountriesSuccess | fromActions.LoadCountriesFail> = this.actions$.pipe(
    ofType<fromActions.LoadCountries>(fromActions.LOAD_COUNTRIES),
    withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))),
    flatMap(([action, state]: [fromActions.LoadCountries, fromReducer.ItemsState]) => {

      return this.itemsService.loadCountries().pipe(
        map((countries: Country[]) => new fromActions.LoadCountriesSuccess({ countries })),
        catchError((error: any) => of(new fromActions.LoadCountriesFail({ error })))
      );
    })
  );

  @Effect()
  loadSuppliers$: Observable<fromActions.LoadSuppliersSuccess | fromActions.LoadSuppliersFail> = this.actions$.pipe(
    ofType<fromActions.LoadSuppliers>(fromActions.LOAD_SUPPLIERS),
    withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))),
    flatMap(([action, state]: [fromActions.LoadSuppliers, fromReducer.ItemsState]) => {

      return this.itemsService.loadSuppliers().pipe(
        map((suppliers: Supplier[]) => new fromActions.LoadSuppliersSuccess({ suppliers })),
        catchError((error: any) => of(new fromActions.LoadSuppliersFail({ error })))
      );
    })
  );

  ///
  // delete supplier { _id: action['payload']._id, item_id: action['payload'].item_id }
  //
  @Effect()
  deleteSupplier$: Observable<fromActions.DeleteSupplierSuccess | fromActions.DeleteSupplierFail> = this.actions$.pipe(
    ofType<fromActions.DeleteSupplier>(fromActions.DELETE_SUPPLIER),
    withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))),
    flatMap(([action, state]: [fromActions.DeleteSupplier, fromReducer.ItemsState]) => {
      return this.itemsService.deleteSupplier(action['payload']._id, action['payload'].item_id).pipe(
        map((supplierItem: SupplierItem) => new fromActions.DeleteSupplierSuccess({ supplierItem })),
        catchError((error: any) => {
          // console.log('error', error);
          return of(new fromActions.DeleteSupplierFail({ error }))
        }
        )
      );
    })
  );
  // delete supplier
  ///

  @Effect()
  createSupplierItem$: Observable<fromActions.CreateSupplierItemSuccess | fromActions.CreateSupplierItemFail> = this.actions$.pipe(
    ofType<fromActions.CreateSupplierItem>(fromActions.CREATE_SUPPLIER_ITEM),
    withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))),
    flatMap(([action, state]: [fromActions.CreateSupplierItem, fromReducer.ItemsState]) => {

      return this.itemsService.createSupplierItem(action['payload'].supplierItemInput).pipe(
        map((supplierItem: SupplierItem) => new fromActions.CreateSupplierItemSuccess({ supplierItem })),
        catchError((error: any) => of(new fromActions.CreateSupplierItemFail({ error })))
      );
    })
  );

  @Effect()
  updateSupplierItem$: Observable<fromActions.UpdateSupplierItemSuccess | fromActions.UpdateSupplierItemFail | fromActions.LoadMixedCase> = this.actions$.pipe(
    ofType<fromActions.UpdateSupplierItem>(fromActions.UPDATE_SUPPLIER_ITEM),
    withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))),
    flatMap(([action, state]: [fromActions.UpdateSupplierItem, fromReducer.ItemsState]) => {
      return this.itemsService.updateSupplierItem(action['payload'].supplierItemInput).pipe(
        map((supplierItem: SupplierItem) => {
          // console.log('xxx updateItem', supplierItem)
          let mixedCase: any
          state && state.currentMixedCase ? mixedCase = state.currentMixedCase : mixedCase = false;
          // console.log('xxx updateItem', mixedCase)
          if (mixedCase) {
            return new fromActions.LoadMixedCase({ id: mixedCase._id })
          }
          return new fromActions.UpdateSupplierItemSuccess({ supplierItem })
        }),
        catchError((error: any) => of(new fromActions.UpdateSupplierItemFail({ error })))
      );
    })
  );
  //       map((supplierItem: SupplierItem) => new fromActions.UpdateSupplierItemSuccess({ supplierItem })),
  //       catchError((error: any) => of(new fromActions.UpdateSupplierItemFail({ error })))
  //     );
  //   })
  // );

  @Effect()
  loadMixedCaseSupplierItem$: Observable<fromActions.LoadSupplierItemSuccess | fromActions.LoadSupplierItemFail | fromActions.CreateSupplierItem> = this.actions$.pipe(
    ofType<fromActions.LoadMixedCaseSupplierItem>(fromActions.LOAD_MIXED_CASE_SUPPLIER_ITEM),
    withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))),
    flatMap(([action, state]: [fromActions.LoadMixedCaseSupplierItem, fromReducer.ItemsState]) => {

      return this.itemsService.loadSupplierItem(action['payload'].itemID).pipe(
        map((supplierItems: SupplierItem[]) => {
          let supplierItem = supplierItems.find(i => i.supplier_id === action['payload'].supplierID);
          const supplierItemInput: SupplierItemInput = {
            item_id: action['payload'].itemID,
            supplier_id: action['payload'].supplierID
          }
          // console.log('xxx supplierItems', action['payload'])
          // if (action['payload'].mixed_case) {
          //  new fromActions.DeleteSupplier({ _id: action['payload'].supplierID, item_id: action['payload'].itemID })
          // }
          // if (!supplierItem) {
          // return new fromActions.CreateSupplierItem({ supplierItemInput })
          // }
          return new fromActions.LoadMixedCaseSupplierItemSuccess({ supplierItem })
        }),
        catchError((error: any) => of(new fromActions.LoadSupplierItemFail({ error })))
      );
    })
  );

  @Effect()
  loadSupplierItem$: Observable<fromActions.LoadSupplierItemSuccess | fromActions.LoadSupplierItemFail | fromActions.CreateSupplierItem> = this.actions$.pipe(
    ofType<fromActions.LoadSupplierItem>(fromActions.LOAD_SUPPLIER_ITEM),
    withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))),
    flatMap(([action, state]: [fromActions.LoadSupplierItem, fromReducer.ItemsState]) => {
      return this.itemsService.loadSupplierItem(action['payload'].itemID).pipe(
        map((supplierItems: SupplierItem[]) => {
          let supplierItem = supplierItems.find(i => i.supplier_id === action['payload'].supplierID);
          const supplierItemInput: SupplierItemInput = {
            item_id: action['payload'].itemID,
            supplier_id: action['payload'].supplierID
          }
          // console.log('xxx supplierItems', action['payload'])
          // if (action['payload'].mixed_case) {
          //   new fromActions.DeleteSupplier({ _id: action['payload'].supplierID, item_id: action['payload'].itemID })
          // }

          if (!supplierItem) {
            return new fromActions.CreateSupplierItem({ supplierItemInput })
          }
          return new fromActions.LoadSupplierItemSuccess({ supplierItem })
        }),
        catchError((error: any) => of(new fromActions.LoadSupplierItemFail({ error })))
      );
    })
  );

  @Effect()
  loadSupplierItemConditionSuggest$: Observable<fromActions.LoadSupplierItemConditionSuggestSuccess | fromActions.LoadSupplierItemConditionSuggestFail> = this.actions$.pipe(
    ofType<fromActions.LoadSupplierItemConditionSuggest>(fromActions.LOAD_SUPPLIER_ITEM_CONDITION_SUGGEST),
    withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))),
    flatMap(([action, state]: [fromActions.LoadSupplierItemConditionSuggest, fromReducer.ItemsState]) => {
      return this.itemsService.loadSupplierItemConditionSuggest(action['payload'].condition).pipe(
        map((conditions: string[]) => new fromActions.LoadSupplierItemConditionSuggestSuccess({ conditions })),
        catchError((error: any) => of(new fromActions.LoadSupplierItemConditionSuggestFail({ error })))
      );
    })
  );

  @Effect()
  createBrand$: Observable<fromActions.ItemsAction> = this.actions$.pipe(
    ofType<fromActions.CreateBrand>(fromActions.CREATE_BRAND),
    withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))),
    flatMap(([action, state]: [fromActions.CreateBrand, fromReducer.ItemsState]) => {

      return this.itemsService.createBrand(action['payload'].brandInput).pipe(
        flatMap((brand: ItemBrand) => {
          return [
            new fromActions.CreateBrandSuccess({ brand }),
            new fromActions.UpdateItem({
              item: {
                _id: state.currentItem._id,
                brand_id: brand._id,
                brandDescription: brand.name
              }
            })
          ];
        }),
        catchError((error: any) => of(new fromActions.CreateBrandFail({ error })))
      );
    })
  );

  @Effect()
  updateBrand$: Observable<fromActions.UpdateBrandSuccess | fromActions.UpdateBrandFail> = this.actions$.pipe(
    ofType<fromActions.UpdateBrand>(fromActions.UPDATE_BRAND),
    withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))),
    flatMap(([action, state]: [fromActions.UpdateBrand, fromReducer.ItemsState]) => {
      return this.itemsService.updateBrand(action['payload'].id, action['payload'].brandInput).pipe(
        map((brand: ItemBrand) => new fromActions.UpdateBrandSuccess({ brand })),
        catchError((error: any) => of(new fromActions.UpdateBrandFail({ error })))
      );
    })
  );

  @Effect()
  getExcelTemplateUrl$: Observable<fromActions.GetExcelTemplateSuccess | fromActions.GetExcelTemplateFail> = this.actions$.pipe(
    ofType<fromActions.GetExcelTemplate>(fromActions.GET_EXCEL_TEMPLATE),
    withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))),
    flatMap(([action, state]: [fromActions.GetExcelTemplate, fromReducer.ItemsState]) => {

      return this.itemsService.getExcelTemplate().pipe(
        map((excelTemplateUrl: string) => new fromActions.GetExcelTemplateSuccess({ excelTemplateUrl })),
        catchError((error: any) => of(new fromActions.GetExcelTemplateFail({ error })))
      );
    })
  );

  @Effect()
  importItems$: Observable<fromActions.ImportItemsSuccess | fromActions.ImportItemsFail> = this.actions$.pipe(
    ofType<fromActions.ImportItems>(fromActions.IMPORT_ITEMS),
    withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))),
    flatMap(([action, state]: [fromActions.ImportItems, fromReducer.ItemsState]) => {
      return this.itemsService.importItems(action['payload'].itemsFile).pipe(
        map((status: string) => new fromActions.ImportItemsSuccess({ status })),
        catchError((error: any) => of(new fromActions.ImportItemsFail({ error })))
      );
    })
  );

  @Effect()
  exportItems$: Observable<fromActions.ExportItemsSuccess | fromActions.ExportItemsFail> = this.actions$.pipe(
    ofType<fromActions.ExportItems>(fromActions.EXPORT_ITEMS),
    withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))),
    flatMap(([action, state]: [fromActions.ExportItems, fromReducer.ItemsState]) => {
      return this.itemsService.exportItems(action['payload'].filetype).pipe(
        map((res: any) => {
          const downloadURL = window.URL.createObjectURL(res);
          const link = document.createElement('a');
          link.href = downloadURL;
          action['payload'].filetype === 'pdf' ? link.download = 'Stories.pdf' : link.download = 'Stories.csv';
          link.click();
          window.URL.revokeObjectURL(downloadURL);
          return new fromActions.ExportItemsSuccess()
        }),
        catchError((error: any) => of(new fromActions.ExportItemsFail({ error })))
      );
    })
  );

  @Effect()
  createItemReview$: Observable<fromActions.CreateItemReviewSuccess | fromActions.CreateItemReviewFail> = this.actions$.pipe(
    ofType<fromActions.CreateItemReview>(fromActions.CREATE_ITEM_REVIEW),
    withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))),
    flatMap(([action, state]: [fromActions.CreateItemReview, fromReducer.ItemsState]) => {

      return this.itemsService.createItemReview(action['payload'].item_id, action['payload'].text).pipe(
        map((itemReview: ItemReview) => {
          return new fromActions.CreateItemReviewSuccess({ itemReview });
        }),
        catchError((error: any) => of(new fromActions.CreateItemReviewFail({ error })))
      );
    })
  );
  @Effect()
  updateItemInline$: Observable<any> = this.actions$.pipe(
    ofType<fromActions.UpdateItemInline>(fromActions.UPDATE_ITEM_INLINE),
    withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))),
    switchMap(([action, state]: [fromActions.UpdateItemInline, fromReducer.ItemsState]) => {
      return this.itemsService.updateItemInline(action['payload'].item).pipe(
        map((item: ItemInline) => {

          const updateItem: Item = item.editItem
          const updateSupItem: SupplierItem = item.editSupplierItem

          // console.log('updateItem', updateItem)
          // console.log('updateSupItem', updateSupItem)

          return new fromActions.UpdateItemInlineSuccess({ item: updateItem, supplierItemInput: updateSupItem })


          // return [
          //   new fromActions.UpdateItemSuccess({ item: updateItem }),
          //   new fromActions.UpdateSupplierItemSuccess({ supplierItem: updateSupItem })
          // ];
        }),
        catchError((error: any) => {
          // console.log('UpdateItemInlineFail', error);
          return of(new fromActions.UpdateItemInlineFail({ error }))
        })
      );
    })
  );

  ///
  @Effect()
  loadLabels$: Observable<fromActions.LoadLabelsSuccess | fromActions.LoadLabelsFail> = this.actions$.pipe(
    ofType<fromActions.LoadLabels>(fromActions.LOAD_LABELS),
    withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))),
    flatMap(([action, state]: [fromActions.LoadLabels, fromReducer.ItemsState]) => {
      // console.log('xxx', state);
      return this.itemsService.loadLabels1().pipe(
        map((labels: valueLabel[]) => new fromActions.LoadLabelsSuccess({ labels })),
        catchError((error: any) => of(new fromActions.LoadLabelsFail({ error })))
      );
    })
  );
  // ///


}
