import { environment } from 'environments/environment';
import { NgModule } from '@angular/core';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLinkModule, HttpLink } from 'apollo-angular-link-http';
import { InMemoryCache, defaultDataIdFromObject } from 'apollo-cache-inmemory';

const uri = environment.apiPath; // <-- add the URL of the GraphQL server here
export function createApollo(httpLink: HttpLink) {
  return {
    link: httpLink.create({ uri }),
    // tslint:disable-next-line: max-line-length
    cache: new InMemoryCache({
      dataIdFromObject: object => {
        // console.log('object.__typename', object.__typename)
        switch (object.__typename) {
          case 'OrderReportsPickingList':
            return object.id;
          case 'OrderReportsByProduct':
            return object.id;
          default: return defaultDataIdFromObject(object);
        }
      }
    })
    // cache: new InMemoryCache(),
  }
};


@NgModule({
  exports: [ApolloModule, HttpLinkModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule { }
