/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../node_modules/ng-http-loader/ng-http-loader.ngfactory";
import * as i2 from "ng-http-loader";
import * as i3 from "../../node_modules/angular2-toaster/src/toaster-container.component.ngfactory";
import * as i4 from "angular2-toaster/src/toaster-container.component";
import * as i5 from "angular2-toaster/src/toaster.service";
import * as i6 from "@angular/router";
import * as i7 from "./app.component";
import * as i8 from "./_services/activity.service";
import * as i9 from "./_services/authentication.service";
var styles_AppComponent = [];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "ng-http-loader", [], null, null, null, i1.View_NgHttpLoaderComponent_0, i1.RenderType_NgHttpLoaderComponent)), i0.ɵdid(1, 245760, null, 0, i2.NgHttpLoaderComponent, [i2.PendingRequestsInterceptor, i2.SpinnerVisibilityService], { backdrop: [0, "backdrop"], debounceDelay: [1, "debounceDelay"], filteredHeaders: [2, "filteredHeaders"], minDuration: [3, "minDuration"], opacity: [4, "opacity"], spinner: [5, "spinner"] }, null), i0.ɵpad(2, 1), (_l()(), i0.ɵeld(3, 0, null, null, 1, "toaster-container", [], null, null, null, i3.View_ToasterContainerComponent_0, i3.RenderType_ToasterContainerComponent)), i0.ɵdid(4, 245760, null, 0, i4.ToasterContainerComponent, [i5.ToasterService, i0.ChangeDetectorRef, i0.NgZone], { toasterconfig: [0, "toasterconfig"] }, null), (_l()(), i0.ɵeld(5, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(6, 212992, null, 0, i6.RouterOutlet, [i6.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = false; var currVal_1 = 100; var currVal_2 = _ck(_v, 2, 0, "no-loader"); var currVal_3 = 100; var currVal_4 = 0.6; var currVal_5 = _co.spinkit.skThreeBounce; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = _co.config; _ck(_v, 4, 0, currVal_6); _ck(_v, 6, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "body", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i0.ɵdid(1, 114688, null, 0, i7.AppComponent, [i6.Router, i8.IdleEventManager, i9.AuthenticationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i0.ɵccf("body", i7.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
