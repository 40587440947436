﻿import { ToasterService } from 'angular2-toaster/angular2-toaster';
import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subject } from 'rxjs';
// import { ToasterService} from 'angular2-toaster'

@Injectable({ providedIn: 'root' })
export class AlertService {
  isSidebarVisible: boolean;
  sidebarVisibilityChange: Subject<boolean> = new Subject<boolean>();
  private subject = new Subject<any>();
  private keepAfterNavigationChange = false;

  private toasterService: ToasterService;

  constructor(private router: Router, toasterService: ToasterService) {
    this.toasterService = toasterService;
    // clear alert message on route change
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterNavigationChange) {
          // only keep for a single location change
          this.keepAfterNavigationChange = false;
        } else {
          // clear alert
          this.subject.next();
        }
      }
    });
    this.sidebarVisibilityChange.subscribe((value) => {
      this.isSidebarVisible = value;
    });
  }

  toggleSidebarVisibility() {
    this.sidebarVisibilityChange.next(!this.isSidebarVisible);
  }

  errorAlert(title: string, msg: string) {
    this.toasterService.pop('error', title, msg);
  }

  warningAlert(title: string, msg: string) {
    this.toasterService.pop('warning', title, msg);
  }

  successAlert(title: string, msg: string) {
    this.toasterService.pop('success', title, msg);
  }

  success(message: string, keepAfterNavigationChange = false) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next({ type: 'success', text: message });
    this.successAlert('success', message);
  }

  error(message: string, keepAfterNavigationChange = false) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next({ type: 'error', text: message });
    this.errorAlert('error', message)
  }

  p2Alert(title: string, msg: string) {
    this.toasterService.pop('error', title, msg);
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }
}
