import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ToasterConfig } from 'angular2-toaster';
import { Spinkit } from 'ng-http-loader';
import { SkThreeBounceComponent } from 'ng-http-loader';
import { IdleEventManager } from './_services/activity.service';
import { AuthenticationService } from './_services';
@Component({
  selector: 'body',
  template: `<ng-http-loader [filteredHeaders]="['no-loader']" [backdrop]="false" [debounceDelay]="100" [minDuration]="100" [opacity]=".6" [spinner]="spinkit.skThreeBounce"></ng-http-loader><toaster-container [toasterconfig]="config"></toaster-container><router-outlet></router-outlet>`
})
export class AppComponent implements OnInit {
  public spinkit = Spinkit;
  constructor(private router: Router, public activityService: IdleEventManager, public auth: AuthenticationService) { }
  public config: ToasterConfig =
  new ToasterConfig({
    tapToDismiss: true,
    timeout: 5000
  });

  ngOnInit() {

    // console.log('______________');
    // if (this.auth.isLogin === true && localStorage.token) {
    // console.log('______________local storage token', localStorage.token);
 //   this.activityService.myStart();
    // }
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
