import { Router } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { Apollo } from 'apollo-angular';
import * as jwt_decode from 'jwt-decode';
import { NotificationsService } from './notifications.service';
import * as i0 from "@angular/core";
import * as i1 from "./authentication.service";
import * as i2 from "apollo-angular";
import * as i3 from "@angular/router";
import * as i4 from "./notifications.service";
var IdleEventManager = /** @class */ (function () {
    function IdleEventManager(auth, apollo, router, myNotice) {
        this.auth = auth;
        this.apollo = apollo;
        this.router = router;
        this.myNotice = myNotice;
        this.idle = false;
        this.callbacks = [];
        this.timeout = null;
        this.limit = 6;
        // if (this.auth.isLogin) {
        //   console.log("this in const", this.auth.isLogin);
        //  this.start();
        //  }
        if (this.router.url.indexOf('login') > -1) {
            // console.log('=================================login page');
        }
        else {
            // this.myStart();
            // console.log('=================================fake start');
        }
    }
    IdleEventManager.prototype.registerCallbacks = function () { };
    IdleEventManager.prototype.myStart = function () {
        // console.log('========================start is start!');
        var token = this.auth.token;
        if (token && !(this.router.url.indexOf('login') > -1)) {
            // console.log('start is start token!');
            this.startTimer();
            var decoded = jwt_decode(this.auth.token);
            this.refreshTime = ((decoded.exp - decoded.iat) * 1000) - 300000;
            // console.log('decoded', decoded);
            // this.refreshTime = ((decoded.exp - decoded.iat) * 1000) - 60000;
            // this.refreshTime = 2500;
            // console.log('ref', this.refreshTime);
            // if (this.auth.isLogin === true) {
            // console.log('this.auth.isLogin in click', this.auth.isLogin);
            document.body.addEventListener('click', function () {
                // if ((this.router.url.indexOf('login') > -1) || !this.auth.isLogin) {
                // console.log('doos');
                //   localStorage.clear();
                // } else {
                //   this.auth.refreshToken()
                // console.log('clicked');
                // }
                // if (this.timeout) {
                //   this.limit = 6;
                //   clearTimeout(this.timeout);
                // document.removeEventListener('click', this.yess);
                // }
                // this.startTimer();
            });
        }
    };
    IdleEventManager.prototype.startTimer = function () {
        var _this = this;
        if ((this.router.url.indexOf('login') > -1) || !this.auth.isLogin) {
            // console.log('poes');
            localStorage.clear();
            document.removeEventListener('click', this.yess);
        }
        else {
            // console.log('gggg');
            // this.myNotice.getNotice(this.auth.token).subscribe(res => {
            //  this.noticeList = res.data;
            //   this.noticeList = res.data;
            //   const NAMES = [];
            //   const COUNT = [];
            // tslint:disable-next-line: forin
            //   for (const d in this.noticeList.CmsNotifications) {
            //     const item = this.noticeList.CmsNotifications[d];
            //     if (item.isArchived === false) {
            //       const newName = this.noticeList.CmsNotifications[d];
            //       NAMES.push(newName);
            //     }
            //     if (item.isArchived === false && item.date_read === null) {
            //       const newCount = this.noticeList.CmsNotifications[d];
            //       COUNT.push(newCount);
            //     }
            //   }
            // console.log(NAMES);
            // console.log(COUNT);
            //   NAMES.length > 0 ? this.showNotice = false : this.showNotice = true;
            //   this.noticeList = NAMES;
            //   this.userNoticeAmount = COUNT.length;
            // });
            // console.log('this.refreshTime', this.refreshTime);
            // if (this.auth.isLogin) {
            this.timeout = setTimeout(function () {
                // call some side effect like refresh code here
                // this.auth.refreshToken();
                _this.limit = _this.limit - 1;
                // console.log('minus one', this.limit);
                // call some side effect like refresh code here
                if (_this.limit <= 0) {
                    // call logout code here
                    // console.log('logging out here');
                    _this.auth.timeOut();
                }
                else {
                    _this.startTimer();
                }
            }, this.refreshTime);
        }
    };
    IdleEventManager.prototype.yess = function () {
        console.log('remove');
    };
    IdleEventManager.ngInjectableDef = i0.defineInjectable({ factory: function IdleEventManager_Factory() { return new IdleEventManager(i0.inject(i1.AuthenticationService), i0.inject(i2.Apollo), i0.inject(i3.Router), i0.inject(i4.NotificationsService)); }, token: IdleEventManager, providedIn: "root" });
    return IdleEventManager;
}());
export { IdleEventManager };
