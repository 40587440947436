<li class="nav-item dropdown mr-2 header-stats" dropdown placement="bottom right">
  <a class="nav-link" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false"
    dropdownToggle (click)="false">
    <i class="cui-people icons d-block"></i>
    <span class="badge badge-pill badge-secondary">{{totalUserCount}}</span>
  </a>
  <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
    <div class="dropdown-header">Users Online</div>

    <div class="notice_holder">
      <table style="font-size: 11px;" *ngIf="userTokenStats" class="table mb-0">
        <tbody>
          <tr *ngIf="userTokenStats?.customerUsersLoggedIn">
            <td>Customers</td>
            <td style="text-align:right">{{userTokenStats?.customerUsersLoggedIn}}</td>
          </tr>
          <tr *ngIf="userTokenStats?.shopUsersLoggedIn">
            <td>Support</td>
            <td style="text-align:right">{{userTokenStats?.shopUsersLoggedIn}}</td>
          </tr>
          <tr *ngIf="userTokenStats?.supplierUsersLoggedIn">
            <td>Vendor</td>
            <td style="text-align:right">{{userTokenStats?.supplierUsersLoggedIn}}</td>
          </tr>
          <tr>
            <td>Admin</td>
            <td style="text-align:right">{{userTokenStats?.adminUsersLoggedIn}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</li>
