<li style="color:#959595;font-size:12px;margin-left: 30px;" class="nav-item dropdown mr-2 header-stats" dropdown
  placement="bottom right">
  <a style="color:#b98754;" class="nav-link"
    href="https://forms.monday.com/forms/76b57daf76e67777148d97f9b5f0a58c?r=use1" role="button" target="_blank">
    <i style="margin-right: 5px;" class="cui-tags"></i>&nbsp;<span style="text-decoration: underline;">Submit A
      Product</span>
  </a>
  <!-- <div class="dropdown-menu dropdown-menu-left" *dropdownMenu aria-labelledby="simple-dropdown">

    <div class="notice_holder header_links">
      <table style="font-size: 11px;" class="table mb-0">
        <tbody>
          <tr>
            <td style="border-top:0px">
              <a target="_blank" href="https://bit.ly/ProductUploadP2P">New Product</a>
            </td>
          </tr> -->
  <!-- <tr>
            <td>
              <a target="_blank" href="#">Product Updates</a>
            </td>
          </tr> -->
  <!-- <tr>
            <td>
              <a target="_blank" href="https://forms.monday.com/forms/30882702c28c97ca94443c549ee961ea?r=use1">Promotional Product</a>
            </td>
          </tr>
          <tr>
            <td>
              <a target="_blank" href="https://forms.monday.com/forms/4181ad801c140a1694d15c34a6b35d42?r=use1">Mixed Case</a>
            </td>
          </tr>
          <tr>
            <td>
              <a target="_blank" href="https://forms.monday.com/forms/117a9ad0275a4c6803a994f3c862453d?r=use1">Cult Finds</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div> -->
</li>
