import * as tslib_1 from "tslib";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { User, CmsNotice } from '../_models';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "apollo-angular";
import * as i3 from "@angular/router";
var httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
var NotificationsService = /** @class */ (function () {
    function NotificationsService(http, apollo, router) {
        this.http = http;
        this.apollo = apollo;
        this.router = router;
        this.userDataNew = new User();
        this.userData = new User();
        this.onlyUser1 = [1];
        this.onlyUser2 = [2];
        this.onlyUser3 = [3];
        this.onlyUser23 = [2, 3];
        this.cmsDataNew = new CmsNotice();
        this.cmsData = new CmsNotice();
        this.currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
        this.token = localStorage.getItem('token');
    }
    Object.defineProperty(NotificationsService.prototype, "currentUserValue", {
        get: function () {
            return this.currentUserSubject.value;
        },
        enumerable: true,
        configurable: true
    });
    NotificationsService.prototype.getNotice = function (token) {
        var d = "query {Notifications (limit:0){\n      _id\n      title\n      message\n      to_user_id\n      to_shop_id\n      to_supplier_id\n      from_user_id\n      imageBanner\n      from_shop_id\n      from_supplier_id\n      status\n      date_created\n      date_read\n      notificationType_id\n      isArchived\n      dateArchived } }";
        var userDetailGQL = gql(d);
        // console.log('this.token user', this.token);
        return this.apollo.query({
            query: userDetailGQL,
            fetchPolicy: 'network-only',
            variables: {
            // _id: userid
            },
            context: {
                // example of setting the headers with context per operation
                headers: new HttpHeaders().set('x-access-token', token)
            }
        }).pipe(map(function (newdata) {
            var userInfo = newdata;
            // console.log(userInfo);
            localStorage.setItem('currentUserNotifications', JSON.stringify(userInfo.data));
            return userInfo;
        }));
    };
    NotificationsService.prototype.getCurrentUserNotices = function () {
        var data = JSON.parse(localStorage.getItem('currentUserNotifications'));
        if (data) {
            return data.Notifications;
        }
        else {
            console.log('mistakes');
            // return this.userDataNew;
        }
    };
    NotificationsService.prototype.editNotice1 = function (_id, date_read, token) {
        var editShopGQL = gql;
        editShopGQL = gql(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n    mutation editNotification(\n      $id: ID!, $date_read:Date) {\n      editNotification (_id: $id, Notification: { date_read:$date_read }) {\n            _id\n            date_read\n        }\n    }"], ["\n    mutation editNotification(\n      $id: ID!, $date_read:Date) {\n      editNotification (_id: $id, Notification: { date_read:$date_read }) {\n            _id\n            date_read\n        }\n    }"])));
        // this.cmsData = data;
        return this.apollo.mutate({
            mutation: editShopGQL,
            variables: {
                _id: _id,
                date_read: date_read
                //  userTypeNo: 4,
                // shop_id: this.storieData.shop_id,
                //  supplier_id: this.storieData.supplier_id
            },
            context: {
                // example of setting the headers with context per operation
                headers: new HttpHeaders().set('x-access-token', token)
            }
        }).pipe(map(function (data) {
            var editInfo = data;
            console.log(editInfo);
            if (editInfo && editInfo) {
                localStorage.setItem('EditCurrentArticle', JSON.stringify(editInfo));
            }
            return editInfo;
        }));
    };
    ///
    ///
    NotificationsService.prototype.editNotice = function (data, token) {
        var d = 'mutation {editNotification(_id: "' + data._id + '", notification: { date_read: "' + data.date_read + '"}) { _id date_read } }';
        var userDetailGQL = gql(d);
        return this.apollo.mutate({
            mutation: userDetailGQL,
            variables: {
                _id: data._id,
                date_read: data.date_read
            },
            context: {
                // example of setting the headers with context per operation
                headers: new HttpHeaders().set('x-access-token', token)
            }
        }).pipe(map(function (newdata) {
            var userInfo = newdata;
            // old localStorage.setItem('EditCurrentNotice', JSON.stringify(userInfo.data));
            return userInfo;
        }));
    };
    ///
    NotificationsService.prototype.archiveNotice1 = function (_id, token) {
        var editShopGQL = gql;
        editShopGQL = gql(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["\n    mutation deleteNotification($id: ID!) {\n      deleteNotification(_id: $id) {\n        _id\n        title\n        message\n        to_user_id\n        to_shop_id\n        to_supplier_id\n        from_user_id\n        imageBanner\n        from_shop_id\n        from_supplier_id\n        status\n        date_created\n        date_read\n        notificationType_id\n        isArchived\n        dateArchived\n      }\n    }"], ["\n    mutation deleteNotification($id: ID!) {\n      deleteNotification(_id: $id) {\n        _id\n        title\n        message\n        to_user_id\n        to_shop_id\n        to_supplier_id\n        from_user_id\n        imageBanner\n        from_shop_id\n        from_supplier_id\n        status\n        date_created\n        date_read\n        notificationType_id\n        isArchived\n        dateArchived\n      }\n    }"])));
        return this.apollo.mutate({
            mutation: editShopGQL,
            variables: {
                _id: _id
            },
            context: {
                // example of setting the headers with context per operation
                headers: new HttpHeaders().set('x-access-token', token)
            }
        }).pipe(map(function (data) {
            var editInfo = data;
            console.log(editInfo);
            //  if (editInfo && editInfo) {
            localStorage.setItem('clearResult', JSON.stringify(editInfo));
            //   }
            return editInfo;
        }));
    };
    ///
    NotificationsService.prototype.archiveNotice = function (_id, token) {
        console.log('getting country detail from service');
        var d = 'mutation {deleteNotification(_id: "' + _id + '") { _id isArchived } }';
        var userDetailGQL = gql(d);
        return this.apollo.mutate({
            mutation: userDetailGQL,
            variables: {
                _id: _id
            },
            context: {
                // example of setting the headers with context per operation
                headers: new HttpHeaders().set('x-access-token', token)
            }
        }).pipe(map(function (newdata) {
            var userInfo = newdata;
            localStorage.setItem('clearResult', JSON.stringify(userInfo.data));
            return userInfo;
        }));
    };
    NotificationsService.ngInjectableDef = i0.defineInjectable({ factory: function NotificationsService_Factory() { return new NotificationsService(i0.inject(i1.HttpClient), i0.inject(i2.Apollo), i0.inject(i3.Router)); }, token: NotificationsService, providedIn: "root" });
    return NotificationsService;
}());
export { NotificationsService };
var templateObject_1, templateObject_2;
