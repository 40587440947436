/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "ngx-bootstrap/dropdown";
import * as i3 from "ngx-bootstrap/component-loader";
import * as i4 from "../../../views/dashboard/dashboard.service";
import * as i5 from "@angular/common/http";
import * as i6 from "apollo-angular";
import * as i7 from "@angular/router";
import * as i8 from "./header-stats.component";
var styles_HeaderStatsComponent = [];
var RenderType_HeaderStatsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_HeaderStatsComponent, data: {} });
export { RenderType_HeaderStatsComponent as RenderType_HeaderStatsComponent };
function View_HeaderStatsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "tr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Customers"])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "td", [["style", "text-align:right"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.userTokenStats == null) ? null : _co.userTokenStats.customerUsersLoggedIn); _ck(_v, 4, 0, currVal_0); }); }
function View_HeaderStatsComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "tr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Support"])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "td", [["style", "text-align:right"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.userTokenStats == null) ? null : _co.userTokenStats.shopUsersLoggedIn); _ck(_v, 4, 0, currVal_0); }); }
function View_HeaderStatsComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "tr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Vendor"])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "td", [["style", "text-align:right"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.userTokenStats == null) ? null : _co.userTokenStats.supplierUsersLoggedIn); _ck(_v, 4, 0, currVal_0); }); }
function View_HeaderStatsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "table", [["class", "table mb-0"], ["style", "font-size: 11px;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 11, "tbody", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeaderStatsComponent_3)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeaderStatsComponent_4)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeaderStatsComponent_5)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 4, "tr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Admin"])), (_l()(), i0.ɵeld(11, 0, null, null, 1, "td", [["style", "text-align:right"]], null, null, null, null, null)), (_l()(), i0.ɵted(12, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.userTokenStats == null) ? null : _co.userTokenStats.customerUsersLoggedIn); _ck(_v, 3, 0, currVal_0); var currVal_1 = ((_co.userTokenStats == null) ? null : _co.userTokenStats.shopUsersLoggedIn); _ck(_v, 5, 0, currVal_1); var currVal_2 = ((_co.userTokenStats == null) ? null : _co.userTokenStats.supplierUsersLoggedIn); _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = ((_co.userTokenStats == null) ? null : _co.userTokenStats.adminUsersLoggedIn); _ck(_v, 12, 0, currVal_3); }); }
function View_HeaderStatsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["aria-labelledby", "simple-dropdown"], ["class", "dropdown-menu dropdown-menu-right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "dropdown-header"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Users Online"])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "notice_holder"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeaderStatsComponent_2)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.userTokenStats; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_HeaderStatsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 9, "li", [["class", "nav-item dropdown mr-2 header-stats"], ["dropdown", ""], ["placement", "bottom right"]], [[2, "dropup", null], [2, "open", null], [2, "show", null]], null, null, null, null)), i0.ɵprd(512, null, i2.BsDropdownState, i2.BsDropdownState, []), i0.ɵdid(2, 212992, null, 0, i2.BsDropdownDirective, [i0.ElementRef, i0.Renderer2, i0.ViewContainerRef, i3.ComponentLoaderFactory, i2.BsDropdownConfig, i2.BsDropdownState], { placement: [0, "placement"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 4, "a", [["aria-expanded", "false"], ["aria-haspopup", "true"], ["class", "nav-link"], ["data-toggle", "dropdown"], ["dropdownToggle", ""], ["href", "#"], ["role", "button"]], [[1, "aria-haspopup", 0], [1, "disabled", 0], [1, "aria-expanded", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4).onClick() !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (false !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(4, 147456, null, 0, i2.BsDropdownToggleDirective, [i0.ChangeDetectorRef, i2.BsDropdownDirective, i0.ElementRef, i0.Renderer2, i2.BsDropdownState], null, null), (_l()(), i0.ɵeld(5, 0, null, null, 0, "i", [["class", "cui-people icons d-block"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "span", [["class", "badge badge-pill badge-secondary"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeaderStatsComponent_1)), i0.ɵdid(9, 16384, null, 0, i2.BsDropdownMenuDirective, [i2.BsDropdownState, i0.ViewContainerRef, i0.TemplateRef], null, null)], function (_ck, _v) { var currVal_3 = "bottom right"; _ck(_v, 2, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 2).dropup; var currVal_1 = i0.ɵnov(_v, 2).isOpen; var currVal_2 = (i0.ɵnov(_v, 2).isOpen && i0.ɵnov(_v, 2).isBs4); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = true; var currVal_5 = i0.ɵnov(_v, 4).isDisabled; var currVal_6 = i0.ɵnov(_v, 4).isOpen; _ck(_v, 3, 0, currVal_4, currVal_5, currVal_6); var currVal_7 = _co.totalUserCount; _ck(_v, 7, 0, currVal_7); }); }
export function View_HeaderStatsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-header-stats", [], null, null, null, View_HeaderStatsComponent_0, RenderType_HeaderStatsComponent)), i0.ɵprd(512, null, i4.DashService, i4.DashService, [i5.HttpClient, i6.Apollo, i7.Router]), i0.ɵdid(2, 114688, null, 0, i8.HeaderStatsComponent, [i4.DashService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var HeaderStatsComponentNgFactory = i0.ɵccf("app-header-stats", i8.HeaderStatsComponent, View_HeaderStatsComponent_Host_0, { salesAdvanceSearch: "salesAdvanceSearch" }, {}, []);
export { HeaderStatsComponentNgFactory as HeaderStatsComponentNgFactory };
