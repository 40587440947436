import localeZa from '@angular/common/locales/en-ZA';

import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localeZa, 'en-ZA');


import { ReportsComponent } from './views/reports/reports.component';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, HostListener, ErrorHandler, Injectable } from '@angular/core';
import { LocationStrategy, HashLocationStrategy, TitleCasePipe } from '@angular/common';
import { AgmCoreModule } from '@agm/core';


// @ngrx
import { StoreRouterConnectingModule, RouterStateSerializer } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { metaReducers } from 'app/@store/meta-reducers';
import { reducers, effects, CustomSerializer } from 'app/@store';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { SettingsComponent } from './views/settings/settings.component';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};
import { GraphQLModule } from './graphql.module';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { environment } from 'environments/environment';

import { AppComponent } from './app.component';
import { NgHttpLoaderModule } from 'ng-http-loader';
// import { MyFilterPipe } from './MyFilterPipe';

// Import containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { PasswordResetComponent } from './views/password-reset/password-reset.component';
import { RegisterComponent } from './views/register/register.component';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Ng7DynamicBreadcrumbModule } from 'ng7-dynamic-breadcrumb';

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ChartsModule } from 'ng2-charts';
import { ToasterService, ToasterModule } from 'angular2-toaster';
import { AuthInterceptor } from './_services/token-interceptor.service';

import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgSelectModule } from '@ng-select/ng-select';
import { ErrorInterceptor } from './_services/error-interceptor.service';
import { JwtInterceptor } from './_services/jwt.interceptor';
import { HeaderStatsComponent } from './containers/default-layout/header-stats/header-stats.component';
import { HeaderLinksComponent } from './containers/default-layout/header-links/header-links.component';


// import * as Sentry from '@sentry/browser';

// Sentry.init({
//   dsn: 'https://d158f660fec84af39c4f8351ef27efcb@sentry.io/1510227'
//   // dsn: null
// });

// @Injectable()
// export class SentryErrorHandler implements ErrorHandler {
//   constructor() { }
//   handleError(error) {
//     // console.log(error);
//     const eventId = Sentry.captureException(error.originalError || error);
//     Sentry.showReportDialog({ eventId });
//   }
// }

@NgModule({
  imports: [
    NgSelectModule,
    AutocompleteLibModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    CollapseModule.forRoot(),
    ChartsModule,
    ReactiveFormsModule,
    GraphQLModule,
    HttpClientModule,
    ToasterModule.forRoot(),
    NgHttpLoaderModule.forRoot(),
    FormsModule,
    Ng7DynamicBreadcrumbModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAI1-7gFfNzZTqedVO8-Sksgim5YqwP4gQ',
      libraries: ["places"]
    }),

    // @ngrx
    StoreRouterConnectingModule.forRoot(),
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot(effects),
    environment.production ? [] : StoreDevtoolsModule.instrument(),

  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    LoginComponent,
    RegisterComponent,
    PasswordResetComponent,
    SettingsComponent,
    HeaderStatsComponent,
    HeaderLinksComponent
  ],
  providers: [

    { provide: LOCALE_ID, useValue: "en-ZA" },

    TitleCasePipe,
    ToasterService,
    {
      provide: RouterStateSerializer,
      useClass: CustomSerializer
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
