export class User {
  id: number;
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  token: string;
  userTypeNo: [];
  shop_id: string;
  supplier_id: string;
  name: string;
  email: string;
  status: Boolean;
  __typename: string;
  jwtToken?: any;
}

export class UserToken {
  user_id: string;
  token: string;
}

export enum UserType {
  admin = 1,
  AllUser = 2
}

export class Users {
  User: User[];
}
export class Author {
  id: number;
  name: string;
  title: string;
  tagline: string;
  email: string;
  status: Boolean;
  about: string;
  facebook: string;
  instagram: string;
  twitter: string;
  activeStatus: Boolean;
  isActiveInd: Boolean;
  imageUrl: string;
  imageName: string;
  imageEtag: string;
  imageKey: string;
  description: string;
  country_id: string;
  brand_id: string;
  author_id: string;
  titleShort: string;
  body: string;
  shop_id: string;
  region_id: string;
  publishDate: Date;
  cultivar_ids: [];
  idList?: [];
  keywords: [];
  country: {
    name: string;
  };
  articleRelated: {
    _id: string;
    article_id: string;
    related_article_id: string;
  }
  dateCreated: Date;
  file?: File;
  item_id: string;
  article_id: string;
}

export class Authors {
  Author: Authors[];
}

export class Shop {
  id?: number;
  imageUrl?: string;
  name: string;
  emailAddress?: string;
  liquorLicence?: string;
  telNumber?: number;
  commissionPercentage?: number;
  seoDescription?: string;
  campaignMonitorCode?: string;
  googleAnlyticsCode?: string;
  wineClubRuleDuration?: string;
  wineClubRuleBottles?: number;
  wineClubRuleOrderMinTotal?: number;
  wineClubRuleFreeShippingAmount?: number;
  wineClubRuleDiscountPercentage?: number;
  wineClubRuleBottlesRetainer?: number;
  wineClubRuleOrderMinTotalRetainer?: number;
  wineClubRuleEmailAddress?: string;
  wineClubTelNumber?: number;
  wineClubRuleTelNumber?: string;
  wineClubRuleDescription?: string;
  activeStatus?: Boolean;
  imageName?: string;
  imageEtag?: string;
  imageKey?: string;
  description?: string;
  country_id?: string;
  validDomains?: [];
  facebook?: string;
  instagram?: string;
  twitter?: string;
}

export class Shops {
  Shop: Shops[];
}

export class Story {
  id: number;
  name: string;
  email: string;
  status: Boolean;
  about: string;
  facebook: string;
  instagram: string;
  twitter: string;
  activeStatus: Boolean;
  isActiveInd: Boolean;
  imageUrl: string;
  imageName: string;
  imageEtag: string;
  imageKey: string;
  description: string;
  country_id: string;
  title: string;
  tagline: string;
  publishDate: Date;
  country: {
    name: string;
  };
}

export class ArticleImage {
  article?: string;
  _id?: string;
  file: File;
  article_id?: string;
  // isFoo: Boolean;
}

export class Stories {
  Story: Stories[];
}


export class CmsNotice {
  _id: number;
  date_read: Date;
}
