import { UserType } from './../_models/user';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { User, UserToken, CmsNotice } from '../_models';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Router } from '@angular/router';

export interface cmsData {
  userId: number;
  date_read: Date;
}

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({ providedIn: 'root' })
export class NotificationsService {
  myDate: number;
  private currentUserSubject: BehaviorSubject<UserToken>;
  userDataNew = new User();
  userData = new User();

  public currentUser: Observable<UserToken>;
  token;
  newToken;
  isLogin: boolean;
  myUserType: string;
  myUserList: number[];
  onlyUser1: number[] = [1];
  onlyUser2: number[] = [2];
  onlyUser3: number[] = [3];
  onlyUser23: number[] = [2, 3];
  constructor(
    private http: HttpClient,
    private apollo: Apollo,
    private router: Router) {

    this.currentUserSubject = new BehaviorSubject<UserToken>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
    this.token = localStorage.getItem('token');
  }
  cmsDataNew = new CmsNotice();
  cmsData = new CmsNotice();
  public get currentUserValue(): UserToken {
    return this.currentUserSubject.value;
  }


  getNotice(token: string) {
    const d = `query {Notifications (limit:0){
      _id
      title
      message
      to_user_id
      to_shop_id
      to_supplier_id
      from_user_id
      imageBanner
      from_shop_id
      from_supplier_id
      status
      date_created
      date_read
      notificationType_id
      isArchived
      dateArchived } }`;
    const userDetailGQL = gql(d);
    // console.log('this.token user', this.token);
    return this.apollo.query({
      query: userDetailGQL,
      fetchPolicy: 'network-only',
      variables: {
        // _id: userid
      },
      context: {
        // example of setting the headers with context per operation
        headers: new HttpHeaders().set('x-access-token', token)
      }
    }).pipe(map(newdata => {
      const userInfo = newdata;
      // console.log(userInfo);
      localStorage.setItem('currentUserNotifications', JSON.stringify(userInfo.data));
      return userInfo;
    }));

  }


  getCurrentUserNotices() {
    const data = JSON.parse(localStorage.getItem('currentUserNotifications'));
    if (data) {
      return data.Notifications;
    } else {
      console.log('mistakes');
      // return this.userDataNew;
    }
  }

  editNotice1(_id: String, date_read: Date, token: string) {
    let editShopGQL = gql;
    editShopGQL = gql`
    mutation editNotification(
      $id: ID!, $date_read:Date) {
      editNotification (_id: $id, Notification: { date_read:$date_read }) {
            _id
            date_read
        }
    }`;
    // this.cmsData = data;
    return this.apollo.mutate({
      mutation: editShopGQL,
      variables: {
        _id: _id,
        date_read: date_read
        //  userTypeNo: 4,
        // shop_id: this.storieData.shop_id,
        //  supplier_id: this.storieData.supplier_id
      },
      context: {
        // example of setting the headers with context per operation
        headers: new HttpHeaders().set('x-access-token', token)
      }
    }).pipe(map(data => {
      const editInfo = data;
      console.log(editInfo);
      if (editInfo && editInfo) {
        localStorage.setItem('EditCurrentArticle', JSON.stringify(editInfo));
      }
      return editInfo;
    }));
  }

  ///
  ///
  editNotice(data: any, token: string) {
    const d = 'mutation {editNotification(_id: "' + data._id + '", notification: { date_read: "' + data.date_read + '"}) { _id date_read } }';
    const userDetailGQL = gql(d);
    return this.apollo.mutate({
      mutation: userDetailGQL,
      variables: {
        _id: data._id,
        date_read: data.date_read
      },
      context: {
        // example of setting the headers with context per operation
        headers: new HttpHeaders().set('x-access-token', token)
      }
    }).pipe(map(newdata => {
      const userInfo = newdata;
      // old localStorage.setItem('EditCurrentNotice', JSON.stringify(userInfo.data));
      return userInfo;
    }));
  }
  ///


  archiveNotice1(_id: string, token: string) {
    let editShopGQL = gql;
    editShopGQL = gql`
    mutation deleteNotification($id: ID!) {
      deleteNotification(_id: $id) {
        _id
        title
        message
        to_user_id
        to_shop_id
        to_supplier_id
        from_user_id
        imageBanner
        from_shop_id
        from_supplier_id
        status
        date_created
        date_read
        notificationType_id
        isArchived
        dateArchived
      }
    }`;
    return this.apollo.mutate({
      mutation: editShopGQL,
      variables: {
        _id: _id
      },
      context: {
        // example of setting the headers with context per operation
        headers: new HttpHeaders().set('x-access-token', token)
      }
    }).pipe(map(data => {
      const editInfo = data;
      console.log(editInfo);
      //  if (editInfo && editInfo) {
      localStorage.setItem('clearResult', JSON.stringify(editInfo));
      //   }
      return editInfo;
    }));
  }


  ///
  archiveNotice(_id: string, token: string) {
    console.log('getting country detail from service');
    const d = 'mutation {deleteNotification(_id: "' + _id + '") { _id isArchived } }';
    const userDetailGQL = gql(d);
    return this.apollo.mutate({
      mutation: userDetailGQL,
      variables: {
        _id: _id
      },
      context: {
        // example of setting the headers with context per operation
        headers: new HttpHeaders().set('x-access-token', token)
      }
    }).pipe(map(newdata => {
      const userInfo = newdata;
      localStorage.setItem('clearResult', JSON.stringify(userInfo.data));
      return userInfo;
    }));
  }
  ///


}


