import { UserType } from './_models/user';
interface NavAttributes {
  [propName: string]: any;
}
interface NavWrapper {
  attributes: NavAttributes;
  element: string;
}
interface NavBadge {
  text: string;
  variant: string;
}
interface NavLabel {
  class?: string;
  variant: string;
}

export interface NavData {
  name?: string;
  url?: string;
  icon?: string;
  badge?: NavBadge;
  title?: boolean;
  children?: NavData[];
  variant?: string;
  attributes?: NavAttributes;
  divider?: boolean;
  class?: string;
  label?: NavLabel;
  wrapper?: NavWrapper;
  userType?: number[];
  myUserType?: string;
  user3Special?: string;
}

export const NGFNavItems: NavData[] = [
  {
    name: 'Orders',
    url: '/orders',
    icon: 'cui-basket-loaded'
  },
  {
    name: 'Shipments',
    url: '/shipments',
    icon: 'cui-globe'
  },

]

export const navItems: NavData[] = [
  // {
  //   title: true,
  //   name: 'Port2Port 2.0',
  //   myUserType: '',
  // },
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-speedometer',
    myUserType: '',
    // badge: {
    // variant: 'info',
    // text: 'NEW'
    // }
  },
  {
    name: 'Orders',
    url: '/orders',
    icon: 'cui-basket-loaded',
    myUserType: 'user1'
  },
  {
    name: 'Orders',
    url: '/orders',
    icon: 'cui-basket-loaded',
    myUserType: 'user2'
  },
  {
    name: 'Orders',
    url: '/orders',
    icon: 'cui-basket-loaded',
    myUserType: 'user23'
  },
  {
    name: 'Orders',
    url: '/orders',
    icon: 'cui-basket-loaded',
    myUserType: 'user3'
  },
  {
    name: 'Shipments',
    url: '/shipments',
    icon: 'cui-globe',
    myUserType: 'user1'
  },
  {
    name: 'Shipments',
    url: '/shipments',
    icon: 'cui-globe',
    myUserType: 'user2'
  },
  {
    name: 'Shipments',
    url: '/shipments',
    icon: 'cui-globe',
    user3Special: 'user3Special'
  },
  // {
  //   name: 'Shipments',
  //   url: '/shipments',
  //   icon: 'cui-globe',
  //   myUserType: 'user3'
  // },
  {
    name: 'Carts',
    url: '/carts/abandoned',
    icon: 'cui-cart',
    myUserType: 'user1',
    // children: [
    //   {
    //     name: 'Active',
    //     url: '/carts/active',
    //     icon: 'cui-cart'
    //   },
    //   {
    //     name: 'Abandoned',
    //     url: '/carts/abandoned',
    //     icon: 'cui-cart'
    //   },
    // ]
  },
  {
    name: 'Carts',
    url: '/carts/abandoned',
    icon: 'cui-cart',
    myUserType: 'user2',
  },
  {
    name: 'Catalogue',

    icon: 'fa fa-list',
    myUserType: 'user1',
    children: [
      {
        name: 'Items',
        url: '/catalogue/items',
        icon: 'fa cui-drop'
      },
      {
        name: 'Mixed Cases',
        url: '/catalogue/mixed-cases',
        icon: 'fa fa-cube',
      },
    ]
  },
  {
    name: 'Catalogue',
    url: '/items',
    icon: 'fa fa-glass',
    myUserType: 'user2',
    children: [
      {
        name: 'Items',
        url: '/catalogue/items',
        icon: 'fa cui-drop'
      },
      {
        name: 'Mixed Cases',
        url: '/catalogue/mixed-cases',
        icon: 'fa fa-cube',
      },
    ]

  },
  {
    name: 'Catalogue',
    url: '/items',
    icon: 'fa fa-glass',
    myUserType: 'user3',
    children: [
      {
        name: 'Items',
        url: '/catalogue/items',
        icon: 'fa cui-drop'
      },
      {
        name: 'Mixed Cases',
        url: '/catalogue/mixed-cases',
        icon: 'fa fa-cube',
      },
    ]
  },
  {
    name: 'Vouchers',
    url: '/vouchers',
    icon: 'cui-tags',
    myUserType: 'user1'
  },
  {
    name: 'Vouchers',
    url: '/vouchers',
    icon: 'cui-tags',
    myUserType: 'user2'
  },
  {
    name: 'Articles',
    url: '/articles',
    icon: 'cui-note',
    myUserType: 'user1',
    children: [
      {
        name: 'Authors',
        url: '/articles/authors',
        icon: 'cui-people'
      },
      {
        name: 'Stories',
        url: '/articles/stories',
        icon: 'cui-pencil'
      },
    ]
  },
  {
    name: 'Articles',
    url: '/articles',
    icon: 'cui-note',
    myUserType: 'user2',
    children: [
      {
        name: 'Authors',
        url: '/articles/authors',
        icon: 'cui-people'
      },
      {
        name: 'Stories',
        url: '/articles/stories',
        icon: 'cui-pencil'
      },
    ]
  },
  {
    name: 'Reports',
    url: '/reports',
    icon: 'cui-chart',
    myUserType: 'user1'
  },
  {
    name: 'Reports',
    url: '/reports/',
    icon: 'cui-chart',
    myUserType: 'user2'
  },
  {
    name: 'Reports',
    url: '/reports/',
    icon: 'cui-chart',
    myUserType: 'user3'
  },
  {
    name: 'Reports',
    url: '/reports/',
    icon: 'cui-chart',
    myUserType: 'user23'
  },
  {
    name: 'Shops',
    url: '/shops',
    icon: 'fa fa-shopping-basket',
    myUserType: 'user2'
  },
  {
    name: 'Shop',
    url: '/shop/edit',
    icon: 'fa fa-shopping-basket',
    myUserType: 'user23'
  },
  {
    name: 'Shops',
    url: '/shops',
    icon: 'fa fa-shopping-basket',
    myUserType: 'user1'

  },
  {
    name: 'Vendors',
    url: '/vendors',
    icon: 'fa fa-truck',
    myUserType: 'user1'
  },
  {
    name: 'Vendors',
    url: '/vendors',
    icon: 'fa fa-truck',
    myUserType: 'user2'
  },
  // {
  //   name: 'Vendor',
  //   url: '/vendor/edit',
  //   icon: 'fa fa-truck',
  //   myUserType: 'user3'
  // },
  {
    name: 'Vendor',
    url: '/vendor/edit',
    icon: 'fa fa-truck',
    myUserType: 'user23'
  },
  {
    name: 'Brands',
    url: '/brands',
    icon: 'cui-bold',
    myUserType: 'user1'
  },
  {
    name: 'Brands',
    url: '/brands',
    icon: 'cui-bold',
    myUserType: 'user2'
  },
  {
    name: 'Accounts',
    url: '/accounts',
    icon: 'cui-dollar',
    myUserType: 'user1',
  },
  {
    name: 'Accounts',
    url: '/accounts',
    icon: 'cui-dollar',
    myUserType: 'user3',
  },
  {
    name: 'Accounts',
    url: '/accounts',
    icon: 'cui-dollar',
    myUserType: 'user2',
  },
  {
    name: 'Users',
    url: '/users',
    icon: 'cui-user',
    myUserType: '',
  },
  {
    name: 'Settings',
    url: '/settings',
    icon: 'cui-settings',
    myUserType: 'user1',
    children: [
      {
        name: 'Countries & Cities',
        url: '/settings/countries-cities',
        icon: 'cui-globe'
      },
      {
        name: 'Item Details',
        url: '/settings/item-detail-categories',
        icon: 'icon-puzzle'
      }
    ]
  },
];
