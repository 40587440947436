import { Component, OnDestroy, Inject, HostListener, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AuthenticationService, NotificationsService } from '../../_services';
import { UserToken } from '../../_models';
import { Router } from '@angular/router';
import { IdleEventManager } from './../../_services/activity.service';
import { pipe, interval } from 'rxjs';
import { take } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import * as fromRootStore from 'app/@store';
import * as fromItemsStore from 'app/views/items/@store';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.css'],
})
export class DefaultLayoutComponent implements OnDestroy, OnInit {
  public navItems;
  currentUser: UserToken;
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement;
  temp: any;
  alertService: any;
  data: any[];
  noticeList: any;
  newNotice: any;
  userNoticeAmount: any;
  dateNow: Date;
  my_id: string;
  date_read: Date;
  showNotice = true;
  isAdmin: boolean = false;
  isVendor: boolean;
  isShop: boolean;
  constructor(
    private store: Store<fromRootStore.State>,
    public notice: NotificationsService,
    private activityService: IdleEventManager,
    private router: Router,
    public authServ: AuthenticationService,
    @Inject(DOCUMENT) _document?: any) {

    this.authServ.currentUser.subscribe(x => this.currentUser = x);
    // console.log('this.auth.isLogin ON DEFAULT', auth.isLogin);

    // setInterval(()=> { this.getNoticeandToken() }, 300000);


    authServ.isLogin = true;
    if (authServ.isLogin) {
      this.activityService.myStart();
    } else {
      // console.log('qwerty');
    }

    // console.log('qwerty', this.currentUser);
    // console.log('qwerty', this.auth.token);
    this.navItems = this.authServ.getNavItem();
    this.authServ.currentUser.subscribe(x => this.currentUser = x);

    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = _document.body.classList.contains('sidebar-minimized');
    });
    this.element = _document.body;
    this.changes.observe(<Element>this.element, {
      attributes: true,
      attributeFilter: ['class']
    });
  }

  ngOnInit() {
    // this.generateNotices();
    // console.log('gggg');
    if (this.authServ.myUserType === 'user1') {
      this.isAdmin = true;
    }
    if (this.authServ.myUserType === 'user3') {
      this.isVendor = true;
    }
    if (this.authServ.myUserType === 'user3Special') {
      this.isVendor = true;
    }
    if (this.authServ.myUserType === 'user2') {
      this.isShop = true;
      this.isAdmin = true;
    }
  }



  getNoticeandToken() {
    this.generateNotices();
    // console.log('renew from 5min timer')
    // this.auth.refreshToken();
  }

  ngOnDestroy(): void {
    this.changes.disconnect();
    this.authServ.currentUser.subscribe(x => this.currentUser = x).unsubscribe();
  }
  ///
  generateNotices1() {
    // console.log('gggg');
    this.notice.getNotice(this.currentUser.token).subscribe(res => {
      //  this.noticeList = res.data;
      this.noticeList = res.data;
      const NAMES = [];
      const COUNT = [];
      // tslint:disable-next-line: forin
      for (const d in this.noticeList.Notifications) {
        const item = this.noticeList.Notifications[d];
        if (item.isArchived === false) {
          const newName = this.noticeList.Notifications[d];
          NAMES.push(newName);
        }
        if (item.isArchived === false && item.date_read === null) {
          const newCount = this.noticeList.Notifications[d];
          COUNT.push(newCount);
        }
      }
      // console.log(NAMES);
      // console.log(COUNT);
      NAMES.length > 0 ? this.showNotice = false : this.showNotice = true;
      this.noticeList = NAMES;
      this.userNoticeAmount = COUNT.length;
    });
  }
  ///
  ///
  archiveNotice(val) {
    event.stopPropagation();
    this.notice.archiveNotice(val, this.currentUser.token)
      .pipe(take(1))
      .subscribe(
        () => {
          const data = JSON.parse(localStorage.getItem('clearResult'));
          localStorage.removeItem('clearResult');
          // this.noticeList = data;
          // console.log(this.noticeList);
          this.generateNotices();
          // const data = JSON.parse(localStorage.getItem('currentUserNotifications'));
          // console.log(this.noticeList.Notifications.length);
          // this.userNoticeAmount = this.noticeList.Notifications.length;
        });
  }
  ///
  ///
  clearAllNotices() {
    this.dateNow = new Date();
    // this.my_id = '5d48378f6595c10004634f1e'
    // tslint:disable-next-line: forin
    for (const d in this.noticeList) {
      const item = this.noticeList[d];
      const item_id = item._id;
      const data = {
        _id: item_id,
        date_read: this.dateNow
      };
      this.notice.editNotice(data, this.currentUser.token)
        .pipe(take(1))
        .subscribe(
          () => {
            // const data = JSON.parse(localStorage.getItem('clearResult'));
            // localStorage.removeItem('clearResult');
            this.generateNotices();
          });
    }
  }
  ///
  ///
  clearNotice(val) {
    this.dateNow = new Date();
    const data = {
      _id: val,
      date_read: this.dateNow
    };
    this.notice.editNotice(data, this.currentUser.token)
      .pipe(take(1))
      .subscribe(
        () => {
          // const data = JSON.parse(localStorage.getItem('clearResult'));
          // localStorage.removeItem('clearResult');
          this.generateNotices();
        });
  }
  ///
  ///
  archiveAllNotices() {
    // tslint:disable-next-line: forin
    for (const d in this.noticeList) {
      const item = this.noticeList[d];
      const item_id = item._id;
      this.notice.archiveNotice(item_id, this.currentUser.token)
        .pipe(take(1))
        .subscribe(
          () => {
            this.generateNotices();
            // const data = JSON.parse(localStorage.getItem('clearResult'));
            // localStorage.removeItem('clearResult');
          });
    }
    // if (item.isArchived === false && item.date_read === null) {
    //   const newName = this.noticeList.Notifications[d];
    //   NAMES.push(newName);
    // }
  }
  ///
  ///
  generateNotices() {
    const data = JSON.parse(localStorage.getItem('currentUser'));
    if (data) {
      const token = data.token;
      this.notice.getNotice(token)
        .pipe(take(1))
        .subscribe(
          () => {
            const data = JSON.parse(localStorage.getItem('currentUserNotifications'));
            this.noticeList = data;
            const NAMES = [];
            const COUNT = [];
            // tslint:disable-next-line: forin
            for (const d in this.noticeList.Notifications) {
              const item = this.noticeList.Notifications[d];
              if (item.isArchived === false) {
                const newName = this.noticeList.Notifications[d];
                NAMES.push(newName);
              }
              if (item.isArchived === false && item.date_read === null) {
                const newCount = this.noticeList.Notifications[d];
                COUNT.push(newCount);
              }
            }
            // console.log(NAMES) old;
            // console.log(COUNT);
            NAMES.length > 0 ? this.showNotice = false : this.showNotice = true;
            NAMES.length > 0 ? this.showNotice = false : this.showNotice = true;
            this.noticeList = NAMES;
            this.userNoticeAmount = COUNT.length;
            // this.data = [...newItems];
            localStorage.removeItem('currentUserNotifications');
          }, // success path
          error => {
            if (error.graphQLErrors.length > 0) {
              // console.log(error.graphQLErrors[0]);
              if (error.graphQLErrors[0].statusCode === 404) {
                // no notifications for this user
              }
              this.temp = error.graphQLErrors[0];
            } else {
              this.temp.error = error;
            }
            // this.alertService.error(error.graphQLErrors);
            // console.log(error.graphQLErrors);
          });
    }
  }
  closeAndClear() {
    this.store.dispatch(new fromItemsStore.ClearMixedItem());
  }

  ///
}
