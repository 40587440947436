import { AlertService } from './alert.service';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';
import { IdleEventManager } from './activity.service';
import { Injector, Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as jwt_decode from 'jwt-decode';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(public alertService: AlertService, public auth: AuthenticationService) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // console.log('event--->>>', event);

          if (event.body.errors && event.body.errors.length > 0) {
            let s = event.body.errors[0].error.message
            s = s.split(':')[1];
            // console.log('event--->>>', s);
            if ([123].includes(event.body.errors[0].statusCode)) {

              // auto logout if 401 or 403 response returned from api
              // this.tokenService.logout();
              //"404 - {"auth":false,"message":"Failed to authenticate token."}"

              // 404 - {\"message\":\"Missing Orders Summary\"}
            }

            if (event.body.errors[0].error.message === '404 - {"auth":false,"message":"Failed to authenticate token."}' ||
                event.body.errors[0].error.message === '500 - {\"auth\":false,\"message\":\"Failed to authenticate token.\"}') {
              this.auth.timeOut();
              // this.auth.showMsg = true;
              // console.log('event--->>>', event.body.errors[0].error.message);
            } else if (event.body.errors[0].error.message === '404 - {\"message\":\"Missing Orders Summary\"}') {
              this.alertService.p2Alert('No orders yet', 'Adjust date selection to see previous orders');
            } else {
              // document.write(s.slice(0, -1));
              this.alertService.p2Alert('', s.slice(0, -1));
            }

            // 404 - {\"message\":\"Invalid Item is published\"}

            // console.log('event--->>>', event.body.errors);
          }

          // if (event.body.data) {
          //   this.alertService.success('udpated');
          //   console.log('event--->>>', event.body.data[0]);
          // }
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        let data = {};
        data = {
          reason: error && error.error && error.error.reason ? error.error.reason : '',
          status: error.status
        };
        console.log(data)
        // this.errorDialogService.openDialog(data);
        this.alertService.error(error.error.errors[0].error.message);
        return throwError(error);
      }));
  }
}

// @Injectable()
// export class AuthInterceptor implements HttpInterceptor {
//   expireTime: any;
//   nowTime: any;
//   timestamp: any;
//   myTimer: any;
//   refreshTimer: any;

//   constructor(
//     public auth: AuthenticationService,
//     private activityService: IdleEventManager,
//     private router: Router,
//     public alertService: AlertService
//   ) {

//   }
//   intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
//     console.log('intercept',request);
//     if (this.router.url.indexOf('login') > -1 ||this.router.url.indexOf('password-reset') > -1 ) {
//     } else {
//       // this.alertService.toggleSidebarVisibility()
//       const data = JSON.parse(localStorage.getItem('currentUser'));
//       // this.token = localStorage.getItem('token');
//       const decoded = jwt_decode(this.auth.token);
//       this.expireTime = decoded.exp;
//       this.nowTime = Math.floor(new Date().getTime() / 1000);
//       // console.log('_EXP', this.expireTime);
//       // console.log('_NOW', this.nowTime);
//       if (this.expireTime < this.nowTime) {
//         this.auth.timeOut();
//         // console.log('bye');
        // this.auth.logout();
//       } else {
//         // this.refreshTimer.setTime(this.myTimer.getTime() + (1 * 60 * 1000));
//         // console.log('mytimer', this.myTimer);
//         // console.log('refresh', this.refreshTimer);
//         const dateNow = Math.floor(new Date().getTime() / 1000);
//         if (this.myTimer === undefined || this.refreshTimer < dateNow) {
//           this.myTimer = Math.floor(new Date().getTime() / 1000);
//           this.refreshTimer = this.myTimer + 240;

//           setTimeout(() => {
//             // console.log('renew from token intercept')
//             this.auth.refreshToken();
//           }, 2000);

//           // this.auth.refreshToken();
//           // console.log(this.myTimer);
//           // console.log(this.refreshTimer);
//         }
//       }
//     }
    // console.log('local storage token', localStorage.token);
    // if (!this.auth.isLogin) {
    //   localStorage.clear();
    // console.log('token if');
    // console.log('login page', localStorage.token);
    // } else {
    // this.activityService.myStart();
    // console.log('token else');
    // }
    // Clone the request and authorization header
    // const authReq = request.clone({
    //   headers: request.headers.set('authorization', Authorization ? Authorization : '')
    // });
    // next.handle(request).subscribe(r=> console.log('req 20' , r));
    // return next.handle(request);

//     return next.handle(request)
//       .pipe(
//         retry(1),
//         catchError((error: HttpErrorResponse) => {
//           console.log('token if');
//           let errorMessage = '';
//           if (error.error instanceof ErrorEvent) {
//             // client-side error
//             errorMessage = `Error: ${error.error.message}`;
//           } else {
//             // server-side error
//             if (error.status === 409) {
//               this.auth.refreshToken();
//               console.log('error from interceptor');
//               console.log('error from ');
//               errorMessage = `Password reset link already sent, please try again in 30mins`;
//             } else {
//               errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
//             }

//           }
//           // window.alert(errorMessage);
//           return throwError(errorMessage);
//         })
//       )
//   }
// }
