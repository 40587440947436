import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { AlertService, AuthenticationService } from '../../_services';
import * as jwt_decode from 'jwt-decode';
import { first } from 'rxjs/operators';
var httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'my-auth-token'
    })
};
var PasswordResetComponent = /** @class */ (function () {
    function PasswordResetComponent(apollo, formBuilder, route, router, authenticationService, alertService, http, httpClient, handler) {
        this.apollo = apollo;
        this.formBuilder = formBuilder;
        this.route = route;
        this.router = router;
        this.authenticationService = authenticationService;
        this.alertService = alertService;
        this.http = http;
        this.httpClient = httpClient;
        this.loading = false;
        this.submitted = false;
        this.onSubmit = function (user) {
            this.submitted = true;
            // console.log(this.fragmentBoolean);
            if (this.fragmentBoolean) {
                if (this.tokenForm.invalid) {
                    return;
                }
                //  console.log('sending passwords');
                this.resetPassword(this.user_id, this.ff.password.value);
            }
            else {
                if (this.form.invalid) {
                    return;
                }
                // console.log('email bish', this.f.email.value);
                this.requestPassword(this.f.email.value);
            }
        };
    }
    Object.defineProperty(PasswordResetComponent.prototype, "f", {
        get: function () { return this.form.controls; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PasswordResetComponent.prototype, "ff", {
        get: function () { return this.tokenForm.controls; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PasswordResetComponent.prototype, "password", {
        get: function () { return this.tokenForm.get('password'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PasswordResetComponent.prototype, "confirmPassword", {
        get: function () { return this.tokenForm.get('confirmPassword'); },
        enumerable: true,
        configurable: true
    });
    PasswordResetComponent.passwordsShouldMatch = function (control) {
        var password = control.get('password');
        var confirmPassword = control.get('confirmPassword');
        if (password.value !== confirmPassword.value)
            return { passwordsShouldMatch: true };
        return null;
    };
    PasswordResetComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.form = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]]
        });
        this.tokenForm = this.formBuilder.group({
            password: ['', Validators.required],
            confirmPassword: ['', Validators.required]
        }, {
            validator: PasswordResetComponent.passwordsShouldMatch
        });
        //   if (this.loginForm.invalid) {
        //     return;
        // }
        this.route.fragment.subscribe(function (fragment) {
            // console.log('My hash fragment is here => ', fragment);
            if (fragment) {
                var decoded = jwt_decode(fragment);
                // console.log('USER ID', decoded.user_id);
                _this.user_id = decoded.user_id;
                // console.log('My hash fragment is here => ', fragment);
                // console.log('USER EMAIL', decoded.email);
                _this.fragmentBoolean = fragment;
                // this.resetPassword(this.user_id , '123');
            }
        });
    };
    // => Requesting new password
    PasswordResetComponent.prototype.requestPassword = function (email) {
        var _this = this;
        // console.log('requesting password');
        var reqestGQL = gql(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n      mutation createUserPasswordResetToken($email: String!) {\n        createUserPasswordResetToken(email: $email) {\n      email\n          }\n      }"], ["\n      mutation createUserPasswordResetToken($email: String!) {\n        createUserPasswordResetToken(email: $email) {\n      email\n          }\n      }"])));
        this.apollo.mutate({
            mutation: reqestGQL,
            variables: {
                email: email
            },
            context: {
            // example of setting the headers with context per operation
            // headers: new HttpHeaders().set('x-reset-token', this.fragmentBoolean)
            }
        })
            .pipe(first())
            .subscribe(function (newdata) {
            _this.alertService.success('Password reset link sent');
            // setTimeout(() => {
            // console.log(newdata);
            // this.router.navigate([this.returnUrl]);
            // }, 600);
        }, function (error) {
            if (error.graphQLErrors.length > 0 && error.graphQLErrors[0].statusCode === 409) {
                _this.alertService.error('Password reset link already sent, please try again in 30 mins');
                // this.temp = error.graphQLErrors[0];
            }
            else {
                _this.temp.error = error;
            }
            // this.alertService.error(this.temp.error.message);
            // this.alertService.error(error);
            _this.loading = false;
        });
    };
    // => Setting new password
    PasswordResetComponent.prototype.resetPassword = function (user_id, password) {
        var _this = this;
        var resetGQL = gql(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["\n      mutation useUserPasswordResetToken($user_id: ID!, $password: String!) {\n        useUserPasswordResetToken(user_id: $user_id, password: $password) {\n          _id\n          email\n          shop_id\n          createdAt\n          expireAt\n          token\n          }\n      }"], ["\n      mutation useUserPasswordResetToken($user_id: ID!, $password: String!) {\n        useUserPasswordResetToken(user_id: $user_id, password: $password) {\n          _id\n          email\n          shop_id\n          createdAt\n          expireAt\n          token\n          }\n      }"])));
        this.apollo.mutate({
            mutation: resetGQL,
            variables: {
                user_id: user_id,
                password: password
            },
            context: {
                // example of setting the headers with context per operation
                headers: new HttpHeaders().set('x-reset-token', this.fragmentBoolean)
            }
        }).subscribe(function (newdata) {
            _this.alertService.success('Password reset successfully');
            setTimeout(function () {
                // console.log(newdata);
                _this.router.navigate(['/login']);
            }, 600);
        }, function (error) {
            if (error.graphQLErrors.length > 0 && error.graphQLErrors[0].statusCode === 500) {
                _this.alertService.error('Password reset token expired, please request a new one');
                // this.temp = error.graphQLErrors[0];
            }
            else {
                _this.temp.error = error;
            }
            // this.alertService.error(this.temp.error.message);
            // this.alertService.error(error);
            _this.loading = false;
        });
    };
    return PasswordResetComponent;
}());
export { PasswordResetComponent };
var templateObject_1, templateObject_2;
