import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpBackend } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { interval } from 'rxjs';

import { AlertService, AuthenticationService, IdleEventManager } from '../../_services';
@Component({
  templateUrl: 'login.component.html',

  styleUrls: ['login-component.css'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error: string;
  temp: any;
  isShow: boolean;
  customerCheck: any;
  redirectURL: string;

  // user: UserGraphql;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public authServ: AuthenticationService,
    private alertService: AlertService,
    private idle: IdleEventManager,
    handler: HttpBackend
  ) {
    // redirect to home if already logged in
    if (this.authServ.currentUserValue) {
      // this.router.navigate(['/']);
      console.log('yezzz')
    }
  }

  ngOnInit() {
    this.authServ.isLogin = false;
    // this.authServ.showMsg = false;
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
    this.redirectURL = this.route.snapshot.queryParams['redirectURL'];


    // console.log('nooo', this.route.snapshot.queryParams)
    // console.log('nooo', this.redirectURL)
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  toggleDisplay() {
    // this.isShow = !this.isShow;
    this.authServ.showMsg = false;
  }

  onSubmit() {
    this.submitted = true;

    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.authServ.login(this.f.username.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        data => {
          this.authServ.getUserDetail(data.Login.token.user_id, data.Login.token.token)
            .pipe(first())
            .subscribe(
              newdata => {
                this.customerCheck = newdata.data;
                const customerType = [4];
                const cmsUser = this.customerCheck.User.userTypeNo;
                // console.log(customer)
                if (JSON.stringify(customerType) === JSON.stringify(cmsUser)) {
                  // this.router.navigate('login');
                  this.router.navigate(['/login']);
                  localStorage.clear();
                  this.alertService.error('Not allowed');
                  this.loading = false;
                  return;
                } else {
                  this.alertService.success('Logged in Successfully');
                  this.authServ.isLogin = true;
                  this.authServ.showMsg = false;
                  this.idle.limit = 6;
                }
                setTimeout(() => {
                  const params = this.route.snapshot.queryParams;
                  if (params['redirectURL']) {
                    this.redirectURL = params['redirectURL'];
                  } if (this.redirectURL) {
                    this.router.navigateByUrl(this.redirectURL)
                    // .catch(() => this.router.navigate(['orders']))
                  } else if (this.authServ.getUserNo() === 'user3') {
                    this.router.navigate(['dashboard'])
                    return
                  }
                  else {
                    this.router.navigate(['orders'])
                  }
                }, 600);
              },
              error => {
                this.alertService.error(error);
              });
        }
        ,
        error => {
          if (error.graphQLErrors.length > 0) {
            this.temp = error.graphQLErrors[0];
          } else {
            this.temp.error = error;
          }
          this.alertService.error(this.temp.error.message);
          // this.alertService.error(error);
          this.loading = false;
        }
      );
  }
}



