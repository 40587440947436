import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpBackend } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { AlertService, AuthenticationService, IdleEventManager } from '../../_services';
var LoginComponent = /** @class */ (function () {
    // user: UserGraphql;
    function LoginComponent(formBuilder, route, router, authServ, alertService, idle, handler) {
        this.formBuilder = formBuilder;
        this.route = route;
        this.router = router;
        this.authServ = authServ;
        this.alertService = alertService;
        this.idle = idle;
        this.loading = false;
        this.submitted = false;
        // redirect to home if already logged in
        if (this.authServ.currentUserValue) {
            // this.router.navigate(['/']);
            console.log('yezzz');
        }
    }
    LoginComponent.prototype.ngOnInit = function () {
        this.authServ.isLogin = false;
        // this.authServ.showMsg = false;
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });
        this.redirectURL = this.route.snapshot.queryParams['redirectURL'];
        // console.log('nooo', this.route.snapshot.queryParams)
        // console.log('nooo', this.redirectURL)
    };
    Object.defineProperty(LoginComponent.prototype, "f", {
        // convenience getter for easy access to form fields
        get: function () { return this.loginForm.controls; },
        enumerable: true,
        configurable: true
    });
    LoginComponent.prototype.toggleDisplay = function () {
        // this.isShow = !this.isShow;
        this.authServ.showMsg = false;
    };
    LoginComponent.prototype.onSubmit = function () {
        var _this = this;
        this.submitted = true;
        if (this.loginForm.invalid) {
            return;
        }
        this.loading = true;
        this.authServ.login(this.f.username.value, this.f.password.value)
            .pipe(first())
            .subscribe(function (data) {
            _this.authServ.getUserDetail(data.Login.token.user_id, data.Login.token.token)
                .pipe(first())
                .subscribe(function (newdata) {
                _this.customerCheck = newdata.data;
                var customerType = [4];
                var cmsUser = _this.customerCheck.User.userTypeNo;
                // console.log(customer)
                if (JSON.stringify(customerType) === JSON.stringify(cmsUser)) {
                    // this.router.navigate('login');
                    _this.router.navigate(['/login']);
                    localStorage.clear();
                    _this.alertService.error('Not allowed');
                    _this.loading = false;
                    return;
                }
                else {
                    _this.alertService.success('Logged in Successfully');
                    _this.authServ.isLogin = true;
                    _this.authServ.showMsg = false;
                    _this.idle.limit = 6;
                }
                setTimeout(function () {
                    var params = _this.route.snapshot.queryParams;
                    if (params['redirectURL']) {
                        _this.redirectURL = params['redirectURL'];
                    }
                    if (_this.redirectURL) {
                        _this.router.navigateByUrl(_this.redirectURL);
                        // .catch(() => this.router.navigate(['orders']))
                    }
                    else if (_this.authServ.getUserNo() === 'user3') {
                        _this.router.navigate(['dashboard']);
                        return;
                    }
                    else {
                        _this.router.navigate(['orders']);
                    }
                }, 600);
            }, function (error) {
                _this.alertService.error(error);
            });
        }, function (error) {
            if (error.graphQLErrors.length > 0) {
                _this.temp = error.graphQLErrors[0];
            }
            else {
                _this.temp.error = error;
            }
            _this.alertService.error(_this.temp.error.message);
            // this.alertService.error(error);
            _this.loading = false;
        });
    };
    return LoginComponent;
}());
export { LoginComponent };
