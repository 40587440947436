import { ActionReducerMap, createFeatureSelector, State } from '@ngrx/store';

import * as itemsReducer from './items/items.reducer';

export interface ItemsFeatureState {
  items: itemsReducer.ItemsState;
}

export const reducers: ActionReducerMap<ItemsFeatureState> = {
  items: itemsReducer.itemsReducer
};

export const getItemsFeatureState: any = createFeatureSelector('items');
