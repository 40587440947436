/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "ngx-bootstrap/dropdown";
import * as i2 from "ngx-bootstrap/component-loader";
import * as i3 from "../../../views/dashboard/dashboard.service";
import * as i4 from "@angular/common/http";
import * as i5 from "apollo-angular";
import * as i6 from "@angular/router";
import * as i7 from "./header-links.component";
var styles_HeaderLinksComponent = [];
var RenderType_HeaderLinksComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_HeaderLinksComponent, data: {} });
export { RenderType_HeaderLinksComponent as RenderType_HeaderLinksComponent };
export function View_HeaderLinksComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 7, "li", [["class", "nav-item dropdown mr-2 header-stats"], ["dropdown", ""], ["placement", "bottom right"], ["style", "color:#959595;font-size:12px;margin-left: 30px;"]], [[2, "dropup", null], [2, "open", null], [2, "show", null]], null, null, null, null)), i0.ɵprd(512, null, i1.BsDropdownState, i1.BsDropdownState, []), i0.ɵdid(2, 212992, null, 0, i1.BsDropdownDirective, [i0.ElementRef, i0.Renderer2, i0.ViewContainerRef, i2.ComponentLoaderFactory, i1.BsDropdownConfig, i1.BsDropdownState], { placement: [0, "placement"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 4, "a", [["class", "nav-link"], ["href", "https://forms.monday.com/forms/76b57daf76e67777148d97f9b5f0a58c?r=use1"], ["role", "button"], ["style", "color:#b98754;"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, "i", [["class", "cui-tags"], ["style", "margin-right: 5px;"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00A0"])), (_l()(), i0.ɵeld(6, 0, null, null, 1, "span", [["style", "text-decoration: underline;"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Submit A Product"]))], function (_ck, _v) { var currVal_3 = "bottom right"; _ck(_v, 2, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).dropup; var currVal_1 = i0.ɵnov(_v, 2).isOpen; var currVal_2 = (i0.ɵnov(_v, 2).isOpen && i0.ɵnov(_v, 2).isBs4); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_HeaderLinksComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-header-links", [], null, null, null, View_HeaderLinksComponent_0, RenderType_HeaderLinksComponent)), i0.ɵprd(512, null, i3.DashService, i3.DashService, [i4.HttpClient, i5.Apollo, i6.Router]), i0.ɵdid(2, 114688, null, 0, i7.HeaderLinksComponent, [i3.DashService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var HeaderLinksComponentNgFactory = i0.ɵccf("app-header-links", i7.HeaderLinksComponent, View_HeaderLinksComponent_Host_0, { salesAdvanceSearch: "salesAdvanceSearch" }, {}, []);
export { HeaderLinksComponentNgFactory as HeaderLinksComponentNgFactory };
