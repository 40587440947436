export class Order {
  order_id: String;
  user_id: String;
  shop_id: String;
  shopNo?: Number;
  orderNumber: String;
  orderReference: String;
  dateCreated: Date;
  isConsolidated: Boolean;
  orderStatusNumber?: any;
  orderItemStatusNumber: Number;
  itemsCount: Number;
  itemsAmount: Number;
  discountAmount: Number;
  refundAmount: Number;
  shippingAmount: Number;
  vatAmount: Number;
  totalAmount: Number;
  outstandingAmount: Number;
  additionalInformation: string;
  isGift: Boolean;
  giftSender: String;
  giftRecipient: String;
  giftMessage: String;
  dateFrom: String;
  dateTo: String;
  skip?: number;
  limit?: number;
  IsAdvanceSearch: Boolean;
  vendorName?: String;
  id?: String;
  handelingFee?: Number;
  supplierId?: String;
  userId?: String;
  sort?: any;
  courierNumber?: any;
  shipmentStatusNo?: any;
  trackingStatusNo?: any;
  value?: Number;



  OrderHeader: [];
}


export class Orders {
  Order: Orders[];
}
